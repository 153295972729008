import * as React from "react"

const Car = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={22.5} viewBox="0 0 30 22.5" {...props}>
    <path
      data-name="Icon awesome-car"
      d="M29.3 6.563h-3.512l-.975-2.438A6.529 6.529 0 0 0 18.721 0h-7.442a6.531 6.531 0 0 0-6.093 4.125l-.975 2.438H.7a.7.7 0 0 0-.682.874L.37 8.843a.7.7 0 0 0 .682.533h1.179a3.724 3.724 0 0 0-1.293 2.812V15a3.719 3.719 0 0 0 .938 2.457v3.168A1.875 1.875 0 0 0 3.75 22.5h1.875A1.875 1.875 0 0 0 7.5 20.625V18.75h15v1.875a1.875 1.875 0 0 0 1.875 1.875h1.875a1.875 1.875 0 0 0 1.875-1.875v-3.168A3.717 3.717 0 0 0 29.063 15v-2.812a3.725 3.725 0 0 0-1.293-2.813h1.176a.7.7 0 0 0 .682-.533l.352-1.406a.7.7 0 0 0-.683-.874ZM8.668 5.518a2.813 2.813 0 0 1 2.611-1.768h7.441a2.813 2.813 0 0 1 2.612 1.768l1.168 2.92h-15l1.168-2.92Zm-3.043 9.47a1.769 1.769 0 0 1-1.875-1.869 1.769 1.769 0 0 1 1.875-1.869 3.607 3.607 0 0 1 2.813 2.8c0 1.125-1.688.938-2.813.938Zm18.75 0c-1.125 0-2.813.187-2.813-.935a3.607 3.607 0 0 1 2.813-2.8 1.769 1.769 0 0 1 1.875 1.869 1.769 1.769 0 0 1-1.875 1.866Z"
      fill={props.color || "#fac91f"}
    />
  </svg>
)


export default Car
