import { Instance } from "utils";

const postBuyerInquiry = (data) => {
    return Instance.post('/buyer-inquiry/', data);
}

const fetchBuyerInquiries = ({agent, type}) => {
    return Instance.get(`/buyer-inquiry/?ordering=-date_added&listing__agent=${agent}&inquiry_type=${type}`);
}

const deleteBuyerInquiry = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.delete(`/buyer-inquiry/${id}/`);
}

const inviteAgent = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('buyer-agent-connection/agent-invite/', data)
}

const fetchBuyerMortgageConnection = ({ offset, limit=20 }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/buyer-broker-connection/?limit=${limit}&offset=${offset}&ordering=-date_created`);
}

const searchBuyerMortgageConnection = ({ offset, limit=20, filters }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/buyer-broker-connection/search/?limit=${limit}&offset=${offset}&ordering=-date_created&${filters}`);
}

const patchBuyerMortgageConnection = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.patch(`/buyer-broker-connection/${id}/`, data);
}

const deleteBuyerMortgageConnection = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.delete(`/buyer-broker-connection/${id}/`);
}

const inviteMortgageBroker = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('mortgage-broker-invitation/', data)
}

const cashBuyerUpgrade  = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('cash-buyer-upgrade/', data)
}

const foragerBuyerDowngrade  = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('forager-buyer-downgrade/', data)
}

const fetchBuyerProfile  = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get('buyer-profile/')
}

const setDefaultAgent  = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('buyer-profile/set-default-agent/', data)
}

const setDefaultBroker  = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('buyer-profile/set-default-broker/', data)
}


const buyer = {
    postBuyerInquiry,
    fetchBuyerInquiries,
    deleteBuyerInquiry,
    inviteAgent,
    fetchBuyerMortgageConnection,
    searchBuyerMortgageConnection,
    patchBuyerMortgageConnection,
    deleteBuyerMortgageConnection,
    inviteMortgageBroker,
    cashBuyerUpgrade,
    foragerBuyerDowngrade,
    fetchBuyerProfile,
    setDefaultAgent,
    setDefaultBroker,
}


export default buyer;
