import Seprator from 'components/Separator';
import './customButton.scss';
import Icons from 'components/Icons';

/* ACCEPTABLE PROPS VALUE
 * type: primary, secondary, tertiary, text, error, success
 * size: small, normal(default), large
 * iconOnly: button with icon only
 */

const CustomButton = ({ text, size, type = 'primary', iconLeft, iconRight, disabled, loading, iconOnly, style, action = () => { } }) => {
  return (
    <button className={`custom-button ${size} ${type}`} disabled={disabled || loading} onClick={action} style={style}>
      {
        !iconOnly
          ? <>
            <div className='icon-left'>
              {iconLeft}
              {
                iconLeft &&
                <Seprator width={5} />
              }
            </div>
            {
              !loading 
              ? <p> {text} </p>
              : <Icons.Loading height={16} width={16} background={'none'} />
            }
            <div className='icon-right'>{iconRight}</div>
          </>
          : <div>{iconOnly}</div>
      }
    </button>
  )
}

export default CustomButton;