// Components
import Separator from 'components/Separator';
// Assets
import './formStepper.scss'

const FormStepper = ({ active, form, skip }) => {
    return (
        <>
            <Separator height={50} />
            <div className='stepper-container'>
                <ul className={`${form.user_type.value === 'Buyer / Seller' && 'buyer-step'} 'stepper'`}>
                    <li className="active">
                        <div className='point'></div>
                        <Separator height={15} />
                        <span>Information</span>
                    </li>
                    {
                        form.user_type.value === 'Buyer / Seller' &&
                        <li 
                            className={`
                            ${(((active === 'account' || active === 'finish') && !skip) || active === 'invite_agent') && 'active' }
                            ${skip && 'skip'}
                            `}
                        >
                            <div className='point'></div>
                            <Separator height={15} />
                            <span className='desktop-only'>Invite Buyer's Agent</span>
                            <span className='mobile-only'>Invite</span>
                        </li>
                    }
                    <li className={`${(active === 'account' || active === 'finish') && 'active'}`}>
                        <div className='point'></div>
                        <Separator height={15} />
                        <span>Account</span>
                    </li>
                    <li className={`${active === 'finish' && 'active'}`}>
                        <div className='point'></div>
                        <Separator height={15} />
                        <span>Finish</span>
                    </li>
                </ul>
            </div>
            <Separator height={50} />
        </>
    )
}


export default FormStepper;