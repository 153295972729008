import Seprator from 'components/Separator';
import './publicCatalog.scss'
import beeTierIlllu from 'assets/images/bee_tier_illustration.svg'
import proBadge from 'assets/images/Pro-Badge.svg'
import forager from 'assets/images/Forager.png'
import house from 'assets/images/House.png'
import queen from 'assets/images/Queen.png'
import Icons from 'components/Icons';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const PublicCatalog = () => {
    const ref = useRef(null);
    const navigate = useNavigate()
    const scrollToHash = () => {
        const yOffset = -120;
        const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }
    return (
        <>
            <Header />
            <div className="public-catalog-container">
                {/* <div className="header-container">
                        <div className="details">
                            <p className="title">Hello there!</p>
                            <p className="jumbotron">Let's choose which type<br className='desktop-only'/>of buyer
                                you are, and it's totally <marker>Free</marker>!
                            </p>
                            <Seprator height={20} />
                            <p className="subtitle">
                                Enjoy the perks and features accessible on each tier. <br  className='desktop-only'/>
                                Buyers can present to Listing Agents by qualifying themselves as one of three levels
                            </p>

                            <Seprator height={40} />
                            <button className="button primary no-glow" onClick={scrollToHash}>Take a Look</button>
                        </div>
                        <Seprator width={80} />
                        <div className="illustration">
                            <img src={beeTierIlllu}  alt="bee" />
                        </div>
                </div> */}
                <div className="body-container" ref={ref}>
                    {/* <div className="welcome-message">
                        <div>
                            <p className="header">
                                We recommend choosing the <span>Queen Bee Buyer Tier</span>
                            </p>
                            <img src={proBadge} className="desktop-only" alt="" />
                        </div>
                    </div> */}
                    <div className="details">
                        <Seprator height={50} />
                        <p className="title">Hello there!</p>
                        <Seprator height={20} />
                        <p className="jumbotron">Let's choose which type<br className='desktop-only'/> of buyer
                            you are, and it's totally <marker>Free</marker>!
                        </p>
                        <Seprator height={30} />
                        <p className="subtitle">
                            Enjoy the perks and features accessible on each tier. <br className='desktop-only'/>
                            Buyers can present to Listing Agents by qualifying themselves as one of three levels
                        </p>
                        <Seprator height={50} />
                    </div>
                    <div className="catalog-container">
                        <div className="catalog">
                            <div className='details'>
                            <div className='icon'>
                                <img src={forager} className="bee-type" alt="bee" />
                            </div>
                            <Seprator height={20} />
                            <p className="header">Forager Bee</p>
                            <Seprator height={10} />
                            <p className="subheader">Passive</p>
                            <Seprator height={30} />
                            <p className="title">Documentation</p>
                            <Seprator height={20} />
                            <p className="title">
                                Sellers & Agents may see you as a window shopper
                            </p>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='#808080' />
                                <p>Save searches and add to favorites</p>
                            </div>
                        </div>
                            <button className="button primary medium tier-button" onClick={() => navigate('/signup')}>
                                Signup Now!
                            </button>
                        </div>
                        <Seprator width={40} />
                        <div className="catalog queen">
                            <div className='details'>
                            <div className='icon queen'>
                                <img src={queen} className="bee-type" alt="bee" />
                            </div>
                            <Seprator height={20} />
                            <p className="header">Queen Bee</p>
                            <Seprator height={10} />
                            <h5 className="subheader">Hive Master</h5>
                            <Seprator height={30} />
                            <p className="title">Pre-Approved</p>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='white' />
                                <p>Cash buyers are auto-approved</p>
                            </div>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='white' />
                                <p>Upload Pre-Approval letter</p>
                            </div>
                            <Seprator height={30} />
                            <p className='or-spacer'>OR</p>
                            <Seprator height={30} />
                            <div className="item">
                                <Icons.CircleCheck color='white' />
                                <p>Get pre-approved through a verified ShowingBee lender</p>
                            </div>
                            <Seprator height={30} />
                            <p className="title">
                                Sellers & Agents may see you as a ready buyer
                            </p>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='white' />
                                <p>Save searches and add to favorites</p>
                            </div>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='white' />
                                <p>Request Showings via Live Calendar</p>
                            </div>
                        </div>
                            <button className="button medium tier-button queen" onClick={() => navigate('/login')}>
                                Become a Queen Bee!
                            </button>
                        </div>

                        <Seprator width={40} />
                        <div className="catalog active">
                            <div className='details'>
                                <div className='icon'>
                                    <img src={house} className="bee-type" alt="bee" />
                                </div>
                                <Seprator height={20} />
                                <p className="header">House Bee</p>
                                <Seprator height={10} />
                                <p className="subheader">Basic</p>
                                <Seprator height={30} />
                                <p className="title"> Pre-Qualified</p>
                                <Seprator height={20} />
                                <div className="item">
                                    <Icons.CircleCheck color='#808080' />
                                    <p>Upload Pre-Qualification letter</p>
                                </div>
                                <Seprator height={20} />
                                <div className="item">
                                    <Icons.CircleCheck color='#808080' />
                                    <p>Fill out buyer questionnaire</p>
                                </div>
                                <Seprator height={30} />
                                <p className="title">Sellers & Agents may see you as a serious buyer</p>
                                <Seprator height={20} />
                                <div className="item">
                                    <Icons.CircleCheck color='#808080' />
                                    <p>Save searches and add to favorites</p>
                                </div>
                                <Seprator height={20} />
                                <div className="item">
                                    <Icons.CircleCheck color='#808080' />
                                    <p className="proxima font--bold text-color--light-grey">Request Showings via Live Calendar</p>
                                </div>
                            </div>
                            <button className="button primary medium tier-button" onClick={() => navigate('/login')}>
                                Become a House Bee!
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer subfooter />
        </>
    )
}


export default PublicCatalog;