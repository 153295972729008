import Section from "components/Section";
import Seprator from "components/Separator";
import ToastMessage from "components/ToastMessage";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import actions from "store/actions";
import { Config } from "utils";
import { getErrorMessage, inputChange } from "utils/modules/Handlers";
import './contacts.scss'

const Contacts = () => {
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const { connections: { data, previous, next, total } } = useSelector((state) => state.agent)
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(1);
    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
        phone_number: {
            value: '',
            error: '',
        },
    });
    
    useEffect(() => {
        const init = async() => {
            await dispatch(actions.agent.fetchBuyerAgentConnection())
        }
        init();
    }, [])

    useEffect(() => {
        const init = async() => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total/5)||1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    name: i,
                    value: `${Config.API_URL}/buyer-agent-connection/?limit=5&offset=${offset}`
                })
                offset += 5
            }
            setPages(pageLinks)
        }
        init();
    }, [total])

    const loadPageData = async({ page, pageNumber }) => {
        await dispatch(actions.agent.fetchBuyerAgentConnectionPagination(page));
        setPage(pageNumber);
    }

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validatePhone = (phone_number) => {
        let phone = `+1${phone_number}`
        return phone.match(/(^\+\d{11}(\d{2})?$)/);
    };

    const onInvite = useCallback(async() => {
        const { email, first_name, last_name, phone_number } = form
        let isValid = true

        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: ''})
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
        }
        if (phone_number.value) {
            if (!validatePhone(phone_number.value)) {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: ''})
            }
        }
        
        if (isValid) {
            const data = {
                first_name: first_name.value,
                last_name: last_name.value,
                email: email.value,
                phone_number: phone_number.value ? `1${phone_number.value}` : '',
            }
            try {
                await dispatch(actions.agent.inviteBuyer(data));
                await dispatch(actions.agent.fetchBuyerAgentConnection());
                setModalVisible(prev => !prev);
                toast.success('Contact invitation sent!', {
                    position: toast.POSITION.TOP_CENTER
                });
            } catch (error) {
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }, [form])

    return (
        <Section className="contacts-container" style={{ minHeight: '91.5vh' }}>
            <div className={`add-buyer-modal ${modalVisible && 'visible'}`}>
                <div className='form-section'>
                    <p className='section-title'>
                        Invite Connection (Buyer)
                    </p>
                    <Seprator height={30} />
                    <div className='form-input'>
                        <label>First Name</label>
                        <input 
                            id='first_name' 
                            placeholder='Enter First Name' 
                            type='text' 
                            className='small' 
                            value={form.first_name.value}
                            onChange={(event) => onChangeInput({event, type: "first_name"})} 
                        />
                        <span className='error-message' style={{ width: 240 }}>{form.first_name.error}</span>
                    </div>
                    <Seprator height={20} />
                    <div className='form-input'>
                        <label>Last Name</label>
                        <input 
                            id='last_name' 
                            placeholder='Enter Last Name' 
                            type='text' 
                            className='small' 
                            value={form.last_name.value}
                            onChange={(event) => onChangeInput({event, type: "last_name"})} 
                        />
                        <span className='error-message' style={{ width: 240 }}>{form.last_name.error}</span>
                    </div>
                    <Seprator height={20} />
                    <div className='form-input'>
                        <label>Email Address</label>
                        <input 
                            id='email' 
                            placeholder='Enter Email Address' 
                            type='text' 
                            className='small' 
                            value={form.email.value}
                            onChange={(event) => onChangeInput({event, type: "email"})} 
                        />
                        <span className='error-message' style={{ width: 240 }}>{form.email.error}</span>
                    </div>
                    <Seprator height={20} />
                    <div className='form-input'>
                        <label>Phone Number</label>
                        <input 
                            id='phone_number' 
                            placeholder='Enter Phone Number (Optional)' 
                            type='text' 
                            className='small' 
                            maxLength={10} 
                            value={form.phone_number.value}
                            onChange={(event) => onChangeInput({event, type: "phone_number"})} 
                        />
                        <span className='error-message' style={{ alignSelf: 'flex-start' }}>{form.phone_number.error}</span>
                    </div>
                    <Seprator height={35} />
                    <button className='button primary small no-glow' onClick={onInvite}>
                       Invite
                    </button>
                    <Seprator height={15} />
                    <button className='button cancel small no-glow' onClick={() => setModalVisible(prev => !prev)}>Cancel</button>
                </div>
            </div>
            <h1 className="header">My Connections</h1>
            <div className="table-filters">
                <input className="search" type="text" placeholder="Search Buyer" />
                <button 
                    className='button secondary' 
                    onClick={() => setModalVisible(prev => !prev)}
                >
                    Invite Connection
                </button>
            </div>
            <Seprator height={20} />
            <div className="table-container">
                <table cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th width="210px"><div>Name</div></th>
                            <th width="210px"><div>Phone</div></th>
                            <th width="385px"><div>Email</div></th>
                            {/* <th width="210px"><div>Invite Status</div></th> */}
                            {/* <th width="146px"><div>Action</div></th> */}
                        </tr>
                    </thead>
                    <tbody id="showings-table">
                    {
                            data.length > 0 ?
                            data.map((connection, index) => {
                                return (
                                    <tr className="body" key={index}>
                                        <td data-label="Name" align="center">
                                            <p>{connection.buyer.first_name} {connection.buyer.last_name}</p>
                                        </td>
                                        <td data-label="Phone" align="center">
                                            <p>{connection.buyer.phone_number}</p>
                                        </td>
                                        <td data-label="Email" align="center">
                                            <p>{connection.buyer.email}</p>
                                        </td>
                                        {/* <td align="center">
                                            <div className="status-container">
                                                <div className={`status-label ${connection.buyer_approved ? "green" : "grey"}`}>
                                                    {connection.buyer_approved ? "Approved" : "Waiting for Buyer Approval"}
                                                </div>
                                            </div>
                                        </td> */}
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan="3">
                                    <div className="empty-table">No Available Connection</div>
                                </td>
                            </tr> 
                        }
                    </tbody>
                </table>
            </div>
            {
                data.length > 0 &&
                <div className="pagination">
                    <p className="previous" onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}> { previous && 'Previous' } </p>
                    <ul> 
                        {
                            pages.map((item, index) => {
                                return(
                                    <li 
                                        key={index}
                                        className={`${item.name === page && 'active'}`}
                                        onClick={() => loadPageData({ page: item.value, pageNumber: item.name })}
                                    >
                                        {item.name}
                                    </li>)
                            })
                        }
                    </ul>
                    <p className="next" onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}> { next && 'Next' } </p>
                </div>
            }
        </Section>
    )
}


export default Contacts;