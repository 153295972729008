import Icons from 'components/Icons';
import Separator from 'components/Separator';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//FilePond
import { FilePond, registerPlugin } from 'react-filepond';
//FilePond Packages
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
//FilePond Styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';

import './upgradeForm.scss';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { checkImageUrl, getErrorMessage } from 'utils/modules/Handlers';
import NoUser from 'assets/images/no_user.jpg'
import DefaultBrokerModal from '../DefaultBrokerModal';

// FilePond Register Plug-ins
registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginFileEncode,
    FilePondPluginMediaPreview
)

const UpgradeForm = () => {
    const { tier } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState({
        value: '',
        error: '',
    })
    const [files, setFiles] = useState([])
    const [type, setType] = useState('F')
    const [income, setIncome] = useState('')
    const [otherIncome, setOtherIncome] = useState('')
    const [profile, setProfile] = useState({})
    const [brokerModal, setBrokerModal] = useState(false)


    const fetchProfile = async() => {
        const data = await dispatch(actions.buyer.fetchBuyerProfile());
        if (data.length > 0) {
            setProfile(data[0]);
        }
    }

    useEffect(() => {
        fetchProfile();
    }, [])

    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const onFileUpload = (files) => {
        let fileData = []
        try {
            files.map(file => {
                return fileData.push(file.getFileEncodeDataURL())
            })
            if (fileData.length > 0) setFiles(fileData);
            else setFiles([])
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = useCallback(async() => {
        if (type === 'F') {
            let isValid = true
            if (!profile.default_mortgage_broker) {
                if (!validateEmail(email.value)) {
                    setEmail({ ...email, error: 'Entered email address is invalid' })
                    isValid = false
                } else {
                    setEmail({ ...email, error: '' })
                }
            }
            if (isValid) {
                const data = {
                    email: profile.default_mortgage_broker ? profile.default_mortgage_broker.email : email.value,
                    buyer_tier: tier === 'house' ? 'H' : 'Q',
                    user_type: 'mortgage_broker',
                }
                if (files[0]) {
                    data.approval_document = files[0]
                }
                try {
                    await dispatch(actions.buyer.inviteMortgageBroker(data));
                    await dispatch(actions.auth.retrieveUser());
                    navigate('/bee-tier/');
                    toast.success('Upgrade request successfully sent!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                } catch (error) {
                    toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        } else {
            let data = {
                buyer_type: type,
                buyer_tier: tier === 'house' ? 'H' : 'Q'
            }
            if (files[0]) {
                data.proof_of_funds_document = files[0]
            }
            if (income === 'Other' && otherIncome) {
                data.buyer_income_source = otherIncome;
            } else {
                data.buyer_income_source = income;
            }
            try {
                await dispatch(actions.buyer.cashBuyerUpgrade(data));
                await dispatch(actions.auth.retrieveUser());
                navigate('/bee-tier/');
                toast.success('Upgrade request successfully sent!', {
                    position: toast.POSITION.TOP_CENTER
                });
            } catch (error) {
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }, [profile, email, files, type])

    return (
        <section className="upgrade-bee-tier-container">
            {/* Back & Next */}
            <DefaultBrokerModal refetch={fetchProfile} modal={brokerModal} setModal={setBrokerModal} profile={profile} />
            <Separator height={100} />
            <div className='back-next-container'> 
                <div>
                    <p className='back-button' onClick={() => navigate('/bee-tier/')}>Back</p>
                    <h1 className="title">Upgrade Bee Tier Form</h1>
                </div>
                <p className='subtitle'>Thank you for choosing to upgrade your tier.</p>
            </div>
            <div className='contents'>
                <div className='upgrade-form'>
                    {
                         tier === 'queen' &&
                        <>
                            <ul className='type-choices'>
                                <li className={`${type === 'F' && 'selected'}`} onClick={() => setType('F')}>
                                    Finance Buyer
                                </li>
                                <li className={`${type === 'C' && 'selected'}`} onClick={() => setType('C')}>
                                    Cash Buyer
                                </li>
                            </ul>
                            <>
                                {
                                    type === 'C' &&
                                    <div className='income-container'>
                                        <Separator height={20} />
                                        <span className='section-subtitle'>Please choose your sources of funds:</span>
                                        <Separator height={10} />
                                        <div className='form'>
                                            <div className='form-input choices'>
                                                <input 
                                                    type="radio" 
                                                    id='buyer_income_source_1' 
                                                    name='buyer_income_source'
                                                    checked={income === 'Checking Personal' ? true : false}
                                                    onChange={() => setIncome('Checking Personal')}
                                                />
                                                <label htmlFor='buyer_income_source_1'>Checking Personal</label>
                                            </div>
                                        </div>
                                        <Separator height={10} />
                                        <div className='form'>
                                            <div className='form-input choices'>
                                                <input 
                                                    type="radio" 
                                                    id='buyer_income_source_2' 
                                                    name='buyer_income_source' 
                                                    checked={income === 'IRA' ? true : false}
                                                    onChange={() => setIncome('IRA')} 
                                                />
                                                <label htmlFor='buyer_income_source_2'>IRA</label>
                                            </div>
                                        </div>
                                        <Separator height={10} />
                                        <div className='form'>
                                            <div className='form-input choices'>
                                                <input 
                                                    type="radio" 
                                                    id='buyer_income_source_3' 
                                                    name='buyer_income_source'
                                                    checked={income === '1031 Exchange' ? true : false}
                                                    onChange={() => setIncome('1031 Exchange')} 
                                                />
                                                <label htmlFor='buyer_income_source_3'>1031 Exchange</label>
                                            </div>
                                        </div>
                                        <Separator height={10} />
                                        <div className='form'>
                                            <div className='form-input choices'>
                                                <input 
                                                    type="radio" 
                                                    id='buyer_income_source_4' 
                                                    name='buyer_income_source'
                                                    checked={income === 'Wealth Management Account' ? true : false}
                                                    onChange={() => setIncome('Wealth Management Account')} 
                                                />
                                                <label htmlFor='buyer_income_source_4'>Wealth Management Account</label>
                                            </div>
                                        </div>
                                        <Separator height={10} />
                                        <div className='form'>
                                            <div className='form-input choices'>
                                                <input 
                                                    type="radio" 
                                                    id='buyer_income_source_5' 
                                                    name='buyer_income_source' 
                                                    onChange={() => setIncome('Other')} 
                                                    checked={income === 'Other' ? true : false}
                                                />
                                                <label htmlFor='buyer_income_source_5'>Other</label>
                                            </div>
                                        </div>
                                        {
                                            income === 'Other' &&
                                            <>
                                                <Separator height={10} />
                                                <div className='form'>
                                                    <div className='form-input choices'>
                                                        <input 
                                                            type="text"
                                                            className='small'
                                                            value={otherIncome}
                                                            placeholder='Please specify other income source'
                                                            onChange={(event) => setOtherIncome(event.target.value)}
                                                            style={{ width: 450 }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                }
                            </>

                            <Separator height={20} />
                        </>
                    }
                    <div className='form'>
                        <div className='form-input'>
                            <label>Approval Document</label>
                            <Separator height={10} />
                            <FilePond
                                files={files}
                                oninit={() => {}}
                                maxFileSize="5MB"
                                imagePreviewHeight={110}
                                allowMultiple={false}
                                allowFileTypeValidation={true}
                                acceptedFileTypes={['image/*', 'application/pdf']}
                                onupdatefiles={(fileItems) => {
                                    onFileUpload(fileItems);
                                }}
                            />
                        </div>
                    </div>
                    <Separator height={30} />
                    {
                        type === 'F'
                        ? 
                        <>
                            {
                                profile.default_mortgage_broker 
                                ? 
                                <div className='form'>
                                    <div className='form-input'>
                                        <label>Your Default Mortgage Broker</label>
                                        <Separator height={10} />
                                        <div className="broker-container" onClick={() => setBrokerModal(prev => !prev)}>
                                            <img className="broker-image" src={checkImageUrl(profile.default_mortgage_broker?.profile_photo, NoUser)} alt="" />
                                            <p className="broker-info">{profile.default_mortgage_broker?.first_name} {profile.default_mortgage_broker?.last_name} / {profile.default_mortgage_broker?.email}</p>
                                        </div>
                                    </div>
                                    <Separator width={20} />
                                    <button
                                        className='button small primary no-glow'
                                        onClick={onSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                                : <div className='form'>
                                    <div className='form-input'>
                                        <label>Mortgage Broker Email Address</label>
                                        <input
                                            className='small'
                                            id='address'
                                            type='text' 
                                            placeholder='Mortgage Broker Email Address'
                                            onChange={(event) => setEmail({ ...email, value: event.target.value })}
                                        />
                                        <span className='error-message'>{email.error}</span>
                                    </div>
                                    <Separator width={20} />
                                    <button
                                        className='button small primary no-glow'
                                        onClick={onSubmit}
                                        disabled={!email}
                                    >
                                        Submit
                                    </button>
                                </div>
                            }
                        </>
                        : <div className='form'>
                            <button
                                className='button small primary no-glow'
                                onClick={onSubmit}
                                disabled={(income === 'Other' && !otherIncome) || !income}
                            >
                                Submit
                            </button>
                        </div>
                    }
                </div>
                <div className='separator'>OR</div>
                <div className="more-options-container">
                    <div className="selectbox more-options" onClick={() => navigate(`/bee-tier/brokers/${tier}`)}>
                        <Icons.More />
                        <Separator height={25} />
                        <h1 className="header">Featured Mortgage Broker</h1>
                        <p className="subheader">You may choose from our range of Mortgage Brokers to assist you all the way on achieving your dream home.</p>
                    </div>
                </div>
            </div>
            <Separator height={100} />
        </section>
    )
}


export default UpgradeForm;