// Components
import Icons from 'components/Icons';
import Separator from 'components/Separator';
import ToastMessage from 'components/ToastMessage';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import actions from 'store/actions';
// Utils
import { getErrorMessageSignup, inputChange } from 'utils/modules/Handlers';

const AccountForm = ({ onChangeInput, form, setForm, setActive, agentForm }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);

    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const onSubmit = useCallback(async(event) => {
        const { email, first_name, last_name, phone_number, password, user_type } = form
        const { email: agentEmail } = agentForm

        event.preventDefault();
        let isValid = true

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
        }

        if (password.value === '') {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: 'Password cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: ''})
        }

        if (email.value && password.value) {
            isValid = true
        } else {
            isValid = false
        }

        if (isValid) {
            setIsLoading(true);
            try {
                const data = {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                    is_staff: false,
                    password1: password.value,
                    password2: password.value,
                    timezone: 'America/New_York',
                    user_type: user_type.value === 'Agent' ? 'agent' : user_type.value === 'Mortgage Broker' ? 'mortgage_broker' : 'buyer',
                }
                if (phone_number.value) {
                    data.phone_number = phone_number.value
                }
                await dispatch(actions.auth.signup(data)).then(async() => {
                    if (agentEmail.value) {
                        const buyerData = { 
                            email: email.value,
                            agent_email: agentEmail.value
                        }
                        await dispatch(actions.auth.signupAgentInvite(buyerData))
                    }
                })
                setIsLoading(false);
                setActive('finish')
    
            } catch (error) {
                setIsLoading(false);
                toast.error(<ToastMessage messages={getErrorMessageSignup(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }, [form])


    return (
        <form 
            className="account-form-container"
            onSubmit={(event) => onSubmit(event)}
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            <input 
                type="email" 
                placeholder='Email Address'
                value={form.email.value}
                autoComplete="off"
                onChange={(event) => onChangeInput({event, type: "email"})} 
            />
            <span className='error-message'>{form.email.error}</span>
            <Separator height={20} />
            <div className='password'>
                <input 
                    type={viewPassword ? 'text' : 'password'} 
                    autoComplete="new-password"
                    placeholder='Password'
                    value={form.password.value}
                    onChange={(event) => onChangeInput({event, type: "password"})} 
                />
                <Icons.Eye color={viewPassword ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword(prev => !prev)} />
            </div>
            <span className='error-message'>{form.password.error}</span>
            <Separator height={30} />
            <button className='button primary' type='submit'>
            {
                isLoading 
                ? <Icons.Loading height={40} width={40} background={'none'} />
                : 'Next'
            }
            </button>
        </form>
    )
}


export default AccountForm;