import Separator from 'components/Separator'
import './requestDemoFooterPage.scss'
import { useDispatch, useSelector } from 'react-redux';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { inputChange, validateEmail, validatePhone } from 'utils/modules/Handlers';
import { useCallback, useState } from 'react';
import Seprator from 'components/Separator';
import Icons from 'components/Icons';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import CustomTextInput from 'components/CustomTextInput';
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';
import CustomButton from 'components/CustomButton';


const initialForm = {
    first_name: {
        value: '',
        error: '',
    },
    last_name: {
        value: '',
        error: '',
    },
    email: {
        value: '',
        error: '',
    },
    phone_number: {
        value: '',
    },
    position: {
        value: '',
        error: '',
    },
    mls_name: {
        value: '',
        error: '',
    },
}

const RequestDemoFooterPage = () => {
    const { user } = useSelector((state) => state.auth);
    const [form, setForm] = useState(initialForm);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async () => {
        const { email, first_name, last_name, phone_number, position, mls_name } = form
        let isValid = true
        if (!user.id) {
            if (first_name.value === '') {
                inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: '' })
            }

            if (last_name.value === '') {
                inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
            }

            if (!validateEmail(email.value)) {
                inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'email', value: 'error', data: '' })
            }
            if (phone_number.value) {
                if (!validatePhone(phone_number.value)) {
                    inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
                    isValid = false
                } else {
                    inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: '' })
                    isValid = true
                }
            }
        }

        if (position.value === '') {
            inputChange({ setForm: setForm, key: 'position', value: 'error', data: 'Position cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'position', value: 'error', data: '' })
            isValid = true
        }

        if (mls_name.value === '') {
            inputChange({ setForm: setForm, key: 'mls_name', value: 'error', data: 'MLS Name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'mls_name', value: 'error', data: '' })
            isValid = true
        }

        if (isValid) {
            setLoading(true);
            try {
                let dataForm = {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                    phone: phone_number.value,
                    position: position.value,
                    mls_name: mls_name.value,
                };

                await dispatch(actions.auth.demoRequest(dataForm)).then(() => {
                    toast.success('Request for a Demo Sent!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setLoading(false);
                });

            } catch (error) {
                toast.error('Something went wrong!', {
                    position: toast.POSITION.TOP_CENTER
                });
                setLoading(false);
            }
            clearInput();
        }
    }, [form, user])

    const clearInput = () => {
        setForm(initialForm);

        let first_name = document.getElementById('first_name')
        if (first_name) { first_name.value = '' }

        let last_name = document.getElementById('last_name')
        if (last_name) { last_name.value = '' }

        let email = document.getElementById('email')
        if (email) { email.value = '' }

        let phone_number = document.getElementById('phone_number')
        if (phone_number) { phone_number.value = '' }

        let position = document.getElementById('position')
        if (position) { position.value = '' }

        let mls_name = document.getElementById('mls_name')
        if (mls_name) { mls_name.value = '' }
    }

    return (
        <>
            {
                !user.id && <Header darkmode />
            }
            <PageWrapper>
                <CustomContainer>
                    <div className='request-demo-container'>
                        <div className='form'>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, alignItems: 'center' }}>
                                <p className='header'>Schedule a Demo</p>
                                <span className='subheader'>
                                    Reach out to engage with us and explore how you can be part of the Showing Bee success story. Please fill out the form below to request a demo. Please include your position and MLS.
                                </span>
                                <Separator height={40} />
                                <CustomTextInput
                                    inputProps={{
                                        type: "text",
                                        label: 'First Name',
                                        placeholder: 'John',
                                        id: 'first_name',
                                        value: form.first_name.value,
                                        onChange: (event) => onChangeInput({ event, type: "first_name" }),
                                    }}
                                    size={'large'}
                                    style={{ width: 300 }}
                                    error={form.first_name.error}
                                />

                                <CustomTextInput
                                    inputProps={{
                                        type: "text",
                                        label: 'Last Name',
                                        placeholder: 'Doe',
                                        id: 'last_name',
                                        value: form.last_name.value,
                                        onChange: (event) => onChangeInput({ event, type: "last_name" }),
                                    }}
                                    size={'large'}
                                    style={{ width: 300 }}
                                    error={form.last_name.error}
                                />

                                <CustomTextInput
                                    inputProps={{
                                        type: "text",
                                        label: 'Phone Number (Optional)',
                                        placeholder: '10-digit Phone Number',
                                        id: 'phone_number',
                                        value: form.phone_number.value,
                                        onChange: (event) => onChangeInput({ event, type: "phone_number" }),
                                    }}
                                    size={'large'}
                                    style={{ width: 300 }}
                                    error={form.phone_number.error}
                                />

                                <CustomTextInput
                                    inputProps={{
                                        type: "text",
                                        label: 'Email Address',
                                        placeholder: 'example@email.com',
                                        id: 'email',
                                        value: form.email.value,
                                        onChange: (event) => onChangeInput({ event, type: "email" }),
                                    }}
                                    size={'large'}
                                    style={{ width: 300 }}
                                    error={form.email.error}
                                />
                                <CustomTextInput
                                    inputProps={{
                                        type: "text",
                                        label: 'Position',
                                        placeholder: 'Sales Director',
                                        id: 'position',
                                        value: form.position.value,
                                        onChange: (event) => onChangeInput({ event, type: "position" }),
                                    }}
                                    size={'large'}
                                    style={{ width: 300 }}
                                    error={form.position.error}
                                />
                                <CustomTextInput
                                    inputProps={{
                                        type: "text",
                                        label: 'MLS Name',
                                        placeholder: 'Multiple Listing Service',
                                        id: 'mls_name',
                                        value: form.mls_name.value,
                                        onChange: (event) => onChangeInput({ event, type: "mls_name" }),
                                    }}
                                    size={'large'}
                                    style={{ width: 300 }}
                                    error={form.mls_name.error}
                                />
                                <CustomButton text={'Schedule Now'} style={{ width: 300 }} size={'large'} loading={loading} action={onSubmit} />
                                <Separator height={40} />
                                <span className='subheader' style={{ fontSize: 14, fontWeight: 700, color: '#2a2a2a' }}>Thank you for considering Showing Bee. We look forward to the opportunity of collaborating with you.</span>
                                <Separator height={40} />
                            </div>
                        </div>
                    </div>
                </CustomContainer>
            </PageWrapper>
            <Footer />
        </>
    )
}


export default RequestDemoFooterPage;