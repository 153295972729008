import { useCallback, useEffect, useMemo, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
// Packages
import debouce from 'lodash.debounce'
import moment from 'moment';
//  Modules
import ListingBlock from "./modules/ListingBlock";
import StatusBlock from "./modules/StatusBlock";
import ActionBlock from "./modules/ActionBlock";
import ConnectedUserModal from "./modules/ConnectedUserModal";
import UsersBlock from "./modules/UsersBlock";
// Components
import Icons from "components/Icons";
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import { Config } from "utils";
import './manageShowings.scss';
import TableHeader from "components/TableHeader";

const ManageShowings = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth)
    const {data: showings, previous, next, total } = useSelector((state) => state.showing)
    const [statusMenu, setStatusMenu] = useState(false);
    const [statusFilter, setStatusFilter] = useState('all');
    const [duration, setDuration] = useState('current');
    const [typeMenu, setTypeMenu] = useState(false);
    const [typeFilter, setTypeFilter] = useState('all');
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedPage, setSelectedPage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({
        status: '',
        agent_type: '',
        date: '',
        start: '',
        finish: '',
    })

    // useEffect(() => {
    //     const init = async() => {
    //         setIsLoading(true);
    //         try {
    //             await dispatch(actions.showing.fetchShowingsAll()).then(() => {
    //                 setIsLoading(false)
    //             })
    //         } catch (error) {
    //             setIsLoading(false)
    //         }
    //     }
    //     init();
    // }, [])

    useEffect(() => {
        const init = async() => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total/20)||1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    name: i,
                    value: `${Config.API_URL}/showing/search/?limit=20&offset=${offset}&duration=${duration}&exclude_draft=true&${filters}`
                })
                offset = i * 20
            }
            setPages(pageLinks)
        }
        init();
    }, [duration, filters, total])

    const loadPageData = async({ page, pageNumber }) => {
        await dispatch(actions.showing.fetchShowingsPagination(page));
        setSelectedPage(page);
        setPage(pageNumber);
    }

    const formatTime = (data) => {
        const startDate = moment(new Date(data.start)).format('dddd');
        return startDate
    }

    const formatDay = (data) => {
        const startDate = moment(new Date(data.start)).format('MMMM DD, YYYY');
        return startDate
    }

    const formatDate = (data) => {
        const startDate = moment(new Date(data)).format('MM/DD/YY');
        return startDate
    }

    const onStatusSelect = useCallback((status) => {
        setStatusFilter(status);
        if (status === 'all') {
            setFilters({...filters, status: ''});
        } else {
            setFilters({...filters, status: `status=${status}`});
        }
        setStatusMenu(false)
    }, [filters])

    const onTypeSelect = useCallback((type) => {
        setTypeFilter(type);
        if (type === 'all') {
            setFilters({...filters, agent_type: ''});
        } else {
            setFilters({...filters, agent_type: `agent_type=${type}`});
        }
        setTypeMenu(false)
    }, [filters])


    const onSearch = useCallback(async() => {
        setIsLoading(true);
        try {
            let data = [];
            if (search) {
                data.push(search)
            }
            if (filters.status) {
                data.push(filters.status)
            }
            if (filters.agent_type) {
                data.push(filters.agent_type)
            }
            if (filters.date) {
                const checkDate = moment(filters.date)
                if (checkDate.isValid()) {
                    data.push(filters.date)
                } else {
                    setFilters({...filters, date: '', start: '', finish: ''})
                    document.getElementById('start').value = ''
                    document.getElementById('finish').value = ''
                }
            }
            if (filters.start) {
                data.push(filters.start)
            }
            if (filters.finish) {
                data.push(filters.finish)
            }
            await dispatch(actions.showing.searchShowings({ offset: 0, filters: data.length > 0 ? data.join('&') : '', duration: duration })).then(() => {
                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
            console.log(error)
        }
    }, [dispatch, duration, filters, search])

    useEffect(() => {
        const handleSearch = () => {
            onSearch();
        }
        handleSearch()
    }, [duration, filters, search])

    const onChangeInput = useCallback((event) => {
        setSearch(`search=${event.target.value}`)
    }, [])

    const handleInput = useMemo(() => {
        return debouce(onChangeInput, 300)
    }, [onChangeInput])

    const onArchived = () => {
        setDuration((prev) => prev === 'current'? 'past' : 'current');
    }

    return (
        <Section className="manage-showing-container">
            <ConnectedUserModal modal={modal} setModal={setModal} data={selectedData} />
            <TableHeader title={duration === 'past' ? 'Archived Showings' : 'Manage Showings'}>
                <div className="header-button" onClick={() => onArchived()}>
                    {
                        duration === 'past' 
                        ? <div><Icons.Manage /> <p>Manage Showing</p></div>
                        : <div><Icons.Archived /> <p>Archived</p></div>
                    }
                </div>
            </TableHeader>
            <div className="table-filters">
                <input id="search" className="search" type="text" placeholder="Listing, MLS Number or Showing Bee ID" onChange={handleInput} />

                <Seprator width={30} />
                <div className={`status type ${typeMenu ? 'active' : 'inactive'}`} onClick={() => setTypeMenu((prev) => !prev)}>
                    <p className={`${typeFilter}`}>
                        {typeFilter === 'all' ? 'All Showings' : typeFilter === 'listing' ? 'As Listing Agent' : typeFilter === 'buyer' ? 'As Buyer\'s Agent' : 'As Team Member' }
                    </p>
                    <div className={`status-menu ${typeMenu ? 'active' : 'inactive'}`} onClick={(e) => e.stopPropagation()}>
                        <p className={`${typeFilter === 'all' && 'active'}`} onClick={() => onTypeSelect('all')}> <span className="all"></span> All Showings</p>
                        <p className={`${typeFilter === 'listing' && 'active'}`} onClick={() => onTypeSelect('listing')}> <span className="listing"></span> As Listing Agent</p>
                        <p className={`${typeFilter === 'buyer' && 'active'}`} onClick={() => onTypeSelect('buyer')}> <span className="buyer"></span> As Buyer's Agent</p>
                        <p className={`${typeFilter === 'member' && 'active'}`} onClick={() => onTypeSelect('member')}> <span className="member"></span> As Team Member</p>
                    </div>
                </div>

                <Seprator width={30} />
                <div className={`status ${statusMenu ? 'active' : 'inactive'}`} onClick={() => setStatusMenu((prev) => !prev)}>
                    <p className={`${statusFilter}`}>
                        {statusFilter === 'all' ? 'All Status' : statusFilter === 'ongoing' ? 'In Progress' : statusFilter === 'upcoming' ? 'Approved' : statusFilter}
                    </p>
                    <div className={`status-menu ${statusMenu ? 'active' : 'inactive'}`} onClick={(e) => e.stopPropagation()}>
                        <p className={`${statusFilter === 'all' && 'active'}`} onClick={() => onStatusSelect('all')}> <span className="all"></span> All Status</p>
                        {/* <p className={`${statusFilter === 'draft' && 'active'}`} onClick={() => onStatusSelect('draft')}>Draft</p> */}
                        <p className={`${statusFilter === 'pending' && 'active'}`} onClick={() => onStatusSelect('pending')}> <span className="pending"> </span>Pending</p>
                        <p className={`${statusFilter === 'approved' && 'active'}`} onClick={() => onStatusSelect('upcoming')}> <span className="approved"> </span>Approved</p>
                        <p className={`${statusFilter === 'rejected' && 'active'}`} onClick={() => onStatusSelect('rejected')}> <span className="rejected"> </span>Rejected</p>
                        <p className={`${statusFilter === 'ongoing' && 'active'}`} onClick={() => onStatusSelect('ongoing')}> <span className="ongoing"> </span>In Progress</p>
                        <p className={`${statusFilter === 'finished' && 'active'}`} onClick={() => onStatusSelect('finished')}> <span className="finished"> </span>Finished</p>
                        <p className={`${statusFilter === 'cancelled' && 'active'}`} onClick={() => onStatusSelect('cancelled')}> <span className="cancelled"> </span>Cancelled</p>
                    </div>
                </div>
                <Seprator width={30} />
                <input id="date" type="date" className="date" onChange={(event) => setFilters({...filters, date: `date=${event.target.value}`})} />
                <Seprator width={30} />
                <div className="time">
                    <input 
                        id="start" 
                        type="time"
                        name="start_time"
                        autoComplete="new-time"
                        disabled={!filters.date}
                        onChange={(event) => setFilters({...filters, start: `start=${event.target.value}`})} 
                        />
                    <p>to</p>
                    <input 
                        id="finish" 
                        type="time"
                        name="end_time"
                        autoComplete="new-time"
                        disabled={!filters.date}
                        onChange={(event) => setFilters({...filters, finish: `finish=${event.target.value}`})} 
                    />
                </div>
            </div>
            <Seprator height={20} />
            <div className="table-container">
                {
                    isLoading &&
                    <div className="loader">
                        <Icons.Loading background={'none'} height={100} width={100} />
                    </div>
                }
                <table cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th width="60px"></th>
                            <th width="300px"><div >Listing</div></th>
                            <th width="210px"><div >Connected Users</div></th>
                            {/* <th width="210px"><div >Homeowner(s)</div></th>
                            <th width="210px"><div >Buyer</div></th> */}

                            <th width="80px"><div >Requested</div></th>
                            {/* <th width="146px"><div >Date</div></th> */}
                            <th width="120px"><div >Schedule</div></th>
                            <th width="146px"><div >Showing Status</div></th>
                            {
                                duration === 'current' &&
                                <>
                                    <th width="160px"><div >Approval Status</div></th>
                                </>
                            }
                            <th width="220px"><div >Action</div></th>
                        </tr>
                    </thead>
                    <tbody id="showings-table">
                        {
                            showings.length > 0
                            ? showings.map((showing, index) => {
                                return (
                                    <tr className='body' key={index}>
                                        <td className="select" data-label="Select" style={{ textAlign: 'center' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', rowGap: 10 }}>
                                                {
                                                    showing.buyers_data[0]?.buyer_tier === 'Q' &&
                                                    <Icons.QueenBeeTier height={20} width={20} />
                                                }
                                                {
                                                    showing.listing_data?.agent_members.includes(user?.id) && <Icons.TeamMemberIndicator height={20} width={20} />
                                                }
                                                {
                                                    showing.buyer_agent === user?.id && <Icons.BuyerAgentIndicator height={20} width={20} />
                                                }
                                                {
                                                    showing.listing_agent === user?.agent_profile?.id && <Icons.ListingAgentIndicator height={20} width={20} />
                                                }
                                            </div>
                                        </td>
                                        <td data-label="Listing">
                                            <ListingBlock data={showing} />
                                        </td>
                                        <td data-label="Users">
                                            <UsersBlock data={showing} setModal={setModal} setSelectedData={setSelectedData} />
                                        </td>
                                        {/* <td data-label="Seller">
                                            <SellerBlock data={showing.listing_data} />
                                        </td>
                                        <td data-label="Buyer(s)">
                                            <BuyerBlock data={showing} />
                                        </td> */}
                                        <td data-label="Requested" align="center">
                                            <div className="date">
                                                <p>{ formatDate(showing.created_at) }</p>
                                            </div>
                                        </td>
                                        {/* <td data-label="Date" align="center">
                                            <div className="date">
                                                <p>{ showing.date }</p>
                                            </div>
                                        </td> */}
                                        <td data-label="Schedule" align="center">
                                            <div className="time">
                                                <p>{formatDay(showing)}</p>
                                                <Seprator height={5} />
                                                <p>{formatTime(showing)}</p>
                                                <Seprator height={5} />
                                                <p>{showing.start_time} - {showing.finish_time}</p>
                                                <Seprator height={5} />
                                                <p style={{ fontSize: 12 }}>({showing.listing_data.timezone_display.split(" ")[0]})</p>
                                            </div>
                                        </td>
                                        <td data-label="Showing Status" align="center">
                                            <p className={`showing-status ${showing.status}`}>{showing.status_display}</p>
                                        </td>
                                        {
                                            duration === 'current' &&
                                            <>
                                                <td data-label="Approval Status" align="center">
                                                    <StatusBlock data={showing} />
                                                </td>
                                            </>
                                        }
                                        <td data-label="Action">
                                            <ActionBlock 
                                                data={showing}
                                                refresh={onSearch}
                                                selectedPage={selectedPage}
                                                isLoading={isLoading}
                                                setIsLoading={setIsLoading}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td colSpan={`${duration === 'current' ? '8' : '6'}`}>
                                    <div className="empty-table">
                                        {
                                            !isLoading &&
                                            'No Available Showings'
                                        }
                                    </div>
                                </td>
                            </tr> 
                        }
                    </tbody>
                </table>
            </div>
            {
                showings.length > 0 &&
                <div className="pagination">
                    <p className="previous" onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}> { previous && 'Previous' } </p>
                    <ul> 
                        {
                            pages.map((item, index) => {
                                return(
                                    <li 
                                        key={index}
                                        className={`${item.name === page && 'active'}`}
                                        onClick={() => loadPageData({ page: item.value, pageNumber: item.name })}
                                    >
                                        {item.name}
                                    </li>)
                            })
                        }
                    </ul>
                    <p className="next" onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}> { next && 'Next' } </p>
                </div>
            }
        </Section>
    )
}


export default ManageShowings;