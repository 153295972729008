import * as React from "react"

const ArrowLeft = (props) => (
  <svg
    width={11}
    height={19}
    viewBox="0 0 11 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.70002 18.575L0.275016 10.175C0.175016 10.075 0.104016 9.96667 0.0620156 9.85C0.0200156 9.73333 -0.000651042 9.60833 1.5625e-05 9.475C1.5625e-05 9.34167 0.0210156 9.21667 0.0630156 9.1C0.105016 8.98333 0.175682 8.875 0.275016 8.775L8.70002 0.35C8.93335 0.116667 9.22502 0 9.57502 0C9.92502 0 10.225 0.125 10.475 0.375C10.725 0.625 10.85 0.916667 10.85 1.25C10.85 1.58333 10.725 1.875 10.475 2.125L3.12502 9.475L10.475 16.825C10.7083 17.0583 10.825 17.346 10.825 17.688C10.825 18.03 10.7 18.3257 10.45 18.575C10.2 18.825 9.90835 18.95 9.57502 18.95C9.24168 18.95 8.95002 18.825 8.70002 18.575Z"
      fill="#6C767C"
    />
  </svg>
)


export default ArrowLeft
