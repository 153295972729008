import Facebook from "./modules/Facebook";
import Twitter from "./modules/Twitter";
import Email from "./modules/Email";
import Close from "./modules/Close";
import BoxArrowLeft from "./modules/BoxArrowLeft";
import BoxArrowRight from "./modules/BoxArrowRight";
import Bed from "./modules/Bed";
import Bath from "./modules/Bath";
import Car from "./modules/Car";
import House from "./modules/House";
import ProBadge from "./modules/ProBadge";
import CircleCheck from "./modules/CircleCheck";
import Waggle from "./modules/Waggle";
import Market from "./modules/Market";
import Search from "./modules/Search";
import Message from "./modules/Message";
import Swap from "./modules/Swap";
import Contact from "./modules/Contact";
import Profile from "./modules/Profile";
import Logout from "./modules/Logout";
import WaggleIcon from "./modules/WaggleIcon";
import Media from "./modules/Media";
import Heart from "./modules/Heart";
import Save from "./modules/Save";
import BuyerBadge from "./modules/BuyerBadge";
import BrokerBadge from "./modules/BrokerBadge";
import SellerBadge from "./modules/SellerBadge";
import BuyerAgentBadge from "./modules/BuyerAgentBadge";
import ListingAgentBadge from "./modules/ListingAgentBadge";
import Calendar from "./modules/CalendarIcon";
import Globe3D from "./modules/Globe3D";
import Matterport from "./modules/Matterport";
import Youtube from "./modules/Youtube";
import LeftArrow from "./modules/LeftArrow";
import Hive from "./modules/Hive";
import CaretDown from "./modules/CaretDown";
import CircleClose from "./modules/CircleClose";
import Clock from "./modules/Clock";
import Star from "./modules/Star";
import Dashboard from "./modules/Dashboard";
import Approve from "./modules/Approve";
import Pending from "./modules/Pending";
import Reject from "./modules/Reject";
import Showing from "./modules/Showing";
import Listing from "./modules/Listing";
import Inquiries from "./modules/Inquiries";
import Home from "./modules/Home";
import More from "./modules/More";
import EmailSmall from "./modules/EmailSmall";
import MiniVerified from "./modules/MiniVerified";
import PhoneSmall from "./modules/PhoneSmall";
import Minus from "./modules/Minus";
import Add from "./modules/Add";
import Eye from "./modules/Eye";
import Coins from "./modules/Coins";
import Marker from "./modules/Marker";
import Grid from "./modules/Grid";
import List from "./modules/List";
import SavedSearch from "./modules/SavedSearch";
import ArrowDown from "./modules/ArrowDown";
import Location from "./modules/Location";
import LocationMark from "./modules/LocationMark";
import Target from "./modules/Target";
import Property from "./modules/Property";
import Lease from "./modules/Lease";
import PropertySize from "./modules/PropertySize";
import Price from "./modules/Price";
import Loading from "./modules/Loading";
import General from "./modules/General";
import Group from "./modules/Group";
import ContactRequest from "./modules/ContactRequest";
import Settings from "./modules/Settings";
import HouseAlt from "./modules/HouseAlt";
import Tooltip from "./modules/Tooltip";
import Edit from "./modules/Edit";
import Instruction from "./modules/Instruction";
import LocationAlt from "./modules/LocationAlt";
import Image from "./modules/Image";
import MortgageConnection from "./modules/MortgageConnection";
import BackArrow from "./modules/BackArrow";
import Share from "./modules/Share";
import Filter from "./modules/Filter";
import Info from "./modules/Info";
import ShowingForm from "./modules/ShowingForm";
import Security from "./modules/Security";
import Pencil from "./modules/Pencil";
import Delete from "./modules/Delete";
import Document from "./modules/Document";
import DOC from "./modules/DOC";
import PDF from "./modules/PDF";
import ODT from "./modules/ODT";
import Draft from "./modules/Draft";
import ArrowLeft from "./modules/ArrowLeft";
import ArrowRight from "./modules/ArrowRight";
import Unsaved from "./modules/Unsaved";
import History from "./modules/History";
import Decrease from "./modules/Decrease";
import Increase from "./modules/Increase";
import CirclePlus from "./modules/CirclePlus";
import Invites from "./modules/Invites";
import Check from "./modules/Check";
import ThinClose from "./modules/ThinClose";
import Teams from "./modules/Teams";
import MenuMessage from "./modules/MenuMessage";
import Bee from "./modules/Bee";
import Inquiry from "./modules/Inquiry";
import Compensation from "./modules/Compensation";
import CompensationAmount from "./modules/CompensationAmount";
import Concession from "./modules/Concession";
import BeeIconButton from "./modules/BeeIconButton";
import Telephone from "./modules/Telephone";
import Briefcase from "./modules/Briefcase";
import SBHeader from "./modules/SBHeader";
import AgentIcon from "./modules/AgentIcon";
import RightArrow from "./modules/RightArrow";
import QueenBeeIndicator from "./modules/QueenBeeIndicator";
import ListingAgentIndicator from "./modules/ListingAgentIndicator";
import BuyerAgentIndicator from "./modules/BuyerAgentIndicator";
import TeamMemberIndicator from "./modules/TeamMemberIndicator";
import BuyerIndicator from "./modules/BuyerIndicator";
import SellerIndicator from "./modules/SellerIndicator";
import MoreUser from "./modules/MoreUser";
import Manage from "./modules/Manage";
import Archived from "./modules/Archived";
import ForagerBeeTier from "./modules/ForagerBeeTier";
import HouseBeeTier from "./modules/HouseBeeTier";
import QueenBeeTier from "./modules/QueenBeeTier";
import Approver from "./modules/Approver";
import Ellipsis from "./modules/Ellipsis";
import Feedback from "./modules/Feedback";
import Overview from "./modules/Overview";
import Lot from "./modules/Lot";

const Icons = {
    Facebook,
    Twitter,
    Email,
    Close,
    BoxArrowLeft,
    BoxArrowRight,
    Bed,
    Bath,
    Car,
    House,
    ProBadge,
    CircleCheck,
    Waggle,
    Market,
    Search,
    Message,
    Swap,
    Contact,
    Profile,
    Logout,
    WaggleIcon,
    Media,
    Heart,
    Save,
    BuyerBadge,
    BrokerBadge,
    SellerBadge,
    BuyerAgentBadge,
    ListingAgentBadge,
    Calendar,
    Globe3D,
    Matterport,
    Youtube,
    LeftArrow,
    Hive,
    CaretDown,
    CircleClose,
    Clock,
    Star,
    Dashboard,
    Approve,
    Pending,
    Reject,
    Showing,
    Listing,
    Inquiries,
    Home,
    More,
    EmailSmall,
    MiniVerified,
    PhoneSmall,
    Add,
    Minus,
    Eye,
    Coins,
    Marker,
    Grid,
    List,
    SavedSearch,
    ArrowDown,
    Location,
    LocationMark,
    Target,
    Property,
    Lease,
    PropertySize,
    Price,
    Loading,
    General,
    Group,
    ContactRequest,
    Settings,
    HouseAlt,
    Tooltip,
    Edit,
    Instruction,
    LocationAlt,
    Image,
    MortgageConnection,
    BackArrow,
    Share,
    Filter,
    Info,
    ShowingForm,
    Security,
    Pencil,
    Delete,
    Document,
    DOC,
    PDF,
    ODT,
    Draft,
    ArrowLeft,
    ArrowRight,
    Unsaved,
    History,
    Decrease,
    Increase,
    CirclePlus,
    Invites,
    Check,
    ThinClose,
    Teams,
    MenuMessage,
    Bee,
    Inquiry,
    Compensation,
    CompensationAmount,
    Concession,
    BeeIconButton,
    Telephone,
    Briefcase,
    SBHeader,
    AgentIcon,
    RightArrow,
    QueenBeeIndicator,
    ListingAgentIndicator,
    BuyerAgentIndicator,
    TeamMemberIndicator,
    BuyerIndicator,
    SellerIndicator,
    MoreUser,
    Manage,
    Archived,
    ForagerBeeTier,
    HouseBeeTier,
    QueenBeeTier,
    Approver,
    Ellipsis,
    Feedback,
    Overview,
    Lot
}


export default Icons