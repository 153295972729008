import * as React from "react"

const Bed = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={18} viewBox="0 0 30 18" {...props}>
    <path
      data-name="Icon awesome-bed"
      d="M8.25 9A3.75 3.75 0 1 0 4.5 5.25 3.754 3.754 0 0 0 8.25 9Zm16.5-6h-10.5a.75.75 0 0 0-.75.75v6.75H3V.75A.75.75 0 0 0 2.25 0H.75A.75.75 0 0 0 0 .75v16.5a.75.75 0 0 0 .75.75h1.5a.75.75 0 0 0 .75-.75V15h24v2.25a.75.75 0 0 0 .75.75h1.5a.75.75 0 0 0 .75-.75v-9A5.25 5.25 0 0 0 24.75 3Z"
      fill={props.color || "#fac91f"}
    />
  </svg>
)


export default Bed
