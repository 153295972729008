// Components
import CustomButton from 'components/CustomButton';
import CustomTextInput from 'components/CustomTextInput';
import Icons from 'components/Icons';
import Separator from 'components/Separator';
import { useCallback, useState } from 'react';
// Utils
import { inputChange } from 'utils/modules/Handlers';

const AccountForm = ({ onChangeInput, form, setForm, setActive, agentForm }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [viewPassword2, setViewPassword2] = useState(false);
    const [passwordChecklist, setPasswordChecklist] = useState({
        character: false,
        lowercase: false,
        uppercase: false,
        number: false,
    })

    const onPasswordInput = (event) => {
        onChangeInput({ event: event, type: "password1" });
        let checklist = {
            character: false,
            lowercase: false,
            uppercase: false,
            number: false,
        }
        event.target.value.length >= 8 ? checklist.character = true : checklist.character = false;
        event.target.value.match(/.*[0-9]/) ? checklist.number = true : checklist.number = false;
        event.target.value.match(/.*[a-z]/) ? checklist.lowercase = true : checklist.lowercase = false;
        event.target.value.match(/.*[A-Z]/) ? checklist.uppercase = true : checklist.uppercase = false;
        setPasswordChecklist(checklist)
    }

    const onNext = useCallback(async (event) => {
        const { email, password1, password2 } = form

        event.preventDefault();
        let isValid = true

        if (password1.value === '') {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: 'Password cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: '' })
        }

        if (password2.value === '') {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: 'Confirm Password cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: '' })
        }

        if (password1.value !== password2.value) {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: 'Password does not match' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: '' })
        }

        if (email.value && password1.value) {
            isValid = true
        } else {
            isValid = false
        }


        if (isValid) {
            setIsLoading(true);
            setIsLoading(false);
            setActive('user_type')
        }
    }, [form])


    return (
        <form className='account-container' onSubmit={(event) => onNext(event)}>
            <p className='header'>Let’s create your account</p>
            <span className='subheader'>Fill in your details and we'll set you up quickly</span>
            <Separator height={40} />
            <div style={{ display: 'flex', columnGap: 3, alignItems: 'center', marginLeft: 50, cursor: 'pointer' }} onClick={() => setActive('info')}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.70703 2.62623C9.09755 3.01675 9.09755 3.64992 8.70703 4.04044L5.74747 7L12.6666 7C13.2189 7 13.6666 7.44772 13.6666 8C13.6666 8.55229 13.2189 9 12.6666 9H5.74747L8.70703 11.9596C9.09755 12.3501 9.09755 12.9833 8.70703 13.3738C8.3165 13.7643 7.68334 13.7643 7.29281 13.3738L2.62615 8.70711C2.23562 8.31659 2.23562 7.68342 2.62615 7.2929L7.29281 2.62623C7.68334 2.2357 8.3165 2.2357 8.70703 2.62623Z"
                        fill="#5F5F5F"
                    />
                </svg>
                <p className='back'>Back</p>
            </div>
            <Separator height={24} />
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, alignItems: 'center' }}>
                <CustomTextInput
                    inputProps={{
                        type: viewPassword ? 'text' : 'password',
                        placeholder: 'Password',
                        id: 'password1',
                        autoComplete: "new-password",
                        value: form.password1.value,
                        onChange: (event) => onPasswordInput(event),
                    }}
                    rightSlot={<Icons.Eye style={{ cursor: 'pointer' }} color={viewPassword ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword(prev => !prev)} />}
                    size={'large'}
                    style={{ width: 300 }}
                    error={form.password1.error}
                />
                <CustomTextInput
                    inputProps={{
                        type: viewPassword2 ? 'text' : 'password',
                        placeholder: 'Confirm Password',
                        id: 'password2',
                        autoComplete: "new-password",
                        value: form.password2.value,
                        onChange: (event) => onChangeInput({ event, type: "password2" }),
                    }}
                    rightSlot={<Icons.Eye style={{ cursor: 'pointer' }} color={viewPassword2 ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword2(prev => !prev)} />}
                    size={'large'}
                    style={{ width: 300 }}
                    error={form.password1.error}
                />
                <ul className='password-checklist'>
                    <li><p>Passwords must:</p></li>
                    <li className={`${passwordChecklist.character && 'active'}`}>
                        <p>Be a minimum of 8 characters</p>
                        <Icons.CircleCheck
                            color={`${passwordChecklist.character ? '#FAC91F' : '#DFDFDF'}`}
                            width={16}
                            height={16}
                        />
                    </li>
                    <li className={`${passwordChecklist.lowercase && 'active'}`}>
                        <p>Include at least one lowercase letter</p>
                        <Icons.CircleCheck
                            color={`${passwordChecklist.lowercase ? '#FAC91F' : '#DFDFDF'}`}
                            width={16}
                            height={16}
                        />
                    </li>
                    <li className={`${passwordChecklist.uppercase && 'active'}`}>
                        <p>Include at least one uppercase letter</p>
                        <Icons.CircleCheck
                            color={`${passwordChecklist.uppercase ? '#FAC91F' : '#DFDFDF'}`}
                            width={16}
                            height={16}
                        />
                    </li>
                    <li className={`${passwordChecklist.number && 'active'}`}>
                        <p>Include at least one number</p>
                        <Icons.CircleCheck
                            width={16}
                            color={`${passwordChecklist.number ? '#FAC91F' : '#DFDFDF'}`}
                            height={16}
                        />
                    </li>
                </ul>
                <CustomButton
                    text={'Next'}
                    style={{ width: 300 }}
                    size={'large'}
                    loading={isLoading}
                    disabled={!passwordChecklist.character || !passwordChecklist.uppercase || !passwordChecklist.lowercase || !passwordChecklist.number}
                />
            </div>
        </form>
    )
}


export default AccountForm;