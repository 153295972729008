// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/offset-bee.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-placeholder {
  height: 33px;
  width: 33px;
  border-radius: 100px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-placeholder.ba {
  background-color: #9E3645;
}
.user-placeholder.la {
  background-color: var(--primary-color-1);
}
.user-placeholder.buyer {
  background-color: #519872;
}
.user-placeholder.seller {
  background-color: #1F26FA;
}
.user-placeholder > p {
  font-family: "Proxima Nova", sans-serif;
  margin: 0;
  color: white !important;
  font-size: 12px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/UserPlaceholder/userPlaceholder.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,oBAAA;EACA,yDAAA;EACA,kCAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAAE;EAAO,yBAAA;AAGT;AAFE;EAAO,wCAAA;AAKT;AAJE;EAAU,yBAAA;AAOZ;AANE;EAAW,yBAAA;AASb;AARE;EACE,uCAAA;EACA,SAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AAUJ","sourcesContent":[".user-placeholder {\n  height: 33px;\n  width: 33px;\n  border-radius: 100px;\n  background-image: url('../../assets/images/offset-bee.png');\n  background-position: center center;\n  background-size: cover;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  &.ba { background-color: #9E3645; }\n  &.la { background-color: var(--primary-color-1); }\n  &.buyer { background-color: #519872; }\n  &.seller { background-color: #1F26FA; }\n  > p {\n    font-family: 'Proxima Nova', sans-serif;\n    margin: 0;\n    color: white !important;\n    font-size: 12px;\n    font-weight: 700;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
