import Separator from 'components/Separator';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// Packages
import Splide from '@splidejs/splide';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import './beeBrokers.scss';
import { checkImageUrl, getErrorMessage } from 'utils/modules/Handlers';
import NoUser from 'assets/images/no_user.jpg'
import ToastMessage from 'components/ToastMessage';
import Icons from 'components/Icons';

const BeeBrokers = () => {
    const { tier } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [load, setLoad] = useState(false);
    const { featuredBrokers } = useSelector((state) => state.broker)


    useEffect(() => {
        const init = () => {
            new Splide( '#featured-brokers', {
                type: featuredBrokers.length < 3 ? 'slide' : 'loop',
                rewind: true,
                pagination: false,
                arrows: true,
                perPage: featuredBrokers.length < 3 ? featuredBrokers.length : 3,
                focus: 'left',
                fixedWidth: 320,
            }).mount();
            setLoad(true);
        }

        if (!load) {
            init();
        }

    }, [featuredBrokers])

    useEffect(() => {
        const init = async() => {
            await dispatch(actions.broker.fetchFeaturedMortgageBrokers())            
        }
        init();
    }, [dispatch])

    const onSelect = async(broker, showing) => {
        const data = { 
            mortgage_broker_id: broker.id, 
            buyer_tier: tier === 'house' ? 'H' : 'Q'
        }
        try {
            await dispatch(actions.buyer.inviteMortgageBroker(data));
            await dispatch(actions.auth.retrieveUser());
            toast.success('Upgrade request successfully sent!', {
                position: toast.POSITION.TOP_CENTER
            });
            navigate('/bee-tier');
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    return (
        <section className="featured-mortgage-container">
            {/* Back & Next */}
            <Separator height={100} />
            <div className='back-next-container'> 
                <div>
                    <p className='back-button' onClick={() => navigate(`/bee-tier/upgrade/${tier}`)}>Back</p>
                    <h1 className="title">Showing Bee Mortgage Brokers</h1>
                </div>
                <p className='subtitle'>Select a mortgage broker for you.</p>
            </div>
            <div className='contents'>
                <div id="featured-brokers" className="splide agent-slider">
                    <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev">
                            <Icons.BoxArrowLeft color={'#808080'} />
                        </button>
                        <button className="splide__arrow splide__arrow--next">
                            <Icons.BoxArrowRight color={'#808080'} />
                        </button>
                    </div>
                    <div className="splide__track">
                        <ul className="splide__list">
                            { 
                                featuredBrokers.map(broker => (
                                    <li key={broker.id} className="splide__slide">
                                        <div className="selectbox agent" onClick={() => onSelect(broker)}>
                                            <div className="agent-company">
                                                <img src={broker.company_logo} alt="" />
                                            </div>
                                            <div className="agent-profile--image solo">
                                                <div className="agent-profile--avatar" style={{backgroundImage: `url(${checkImageUrl(broker.profile_photo, NoUser)})`}}>
                                                    {broker.verified_buyer_agent && <Icons.MiniVerified width={24} height={24} />}
                                                </div>
                                            </div>
                                            <Separator height={10} />
                                            <div className="agent-profile--info">
                                                <p className="name">{broker.first_name} {broker.last_name}</p>
                                                <Separator height={10} />
                                                <span className="company-name">{broker.company_name}</span>
                                                <Separator height={20} />
                                                <div>
                                                    <div className="email">
                                                        <Icons.EmailSmall />
                                                        <Separator width={10} />
                                                        <span>{broker.email || 'Not Available'}</span>
                                                    </div>
                                                    <Separator height={10} />
                                                    <div className="phone">
                                                        <Icons.PhoneSmall />
                                                        <Separator width={10} />
                                                        <span>{broker.phone_number || 'Not Available'}</span>
                                                    </div>
                                                </div>
                                                <Separator height={20} />
                                            </div>                         
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <Separator height={100} />
        </section>
    )
}


export default BeeBrokers;