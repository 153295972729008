import Separator from "components/Separator";
import BuyersBanner from 'assets/images/BuyersBanner.png'
import BuyerCalendar from 'assets/images/BuyerCalendar.png'
import CustomButton from "components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const BuyersInfoPage = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    return (
        <div className="buyers-details">
            <p className="header">Buyers</p>
            <Separator height={8} />
            <p className="subheader">FINALLY! Prioritizing your needs, creating transparency,<br /> and making you the driver in the real estate process.</p>
            <Separator height={40} />
            <img src={BuyersBanner} alt="Buyers Banner" style={{ width: '100%', height: 'auto'}} />
            <Separator height={40} />
            <span className="content">It's been a long time coming but we think buyers should be in control. We cater to you, giving you all of the tools you need to efficiently search, tour, and connect with professionals who can help create a seamless and stress-free transaction.</span>
            <Separator height={80} />
            <p className="title">At Showing Bee, <br />we prioritize your needs.</p>
            <Separator height={40} />
            <div className="blocks">
                <img src={BuyerCalendar} alt="" />
                <div style={{ flexDirection: 'column' }}>
                    <div style={{ height: '100%', flex: 1 }}>
                        <div className="card" style={{ maxWidth: 308 }}>
                            <p className="title">Transparency.</p>
                            <span className="content">Explore available concessions.</span>
                        </div>
                        <div className="card" style={{ maxWidth: 308 }}>
                            <p className="title">Flexibility.</p>
                            <span className="content">Tour homes on your own either directly with the listing agent or seamlessly invite your buyer's agent.</span>
                        </div>
                    </div>
                    <div className="card" style={{ maxWidth: 632, height: '100%' }}>
                        <p className="title">Convenience.</p>
                        <span className="content">Use our live calendar to schedule showings with the listing agent or add your buyer's agent later.</span>
                    </div>
                </div>
                <div className="card" style={{ flex: 1 }}>
                    <p className="title">Empowerment.</p>
                    <span className="content">Take precedence with listing agents and sellers by establishing yourself as a House Bee buyer or better yet, a Queen Bee buyer! The higher the tier, the more qualified you'll appear. The process is simple and just requires approval by your mortgage broker or one of Showing Bee's Mortgage Partners.</span>
                </div>
            </div>
            <Separator height={80} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <span className="content">We’ve got your back. Make informed decisions effortlessly with Showing Bee.</span>
                {
                    !user.id &&
                    <>
                        <Separator height={24} />
                        <CustomButton text={'Join Showing Bee'} size={'large'} action={() => navigate('/signup')} />
                    </>
                }
            </div>
        </div>
    );
}


export default BuyersInfoPage;