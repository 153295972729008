import * as React from "react"

const Bath = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={26.25} viewBox="0 0 30 26.25" {...props}>
    <path
      data-name="Icon awesome-bath"
      d="M28.594 13.125H4.688V4.688a1.874 1.874 0 0 1 3.458-1 3.982 3.982 0 0 0 .4 4.865.7.7 0 0 0 .029.963l.663.663a.7.7 0 0 0 .994 0L15.8 4.607a.7.7 0 0 0 0-.994l-.663-.663a.7.7 0 0 0-.963-.029 3.976 3.976 0 0 0-3.76-.9 4.685 4.685 0 0 0-8.539 2.667v8.438h-.469A1.406 1.406 0 0 0 0 14.531v.938a1.406 1.406 0 0 0 1.406 1.406h.469v1.875a5.61 5.61 0 0 0 1.875 4.192v1.9a1.406 1.406 0 0 0 1.406 1.408h.938A1.406 1.406 0 0 0 7.5 24.844v-.469h15v.469a1.406 1.406 0 0 0 1.406 1.406h.938a1.406 1.406 0 0 0 1.406-1.406v-1.9a5.61 5.61 0 0 0 1.875-4.194v-1.875h.469A1.406 1.406 0 0 0 30 15.469v-.937a1.406 1.406 0 0 0-1.406-1.407Z"
      fill={props.color || "#fac91f"}
    />
  </svg>
)


export default Bath
