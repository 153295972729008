import * as React from "react"

const CalendarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23.833} height={22} viewBox="0 0 23.833 22" {...props}>
    <g data-name="Tile Calendar Icon" fill={props.color || '#bebebe'}>
      <path
        data-name="Path 330"
        d="M21.542 1.271H19.25v1.413a.466.466 0 0 1-.458.471h-.917a.466.466 0 0 1-.458-.471V1.271h-11v1.413a.466.466 0 0 1-.458.471h-.917a.466.466 0 0 1-.458-.471V1.271H2.292A2.331 2.331 0 0 0 0 3.627v16.018a2.331 2.331 0 0 0 2.292 2.356h19.25a2.331 2.331 0 0 0 2.292-2.356V3.627a2.331 2.331 0 0 0-2.292-2.356ZM22 18.938a1.166 1.166 0 0 1-1.146 1.178H2.979a1.166 1.166 0 0 1-1.146-1.178v-10.6a.466.466 0 0 1 .458-.471h19.25a.466.466 0 0 1 .458.471Z"
      />
      <path
        data-name="Path 331"
        d="M5.972.318A.319.319 0 0 0 5.654 0h-.635a.319.319 0 0 0-.318.318v.953h1.271Z"
      />
      <path
        data-name="Path 332"
        d="M19.133.318A.319.319 0 0 0 18.812 0h-.635a.319.319 0 0 0-.318.318v.953h1.271Z"
      />
    </g>
  </svg>
)

export default CalendarIcon
