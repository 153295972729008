import Separator from "components/Separator";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
import { checkImageUrl } from "utils/modules/Handlers";
import './inquiries.scss'
import house1 from 'assets/images/house1.jpg'
import { Link } from "react-router-dom";
import { Config } from "utils";
import NoUser from 'assets/images/no_user.jpg'
import DeleteInquiryModal from "./modules/DeleteInquiryModal";
import EmailReplyModal from "./modules/EmailReplyModal";
import MessageReplyModal from "./modules/MessageReplyModal";
import Icons from "components/Icons";
import DashboardFiltersContainer from "components/DashboardFiltersContainer";
import ListingStatus from "components/ListingStatus";
import CustomButton from "components/CustomButton";

const Inquiries = () => {
    const dispatch = useDispatch();
    const { inquiries: { data, next, previous, total } } = useSelector((state) => state.buyer)
    const { user } = useSelector((state) => state.auth)
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedData, setSelectedData] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [emailReplyModal, setEmailReplyModal] = useState(false);
    const [chatReplyModal, setChatReplyModal] = useState(false);
    const [type, setType] = useState(null);
    const [inquiryType, setInquiryType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedInquiry, setSelectedInquiry] = useState(null);
    const [inquiriesData, setInquiriesData] = useState(null);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            await dispatch(actions.buyer.fetchBuyerInquiries({ agent: user.agent_profile.id, type: inquiryType })).then(() => {
                setIsLoading(false);
            })
            const totalsData = await dispatch(actions.agent.inquiryDashboardData(inquiryType))
            setInquiriesData(totalsData);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [inquiryType])

    useEffect(() => {
        const init = async () => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total / 5) || 1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    value: `${Config.API_URL}/buyer-inquiry/?limit=5&ordering=-date_added&listing__agent=${user.agent_profile.id}&offset=${offset}`,
                    name: `${offset + 1} - ${i * 20 >= total ? total : i * 20}`,
                })
                offset += 5
            }
            setPages(pageLinks)
        }
        init();
    }, [total])

    const loadPageData = async ({ page, pageNumber }) => {
        await dispatch(actions.buyer.fetchBuyerInquiriesPagination(page));
        setPage(pageNumber);
    }

    const onDelete = () => {
        setType('delete')
        setDeleteModal(prev => !prev)
    }

    const onEmailReply = () => {
        setType('email')
        setEmailReplyModal(prev => !prev)
    }

    const onChatReply = () => {
        setType('chat')
        setChatReplyModal(prev => !prev)
    }


    return (
        <div className="inquiries-container">
            <DeleteInquiryModal modal={deleteModal} setModal={setDeleteModal} selectedData={selectedInquiry} fetchData={fetchData} />
            <EmailReplyModal modal={emailReplyModal} setModal={setEmailReplyModal} selectedData={selectedInquiry} fetchData={fetchData} />
            <MessageReplyModal modal={chatReplyModal} setModal={setChatReplyModal} selectedData={selectedInquiry} fetchData={fetchData} type={type} />
            <DashboardFiltersContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', columnGap: 8 }}>
                    <ul className="inquiry-type">
                        <li className={`${!inquiryType && 'active'}`} onClick={() => setInquiryType('')}>
                            <p>All</p>
                        </li>
                        <li className={`${inquiryType === 'general' && 'active'}`} onClick={() => setInquiryType('general')}>
                            <p>General</p>
                        </li>
                        <li className={`${inquiryType === 'listing' && 'active'}`} onClick={() => setInquiryType('listing')}>
                            <p>Listing</p>
                        </li>
                        <li className={`${inquiryType === 'showing' && 'active'}`} onClick={() => setInquiryType('showing')}>
                            <p>Showing</p>
                        </li>
                    </ul>
                    {
                        data.length > 0 &&
                        <div className="pagination">
                            <p>{pages[page - 1]?.name} of {total}</p>
                            <p onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10.0404 3.95955C10.431 4.35007 10.431 4.98324 10.0404 5.37376L7.41421 7.99999L10.0404 10.6262C10.431 11.0167 10.431 11.6499 10.0404 12.0404C9.64992 12.431 9.01675 12.431 8.62623 12.0404L5.29289 8.7071C4.90237 8.31657 4.90237 7.68341 5.29289 7.29288L8.62623 3.95955C9.01675 3.56903 9.64992 3.56903 10.0404 3.95955Z"
                                        fill={previous ? "#5F5F5F" : "#C9C9C9"}
                                    />
                                </svg>
                            </p>
                            <p onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.95964 3.95955C6.35017 3.56903 6.98333 3.56903 7.37385 3.95955L10.7072 7.29288C11.0977 7.68341 11.0977 8.31657 10.7072 8.7071L7.37385 12.0404C6.98333 12.431 6.35017 12.431 5.95964 12.0404C5.56912 11.6499 5.56912 11.0167 5.95964 10.6262L8.58587 7.99999L5.95964 5.37376C5.56912 4.98324 5.56912 4.35007 5.95964 3.95955Z"
                                        fill={next ? "#5F5F5F" : "#C9C9C9"}
                                    />
                                </svg>
                            </p>
                        </div>
                    }
                </div>

            </DashboardFiltersContainer>
            <div className="contents">
                <div className="table-container">
                    {
                        isLoading &&
                        <div className="loader">
                            <Icons.Loading background={'none'} height={100} width={100} />
                        </div>
                    }
                    <table cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th width="47px"></th>
                                <th colSpan={2} style={{ paddingLeft: 0 }}><div>{inquiriesData?.pending} Pending Inquiries</div></th>
                                <th><div style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{total} Total Inquries</div></th>
                            </tr>
                        </thead>
                        <tbody id="inquiry-table">
                            {
                                data.length > 0 ?
                                    data.map((inquiry, index) => {
                                        return (
                                            <tr className={`body ${inquiry.is_resolved && 'resolved'}`} key={index} onClick={() => setSelectedInquiry(inquiry)}>
                                                <td></td>
                                                <td style={{ width: 150, paddingRight: 16 }}>
                                                    {
                                                        inquiry.inquirer
                                                            ? <p style={{ fontSize: 12, fontWeight: 600, whiteSpace: 'nowrap' }}>{`${inquiry.inquirer?.first_name} ${inquiry.inquirer?.last_name}`}</p>
                                                            : <p style={{ fontSize: 12, fontWeight: 600, whiteSpace: 'nowrap' }}>{`${inquiry.first_name} ${inquiry.last_name}`}</p>
                                                    }
                                                </td>
                                                <td width="589px">
                                                    <div className="message">
                                                        {/* <p><span style={{ textTransform: 'capitalize' }}>{inquiry.inquiry_type}</span> {`Inquiry | ${inquiry.message}`}</p> */}
                                                        <p>Showing Inquiry |  Someone’s looking for a showing request at your disabled listing on 2024/07/25 1:30 PM Showing Inquiry |  Someone’s looking for a showing request at your disabled listing on 2024/07/25 1:30 PM</p>
                                                    </div>
                                                </td>
                                                <td width="56px" align="right" style={{ paddingRight: 16 }}>
                                                    <p style={{ fontSize: 10, fontWeight: 600, margin: 0 }}>
                                                        {moment(inquiry.date_added, 'YYYY-MM-DDTHH:mm:ss').format('MMM DD')}
                                                    </p>
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <tr>
                                        <td colSpan="8">
                                            <div className="empty-table">
                                                {
                                                    !isLoading &&
                                                    'No Available Inquiries'
                                                }
                                            </div>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className={`preview-inquiry ${selectedInquiry && 'show'}`}>
                    <div className="mobile-close" onClick={() => setSelectedInquiry(null)}><Icons.CircleClose color="#F04545" height={14} width={14} /></div>
                    {

                        selectedInquiry
                            ?
                            <div className="selected-inquiry">
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <p className="title">{selectedInquiry.inquiry_type} Inquiry</p>
                                            <Separator height={8} />
                                            <div style={{ display: 'flex' }}>
                                                <span className="subtitle">{moment(new Date(selectedInquiry.date_added)).format('MMMM DD YYYY')}</span>
                                                <Separator width={10} />
                                                <span className="subtitle">{moment(new Date(selectedInquiry.date_added)).format('h:mm A')}</span>
                                            </div>
                                        </div>
                                        <div className="actions">
                                            <Icons.CircleDelete onClick={onDelete} />
                                        </div>
                                    </div>
                                    <Separator height={24} />
                                    <p className={`inquiry-status ${selectedInquiry.is_resolved ? 'resolved' : selectedInquiry.date_notified ? 'notified' : 'pending'}`}>
                                        {selectedInquiry.is_resolved ? 'Resolved' : selectedInquiry.date_notified ? 'Notified' : 'Pending'}
                                        {
                                            (selectedInquiry.date_notified && !selectedInquiry.is_resolved) &&
                                            <span className="tooltip" data-text="The inquirer has been notified regarding the inquiry because it's been 12 hours since it was made. Please respond to the inquiry immediately." >
                                                <Icons.Tooltip height={12} width={12} />
                                            </span>
                                        }
                                    </p>
                                    <Separator height={24} />
                                    <div className="listing-card">
                                        <img src={selectedInquiry.listing.primary_photo_url} alt="" />
                                        <div>
                                            <p className="name">Listing #{selectedInquiry.listing.mls_number || selectedInquiry.listing.id}</p>
                                            <p className="address">{`${selectedInquiry.listing.address}, ${selectedInquiry.listing.city} ${selectedInquiry.listing.state} ${selectedInquiry.listing.zip_code}`}</p>
                                        </div>
                                    </div>
                                    <Separator height={24} />
                                    <p style={{ margin: 0, fontSize: 12, fontWeight: 500, color: '#949494' }}>Inquirer</p>
                                    <Separator height={6} />
                                    <div className="requestor">
                                        <img src={checkImageUrl(selectedInquiry.inquirer?.profile_photo, NoUser)} alt="" />
                                        {
                                            selectedInquiry.inquirer
                                                ? <div>
                                                    <p>{`${selectedInquiry.inquirer?.first_name} ${selectedInquiry.inquirer?.last_name}`}</p>
                                                    <span>{selectedInquiry.email}</span>
                                                </div>
                                                : <div>
                                                    <p>{`${selectedInquiry.first_name} ${selectedInquiry.last_name}`}</p>
                                                    <span>{selectedInquiry.email}</span>
                                                </div>
                                        }
                                    </div>
                                    <Separator height={34} />
                                    <p style={{ margin: 0, fontSize: 12, fontWeight: 500, color: '#949494' }}>Message</p>
                                    <Separator height={8} />
                                    <p style={{ color: '#5F5F5F', margin: 0, lineHeight: '24px', fontWeight: 400, fontSize: 12 }}>{selectedInquiry.message}</p>
                                </div>
                                <div style={{ display: 'flex', rowGap: 8, width: '100%', flexDirection: 'column' }}>
                                    {
                                        selectedInquiry.inquirer &&
                                        <CustomButton text={'Reply by Message'} type="success" action={selectedInquiry} />
                                    }
                                    <CustomButton text={'Reply by Email'} type="success" action={onEmailReply} />
                                </div>
                            </div>
                            :
                            <>
                                <p className="title">Inquiry</p>
                                <Separator height={8} />
                                <span className="subtitle">Click on an inquiry to view here</span>
                            </>
                    }
                </div>
            </div>

        </div>
    )
}


export default Inquiries;