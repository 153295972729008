import * as React from "react"

const Message = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25.455}
        height={20}
        viewBox="0 0 25.455 20"
        {...props}
    >
        <path
            fill={props.color || '#808080'}
            d="M25.28,5.625H4.674A2.431,2.431,0,0,0,2.25,8.049V23.2a2.431,2.431,0,0,0,2.424,2.424H25.28A2.431,2.431,0,0,0,27.7,23.2V8.049A2.431,2.431,0,0,0,25.28,5.625Zm-.3,5.152-10,6.667-10-6.667V8.352l10,6.667,10-6.667Z"
            transform="translate(-2.25 -5.625)"
        />
    </svg>
)


export default Message
