// Packages
import Icons from 'components/Icons';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Seprator from 'components/Separator'
import './getAccessModal.scss'
import { useCallback, useState } from 'react';
import { getErrorMessage, inputChange, validateEmail } from 'utils/modules/Handlers';
import actions from 'store/actions';
import ToastMessage from 'components/ToastMessage';


const GetAccessModal = ({ modal, setModal }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        full_name: {
            value: '',
            error: '',
        },
        company: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async(event) => {
        event.preventDefault();

        const { email, full_name, company } = form
        let isValid = true

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid'})
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
        }

        if (!full_name.value) {
            inputChange({ setForm: setForm, key: 'full_name', value: 'error', data: 'Name cannot be blank'})
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'full_name', value: 'error', data: ''})
        }

        if (!company.value) {
            inputChange({ setForm: setForm, key: 'company', value: 'error', data: 'Company cannot be blank'})
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'company', value: 'error', data: ''})
        }


        if (isValid) {
            setLoading(true)
            try {
                await dispatch(actions.auth.investorRequest({ full_name: full_name.value, email: email.value, company: company.value })).then(() => {
                    toast.success('Request has been sent, please wait for the access code from our team.', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setLoading(false)
                    setModal(prev => !prev);
                })
            } catch (error) {
                console.log(error)
                // toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                //     position: toast.POSITION.TOP_CENTER
                // });
                setLoading(false)
            }
        }
 
    }, [form])

    return (
        <div className={`get-access-modal ${modal ? 'open' : 'close'}`}>
            <div className='modal-container'>
                <div className='modal-title'>
                    Get Exclusive Access
                    <div className='close' onClick={() => setModal(prev => !prev)}>
                        <Icons.Close height={13} width={13} />
                    </div>
                </div>
                <Seprator height={40} />
                <div className='content'>
                    <div>
                        <div className='form-input'>
                            <label>Full Name</label>
                            <input 
                                id='full_name' 
                                placeholder='Enter Full Name' 
                                type='text' 
                                className='small' 
                                value={form.full_name.value}
                                onChange={(event) => onChangeInput({event, type: "full_name"})} 
                            />
                            <span className='error-message' style={{ width: 240 }}>{form.full_name.error}</span>
                        </div>
                        <Seprator height={20} />
                        <div className='form-input'>
                            <label>Email</label>
                            <input 
                                id='email' 
                                placeholder='Enter Email' 
                                type='text' 
                                className='small' 
                                value={form.email.value}
                                onChange={(event) => onChangeInput({event, type: "email"})} 
                            />
                            <span className='error-message' style={{ width: 240 }}>{form.email.error}</span>
                        </div>
                        <Seprator height={20} />
                        <div className='form-input'>
                            <label>Company</label>
                            <input 
                                id='company' 
                                placeholder='Enter Company Name' 
                                type='text' 
                                className='small' 
                                value={form.company.value}
                                onChange={(event) => onChangeInput({event, type: "company"})} 
                            />
                            <span className='error-message' style={{ width: 240 }}>{form.company.error}</span>
                        </div>
                        <Seprator height={40} />
                        <button className='button primary small no-glow' onClick={onSubmit}>Get Access</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetAccessModal;