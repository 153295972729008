import Icons from 'components/Icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './defaultBrokerModal.scss';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { checkImageUrl, getErrorMessage } from 'utils/modules/Handlers';
import Seprator from 'components/Separator';
import API from 'API';
import NoUser from 'assets/images/no_user.jpg'

const DefaultBrokerModal = ({ modal, setModal, profile, refetch }) => {
    const dispatch = useDispatch();
    const [brokers, setBrokers] = useState([]);


    const fetchData = async() => {
        const brokerData = await API.buyer.fetchBuyerMortgageConnection({ offset: 0, limit: 6 });
        setBrokers(brokerData.data.results)
    }

    
    useEffect(() => {
        const init = async() => {
            await fetchData();
        }
        init();
    }, [])


    const onSubmit = useCallback(async(item) => {
        try {
            const data = { id: item.id }
            await dispatch(actions.buyer.setDefaultBroker(data)).then(() => {
                refetch();
                setModal(prev => !prev);
                toast.success('New default Mortgage Broker has been set', {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } catch (error) {
            toast.error(<ToastMessage messages={'Something went wrong.'} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [profile])

    return (
        <div className={`default-broker-modal ${modal && 'visible'}`}>
            <div className='form-section'>
                <p className='section-title'>
                    Change Default Mortgage Broker
                </p>
                <Seprator height={25} />
                <div className='items'>
                    {
                        brokers.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className='details'>
                                        <div>
                                            <img src={checkImageUrl(item.mortgage_broker.profile_photo, NoUser)} alt="" />
                                            <div className='info'>
                                                <p className='name'>{item.mortgage_broker.first_name} {item.mortgage_broker.last_name}</p>
                                                <Seprator height={5} />
                                                <p className='email'>{item.mortgage_broker.email}</p>
                                            </div>
                                        </div>
                                        <div>
                                        {
                                            profile.default_mortgage_broker?.id !== item.mortgage_broker.id 
                                            ? <p className='set' onClick={() => onSubmit(item.mortgage_broker)}>Set Default</p>
                                            : <p className='current'>Default</p>
                                        }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <Seprator height={30} />
                <button className='button cancel small no-glow' onClick={() => setModal(prev => !prev)}>Cancel</button>
            </div>
        </div>
    )
}


export default DefaultBrokerModal;