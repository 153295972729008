import API from 'API'
import axios from 'axios';
import listingSlice from '../reducers/listing'

const { 
  fetchListingsSuccess,
  onLoadMoreFetchListingsSuccess,
  fetchSavedListingsSuccess,
  retrieveListingSuccess,
  retrieveForAGentsListingSuccess,
  retrieveForAGentsListingError,
  fetchListingEventsSuccess,
  fetchListingCalendarEventsSuccess,
  fetchPublicListingsInit,
  fetchPublicListingsSuccess,
  fetchPublicListingsFailed,
  onLoadMoreFetchPublicListingsSuccess,
  retrievePublicListingInit,
  retrievePublicListingSuccess,
  retrievePublicListingFailed,
  fetchSavedSearchesSuccess,
} = listingSlice.actions

const fetchListings = () => async dispatch => {
    const response = await API.listing.fetchListings({ offset: 0 });
    const payload = response.data;
    dispatch(fetchListingsSuccess(payload));
    return payload
  }

const fetchListingsPagination = (page) => async dispatch => {
  const instance = axios.create({
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const token = localStorage.getItem('token')
  instance.defaults.headers.common["Authorization"] = `Token ${token}`;
  const response = await instance.get(page);
  const payload = response.data;
  dispatch(fetchListingsSuccess(payload));
}

const onLoadMoreFetchListings = ({ offset }) => async dispatch => {
    const response = await API.listing.fetchListings({ offset });
    const payload = response.data;
    payload.is_end = false;
    payload.offset = offset
    if (payload.results.length < 1) {
      payload.is_end = true;
    }
    dispatch(onLoadMoreFetchListingsSuccess(payload));
}

const retrieveListing = (id) => async dispatch => {
  const response = await API.listing.retrieveListing(id);
  const payload = response.data;
  dispatch(retrieveListingSuccess(payload));
  return payload;
}

const updateListing = ({ id, data }) => async dispatch => {
  const response = await API.listing.updateListing({ id, data });
  const payload = response.data;
  // dispatch(updateListingSuccess(payload));
  return payload;
}

const fetchSavedListings = () => async dispatch => {
  const response = await API.listing.fetchSavedListings();
  const payload = response.data;
  dispatch(fetchSavedListingsSuccess(payload));
  return payload
}

const postSavedListing = (data) => async dispatch => {
  await API.listing.postSavedListing(data);
}

const deleteSavedListing = (id) => async dispatch => {
  await API.listing.deleteSavedListing(id);
}

const retrieveForAGentsListing = (id) => async dispatch => {
  try {
    const response = await API.listing.retrieveForAGentsListing(id);
    const payload = response.data;
    dispatch(retrieveForAGentsListingSuccess(payload));
  } catch (error) {
    dispatch(retrieveForAGentsListingError());
  }
}

const fetchListingEvents = (listing) => async dispatch => {
  const response = await API.listing.fetchListingEvents(listing);
  const payload = response.data;
  dispatch(fetchListingEventsSuccess(payload));
  return payload
}

const fetchListingEventsStartEnd = ({ listing, start, end }) => async dispatch => {
  const response = await API.listing.fetchListingEventsStartEnd({ listing, start, end });
  return response.data;
}

const fetchListingCalendarEvents = (listing) => async dispatch => {
  const response = await API.listing.fetchListingCalendarEvents(listing);
  const payload = response.data;
  dispatch(fetchListingCalendarEventsSuccess(payload));
}

const addListingCalendarEvent = ({listing, data}) => async dispatch => {
  const response = await API.listing.addListingCalendarEvent({listing, data});
  const payload = response.data;
  return payload
}

const updateListingCalendarEvent = ({listing, id, data}) => async dispatch => {
  const response = await API.listing.updateListingCalendarEvent({listing, id, data});
  const payload = response.data;
  return payload
}

const updateListingCalendarEventOccurance = ({ url, data }) => async dispatch => {
  const instance = axios.create({
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const token = localStorage.getItem('token')
  instance.defaults.headers.common["Authorization"] = `Token ${token}`;
  const response = await instance.put(url, data);
  const payload = response.data;
  dispatch(fetchListingsSuccess(payload));
}

const deleteListingCalendarEvent = ({listing, id}) => async dispatch => {
  const response = await API.listing.deleteListingCalendarEvent({listing, id});
  const payload = response.data;
  return payload
}

const fetchPublicListings = () => async dispatch => {
  try {
    dispatch(fetchPublicListingsInit());
    localStorage.removeItem('filterString')
    const response = await API.listing.fetchPublicListings({ offset: 0 });
    const payload = response.data;
    dispatch(fetchPublicListingsSuccess(payload));
  } catch (error) {
    dispatch(fetchPublicListingsFailed());
  }
}

const onLoadMoreFetchPublicListings = ({ offset }) => async dispatch => {
  const response = await API.listing.fetchPublicListings({ offset });
  const payload = response.data;
  payload.is_end = false;
  payload.offset = offset
  if (payload.results.length < 1) {
    payload.is_end = true;
  }
  dispatch(onLoadMoreFetchPublicListingsSuccess(payload));
}

const searchPublicListings = ({ filters, offset=0, sort }) => async dispatch => {
  try {
    dispatch(fetchPublicListingsInit());
    // if (filters && localStorage.getItem('filterString') !== filters) {
    //   localStorage.setItem('filterString', filters);
    // } else (
    //   localStorage.removeItem('filterString')
    // )
    const response = await API.listing.searchPublicListings({ offset: offset, filters, sort });
    const payload = response.data;
    dispatch(fetchPublicListingsSuccess(payload));
  } catch (error) {
    dispatch(fetchPublicListingsFailed());
  }
}

const searchListings = ({ filters }) => async dispatch => {
    const response = await API.listing.searchListings({ offset: 0, filters })
    const payload = response.data;
    dispatch(fetchListingsSuccess(payload));
}

const nearbyListings = ({ filters }) => async dispatch => {
  const response = await API.listing.searchPublicListings({ offset: 0, filters })
  const payload = response.data;
  return payload
}

const onLoadMoreSearchPublicListings = ({ offset, filters }) => async dispatch => {
  const response = await API.listing.searchPublicListings({ offset, filters });
  const payload = response.data;
  payload.is_end = false;
  payload.offset = offset
  if (payload.results.length < 1) {
    payload.is_end = true;
  }
  dispatch(onLoadMoreFetchPublicListingsSuccess(payload));
}

const retrievePublicListing = ({ mls, id }) => async dispatch => {
  try {
    dispatch(retrievePublicListingInit());
    const response = await API.listing.retrievePublicListing({ mls, id });
    const payload = response.data;
    dispatch(retrievePublicListingSuccess(payload));
  } catch (error) {
    dispatch(retrievePublicListingFailed());
  }
}

const fetchSavedSearches = (id) => async dispatch => {
  const response = await API.listing.fetchSavedSearches({ offset: 0 });
  const payload = response.data;
  const results = payload.results.filter(item => item.user === id);
  payload.results = results
  dispatch(fetchSavedSearchesSuccess(payload));
}

const postSaveSearch = (data) => async dispatch => {
  const response = await API.listing.postSaveSearch(data);
  const payload = response.data;
  return payload
}

const deleteSaveSearch = (id) => async dispatch => {
  const response = await API.listing.deleteSaveSearch(id);
  const payload = response.data;
  return payload
}

const addListingSellers = ({ id, data }) => async dispatch => {
  const response = await API.listing.addListingSellers({id, data});
  const payload = response.data;
  return payload
}

const editListingSeller = ({ id, data }) => async dispatch => {
  const response = await API.listing.editListingSeller({id, data});
  const payload = response.data;
  return payload
}

const deleteListingSeller = ({ id, data }) => async dispatch => {
  const response = await API.listing.deleteListingSeller({id, data});
  const payload = response.data;
  return payload
}

const enableShowingService = (id) => async dispatch => {
  const response = await API.listing.enableShowingService(id);
  const payload = response.data;
  return payload
}

const disableShowingService = (id) => async dispatch => {
  const response = await API.listing.disableShowingService(id);
  const payload = response.data;
  return payload
}

const retrieveGeolocation = (address) => async dispatch => {
  const instance = axios.create({
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const params = `http://api.positionstack.com/v1/forward?access_key=d9a17661af415afe1a59fee90624aa8f&query=${address}, USA`
  const response = await instance.get(params);
  const payload = response.data;
  return { payload: payload.data[0], status: response.status }
}

const postListing = (data) => async dispatch => {
  const response = await API.listing.postListing(data);
  const payload = response.data;
  return payload
}

const fetchListingDataCalendar = (url) => async dispatch => {
  const instance = axios.create({
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const response = await instance.get(url);
  const payload = response.data;
  return payload
}

const fetchListingImages = (id) => async dispatch => {
  const response = await API.listing.fetchListingImages(id);
  const payload = response.data;
  return payload
}

const setListingDefaultImage = (id) => async dispatch => {
  const response = await API.listing.setListingDefaultImage(id);
  const payload = response.data;
  return payload
}

const deleteListingImage = (id) => async dispatch => {
  const response = await API.listing.deleteListingImage(id);
  const payload = response.data;
  return payload
}

const addListingImage = (data) => async dispatch => {
  const response = await API.listing.addListingImage(data);
  const payload = response.data;
  return payload
}

const fetchListingDocuments = ({id, is_public}) => async dispatch => {
  const response = await API.listing.fetchListingDocuments({id, is_public});
  const payload = response.data;
  return payload
}

const deleteListingDocument = (id) => async dispatch => {
  const response = await API.listing.deleteListingDocument(id);
  const payload = response.data;
  return payload
}

const addListingDocument = (data) => async dispatch => {
  const response = await API.listing.addListingDocument(data);
  const payload = response.data;
  return payload
}

const setDocumentPublic = (id) => async dispatch => {
  const response = await API.listing.setDocumentPublic(id);
  const payload = response.data;
  return payload
}

const fetchListingPrice = (id) => async dispatch => {
  const response = await API.listing.fetchListingPrice(id);
  const payload = response.data;
  return payload
}

const addListingPrice = (data) => async dispatch => {
  const response = await API.listing.addListingPrice(data);
  const payload = response.data;
  return payload
}

const setListingDefaultPrice = (id) => async dispatch => {
  const response = await API.listing.setListingDefaultPrice(id);
  const payload = response.data;
  return payload
}

const removeListingDefaultPrice = (id) => async dispatch => {
  const response = await API.listing.removeListingDefaultPrice(id);
  const payload = response.data;
  return payload
}

const addListingAgentMember = ({ id, data }) => async dispatch => {
  const response = await API.listing.addListingAgentMember({id, data});
  const payload = response.data;
  return payload
}

const deleteListingAgentMember = ({ id, data }) => async dispatch => {
  const response = await API.listing.deleteListingAgentMember({id, data});
  const payload = response.data;
  return payload
}

const addDefaultListingSeller = ({ id, data }) => async dispatch => {
  const response = await API.listing.addDefaultListingSeller({id, data});
  const payload = response.data;
  return payload
}

const uploadConcessionDocument = ({ id, data }) => async dispatch => {
  const response = await API.listing.uploadConcessionDocument({id, data});
  const payload = response.data;
  return payload
}

const uploadCompensationDocument = ({ id, data }) => async dispatch => {
  const response = await API.listing.uploadCompensationDocument({id, data});
  const payload = response.data;
  return payload
}

const createCompensationConcessionHistory = ({ id, data }) => async dispatch => {
  const response = await API.listing.createCompensationConcessionHistory({id, data});
  const payload = response.data;
  return payload
}

const fetchCompensationConcessionHistory = ({ type, listing }) => async dispatch => {
  const response = await API.listing.fetchCompensationConcessionHistory({type, listing});
  const payload = response.data;
  return payload
}

const listingActions = {
    fetchListings,
    fetchListingsPagination,
    onLoadMoreFetchListings,
    retrieveListing,
    updateListing,
    fetchSavedListings,
    postSavedListing,
    deleteSavedListing,
    retrieveForAGentsListing,
    fetchListingEvents,
    fetchListingEventsStartEnd,
    fetchListingCalendarEvents,
    addListingCalendarEvent,
    updateListingCalendarEvent,
    updateListingCalendarEventOccurance,
    deleteListingCalendarEvent,
    fetchPublicListings,
    onLoadMoreFetchPublicListings,
    retrievePublicListing,
    searchPublicListings,
    searchListings,
    onLoadMoreSearchPublicListings,
    fetchSavedSearches,
    postSaveSearch,
    deleteSaveSearch,
    addListingSellers,
    editListingSeller,
    deleteListingSeller,
    enableShowingService,
    disableShowingService,
    retrieveGeolocation,
    postListing,
    fetchListingDataCalendar,
    fetchListingImages,
    setListingDefaultImage,
    deleteListingImage,
    addListingImage,
    fetchListingDocuments,
    deleteListingDocument,
    addListingDocument,
    setDocumentPublic,
    fetchListingPrice,
    addListingPrice,
    setListingDefaultPrice,
    removeListingDefaultPrice,
    addListingAgentMember,
    deleteListingAgentMember,
    addDefaultListingSeller,
    uploadConcessionDocument,
    uploadCompensationDocument,
    createCompensationConcessionHistory,
    fetchCompensationConcessionHistory,
    nearbyListings,
}


export default listingActions;