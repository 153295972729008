import * as React from "react"

const Dashboard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    {...props}
  >
    <g transform="translate(-73 -183)">
      <rect
        fill={props.color || 'grey'}
        width={25}
        height={25}
        rx={5}
        transform="translate(73 183)"
      />
      <rect
        fill="#fff"
        width={6}
        height={16}
        rx={2}
        transform="translate(77.5 187.5)"
      />
      <rect
        fill="#fff"
        width={7}
        height={7}
        rx={2}
        transform="translate(86.5 187.5)"
      />
      <rect
        fill="#fff"
        width={7}
        height={7}
        rx={2}
        transform="translate(86.5 196.5)"
      />
    </g>
  </svg>
)


export default Dashboard;
