import { useCallback, useEffect, useState } from 'react'
// Redux
import { useNavigate } from 'react-router-dom';
// Utils
import { inputChange } from 'utils/modules/Handlers';
// Packages
import Splide from '@splidejs/splide';
// Modules
import SelectUserType from './modules/SelectUserType';
import InformationForm from './modules/InformationForm';
import AccountForm from './modules/AccountForm';
import FormStepper from './modules/FormStepper';
import VerifyEmail from './modules/VerifyEmail';
import InviteBuyerAgentForm from './modules/InviteBuyerAgentForm';
// Components
import Section from 'components/Section'
import Separator from 'components/Separator';
// Assets
import './signup.scss'
import ShowingBeeLogoBlack from 'assets/images/ShowingBeeLogoBlack.png'
import house1 from 'assets/images/house1.jpg'
import house2 from 'assets/images/house2.jpg'
import house3 from 'assets/images/house3.jpg'
import Testimonial from './modules/Testimonal';
import Icons from 'components/Icons';

const Signup  = () => {
    const navigate = useNavigate()
    const [active, setActive] = useState('user_type')
    const [inviteSkip, setInviteSkip] = useState(false)
    const [load, setLoad] = useState(false);
    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
        password: {
            value: '',
            error: '',
        },
        phone_number: {
            value: '',
            error: '',
        },
        user_type: {
            value: '',
        },
    });

    const [agentForm, setAgentForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
    });

    const testimonials = [
        {
            image: house1,
            title: 'Made us reach our dream more faster then ever.',
            body: 'Getting your dream home has never been so easy. Now, my family enjoys the life we always dreamt of. Thanks Showing Bee!',
            ratings: 3,
            reviewer: 'Tessa Delmar'
        },
        {
            image: house2,
            title: 'Made us reach our dream more faster then ever.',
            body: 'Getting your dream home has never been so easy. Now, my family enjoys the life we always dreamt of. Thanks Showing Bee!',
            ratings: 4,
            reviewer: 'John Doe'
        },
        {
            image: house3,
            title: 'Made us reach our dream more faster then ever.',
            body: 'Getting your dream home has never been so easy. Now, my family enjoys the life we always dreamt of. Thanks Showing Bee!',
            ratings: 2,
            reviewer: 'Eric Doe'
        }
    ]
    
    useEffect(() => {
        const init = async() => {
            var main = await new Splide( '#testimonial-slider', {
                type    : 'fade',
                autoplay: true,
                rewind: true,
                pagination: false,
                focus: 'center',
                drag: false,
            });
            setLoad(true);
            main.mount();
        }
        if (!load) {
            init();
        }

    }, [])

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onChangeAgentInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setAgentForm, key: type, value: 'value', data: data })
    }


    const onGoBack = useCallback(() => {
        if (active === 'information') {
            setActive('user_type')
        } else if (active === 'invite_agent') {
            setActive('information')
        } else if (active === 'account') {
            if (form.user_type.value === 'Buyer / Seller') {
                setInviteSkip(false)
                setActive('invite_agent')
            } else {
                setActive('information')
            }
        } else if (active === 'finish') {
            setActive('account')
        }
    }, [active, form])

    return (
        <Section style={{ display: 'flex' }}>
            <div className='signup-container'>
                <div className='signup-form'>
                <Separator  height={20} />
                    <img 
                        src={ShowingBeeLogoBlack} 
                        alt="logo" 
                        className='logo'
                        onClick={() => navigate('/')}
                    />
                    <Separator height={60} />
                    <h1 className='header'>Come and join the hive!</h1>
                    <Separator height={10} />
                    <h2 className='subheader'>Fill in your details and we'll set you up in a jiffy.</h2>
                    <Separator height={30} />
                    {
                        active !== 'user_type' &&
                        <div className='user-type-header'>
                            <p className='user-type'>{form.user_type.value}</p>
                            <p className='go-back' onClick={onGoBack}>{'< Go Back'}</p>
                        </div>
                    }
                    { active !== 'user_type' && <FormStepper active={active} form={form} skip={inviteSkip} /> }
                    { 
                        active === 'user_type' && 
                        <SelectUserType  form={form} setForm={setForm} setActive={setActive} /> 
                    }
                    {
                        active === 'information' && 
                        <InformationForm form={form} setForm={setForm} onChangeInput={onChangeInput} setActive={setActive} /> 
                    }
                    { 
                        active === 'invite_agent' && 
                        <InviteBuyerAgentForm 
                            form={agentForm}
                            onChangeInput={onChangeAgentInput}
                            setActive={setActive}
                            setSkip={setInviteSkip}
                        /> 
                    }
                    { 
                        active === 'account' && 
                        <AccountForm 
                            form={form}
                            agentForm={agentForm}
                            setForm={setForm}
                            onChangeInput={onChangeInput}
                            setActive={setActive}
                        /> 
                    }
                    { active === 'finish' && <VerifyEmail form={form} /> }
                    <Separator height={30} />
                    <p className='sign-up'>Already have an account? <span onClick={() => navigate('/login')}>Sign in here</span></p>
                    <Separator  height={20} />
                </div>
                <div className='desktop-only'>
                    <div className="testimonial-container">
                        <div className="testimonial-slider-container">
                            <>
                                <div id="testimonial-slider" className="splide testimonial-slider">
                                    <div className="splide__track">
                                        <div className='arrows'>
                                            <div className="splide__arrows">
                                                <button className="splide__arrow splide__arrow--prev">
                                                    <Icons.BoxArrowLeft width={35} height={35} />
                                                </button>
                                                <button className="splide__arrow splide__arrow--next">
                                                    <Icons.BoxArrowRight width={35} height={35} />
                                                </button>
                                            </div>
                                        </div>
                                        <ul className="splide__list">
                                            {
                                                testimonials.map((testimonial, index) => {
                                                    return (
                                                        <li className="splide__slide" key={index}>
                                                            <Testimonial data={testimonial} />
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}


export default Signup;