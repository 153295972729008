import { useCallback, useEffect, useMemo, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
// Packages
import debouce from 'lodash.debounce'
import moment from 'moment';
//  Modules
import ListingBlock from "./modules/ListingBlock";
import StatusBlock from "./modules/StatusBlock";
import ActionBlock from "./modules/ActionBlock";
import ConnectedUserModal from "./modules/ConnectedUserModal";
import UsersBlock from "./modules/UsersBlock";
// Components
import Icons from "components/Icons";
import Section from "components/Section";
import Separator from "components/Separator";
// Assets
import { Config } from "utils";
import './manageShowings.scss';
import TableHeader from "components/TableHeader";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardFiltersContainer from "components/DashboardFiltersContainer";
import ShowingStatus from "components/ShowingStatus";
import CustomButton from "components/CustomButton";
import CustomTextInput from "components/CustomTextInput";
import { type } from "@testing-library/user-event/dist/type";

const ManageShowings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth)
    const { data: showings, previous, next, total } = useSelector((state) => state.showing)
    const [statusMenu, setStatusMenu] = useState(false);
    const [statusFilter, setStatusFilter] = useState('all');
    const [duration, setDuration] = useState('current');
    const [typeMenu, setTypeMenu] = useState(false);
    const [typeFilter, setTypeFilter] = useState('all');
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedPage, setSelectedPage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({
        status: '',
        agent_type: '',
        date: '',
        start: '',
        finish: '',
    })

    // useEffect(() => {
    //     const init = async() => {
    //         setIsLoading(true);
    //         try {
    //             await dispatch(actions.showing.fetchShowingsAll()).then(() => {
    //                 setIsLoading(false)
    //             })
    //         } catch (error) {
    //             setIsLoading(false)
    //         }
    //     }
    //     init();
    // }, [])

    useEffect(() => {
        const init = async () => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total / 20) || 1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    value: `${Config.API_URL}/showing/search/?limit=20&offset=${offset}&duration=${duration}&exclude_draft=true&${filters}`,
                    name: `${offset + 1} - ${i * 20 >= total ? total : i * 20}`,
                })
                offset = i * 20
            }
            setPages(pageLinks)
        }
        init();
    }, [duration, filters, total])

    const loadPageData = async ({ page, pageNumber }) => {
        await dispatch(actions.showing.fetchShowingsPagination(page));
        setSelectedPage(page);
        setPage(pageNumber);
    }

    const formatTime = (data) => {
        const startDate = moment(new Date(data.start)).format('dddd');
        return startDate
    }

    const formatDay = (data) => {
        const startDate = moment(new Date(data.start)).format('MMMM DD, YYYY');
        return startDate
    }

    const formatDate = (data) => {
        const startDate = moment(new Date(data)).format('MM/DD/YY');
        return startDate
    }

    const onStatusSelect = useCallback((status) => {
        setStatusFilter(status);
        if (status === 'all') {
            setFilters({ ...filters, status: '' });
        } else {
            setFilters({ ...filters, status: `status=${status}` });
        }
        setStatusMenu(false)
    }, [filters])

    const onTypeSelect = useCallback((type) => {
        setTypeFilter(type);
        if (type === 'all') {
            setFilters({ ...filters, agent_type: '' });
        } else {
            setFilters({ ...filters, agent_type: `agent_type=${type}` });
        }
        setTypeMenu(false)
    }, [filters])


    const onSearch = useCallback(async () => {
        setIsLoading(true);
        try {
            let data = [];
            if (search) {
                data.push(search)
            }
            if (filters.status) {
                data.push(filters.status)
            }
            if (filters.agent_type) {
                data.push(filters.agent_type)
            }
            if (filters.date) {
                const checkDate = moment(filters.date)
                if (checkDate.isValid()) {
                    data.push(filters.date)
                } else {
                    setFilters({ ...filters, date: '', start: '', finish: '' })
                    document.getElementById('start').value = ''
                    document.getElementById('finish').value = ''
                }
            }
            if (filters.start) {
                data.push(filters.start)
            }
            if (filters.finish) {
                data.push(filters.finish)
            }
            await dispatch(actions.showing.searchShowings({ offset: 0, filters: data.length > 0 ? data.join('&') : '', duration: duration })).then(() => {
                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
            console.log(error)
        }
    }, [dispatch, duration, filters, search])

    useEffect(() => {
        const handleSearch = () => {
            onSearch();
        }
        handleSearch()
    }, [duration, filters, search])

    const onChangeInput = useCallback((event) => {
        setSearch(`search=${event.target.value}`)
    }, [])

    const handleInput = useMemo(() => {
        return debouce(onChangeInput, 300)
    }, [onChangeInput])

    const onArchived = (value) => {
        setDuration((prev) => prev === 'current' ? 'past' : 'current');
        navigate(`?view=${value === 'current' ? 'past' : 'current'}`);
    }

    return (
        <div className="manage-showing-container">
            <ConnectedUserModal modal={modal} setModal={setModal} data={selectedData} />
            <DashboardFiltersContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', columnGap: 8 }}>
                    <div className="table-filters">
                        <div sty>
                            <CustomTextInput
                                style={{ width: 220 }}
                                inputProps={{
                                    id: "search",
                                    disabled: false,
                                    placeholder: 'Search Showings',
                                    onChange: handleInput,
                                }}
                                leftSlot={<Icons.Search />}
                            />
                            <div className={`status type ${typeMenu ? 'active' : 'inactive'}`} onClick={() => setTypeMenu((prev) => !prev)}>
                                <p className={`${typeFilter}`}>
                                    {typeFilter === 'all' ? 'All Showings' : typeFilter === 'listing' ? 'As Listing Agent' : typeFilter === 'buyer' ? 'As Buyer\'s Agent' : 'As Team Member'}
                                </p>
                                <div className={`status-menu ${typeMenu ? 'active' : 'inactive'}`} onClick={(e) => e.stopPropagation()}>
                                    <p className={`${typeFilter === 'all' && 'active'}`} onClick={() => onTypeSelect('all')}> <span className="all"></span> All Showings</p>
                                    <p className={`${typeFilter === 'listing' && 'active'}`} onClick={() => onTypeSelect('listing')}> <span className="listing"></span> As Listing Agent</p>
                                    <p className={`${typeFilter === 'buyer' && 'active'}`} onClick={() => onTypeSelect('buyer')}> <span className="buyer"></span> As Buyer's Agent</p>
                                    <p className={`${typeFilter === 'member' && 'active'}`} onClick={() => onTypeSelect('member')}> <span className="member"></span> As Team Member</p>
                                </div>
                            </div>

                            <div className={`status ${statusMenu ? 'active' : 'inactive'}`} onClick={() => setStatusMenu((prev) => !prev)}>
                                <p className={`${statusFilter}`}>
                                    {statusFilter === 'all' ? 'All Status' : statusFilter === 'ongoing' ? 'In Progress' : statusFilter === 'upcoming' ? 'Approved' : statusFilter}
                                </p>
                                <div className={`status-menu ${statusMenu ? 'active' : 'inactive'}`} onClick={(e) => e.stopPropagation()}>
                                    <p className={`${statusFilter === 'all' && 'active'}`} onClick={() => onStatusSelect('all')}> <span className="all"></span> All Status</p>
                                    <p className={`${statusFilter === 'pending' && 'active'}`} onClick={() => onStatusSelect('pending')}> <span className="pending"> </span>Pending</p>
                                    <p className={`${statusFilter === 'approved' && 'active'}`} onClick={() => onStatusSelect('upcoming')}> <span className="approved"> </span>Approved</p>
                                    <p className={`${statusFilter === 'rejected' && 'active'}`} onClick={() => onStatusSelect('rejected')}> <span className="rejected"> </span>Rejected</p>
                                    <p className={`${statusFilter === 'ongoing' && 'active'}`} onClick={() => onStatusSelect('ongoing')}> <span className="ongoing"> </span>In Progress</p>
                                    <p className={`${statusFilter === 'finished' && 'active'}`} onClick={() => onStatusSelect('finished')}> <span className="finished"> </span>Finished</p>
                                    <p className={`${statusFilter === 'cancelled' && 'active'}`} onClick={() => onStatusSelect('cancelled')}> <span className="cancelled"> </span>Cancelled</p>
                                </div>
                            </div>
                            <CustomTextInput
                                style={{ width: 'fit-content' }}
                                inputProps={{
                                    className: 'date',
                                    type: 'date',
                                    id: 'date',
                                    disabled: false,
                                    onChange: (event) => setFilters({ ...filters, date: `date=${event.target.value}` }),
                                }}
                                leftSlot={<Icons.Calendar width={16} height={16} onClick={() => { document.getElementById('date').showPicker() }} />}
                            />
                        </div>

                        {/* <div className="time">
                        <input 
                            id="start" 
                            type="time"
                            name="start_time"
                            autoComplete="new-time"
                            disabled={!filters.date}
                            onChange={(event) => setFilters({...filters, start: `start=${event.target.value}`})} 
                            />
                        <p>to</p>
                        <input 
                            id="finish" 
                            type="time"
                            name="end_time"
                            autoComplete="new-time"
                            disabled={!filters.date}
                            onChange={(event) => setFilters({...filters, finish: `finish=${event.target.value}`})} 
                        />
                    </div> */}
                    </div>
                    <div style={{ display: 'flex', columnGap: 35, alignItems: 'center' }}>
                        {
                            showings.length > 0 &&
                            <div className="pagination">
                                <p>{pages[page - 1]?.name} of {total}</p>
                                <p onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10.0404 3.95955C10.431 4.35007 10.431 4.98324 10.0404 5.37376L7.41421 7.99999L10.0404 10.6262C10.431 11.0167 10.431 11.6499 10.0404 12.0404C9.64992 12.431 9.01675 12.431 8.62623 12.0404L5.29289 8.7071C4.90237 8.31657 4.90237 7.68341 5.29289 7.29288L8.62623 3.95955C9.01675 3.56903 9.64992 3.56903 10.0404 3.95955Z"
                                            fill={previous ? "#5F5F5F" : "#C9C9C9"}
                                        />
                                    </svg>
                                </p>
                                <p onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5.95964 3.95955C6.35017 3.56903 6.98333 3.56903 7.37385 3.95955L10.7072 7.29288C11.0977 7.68341 11.0977 8.31657 10.7072 8.7071L7.37385 12.0404C6.98333 12.431 6.35017 12.431 5.95964 12.0404C5.56912 11.6499 5.56912 11.0167 5.95964 10.6262L8.58587 7.99999L5.95964 5.37376C5.56912 4.98324 5.56912 4.35007 5.95964 3.95955Z"
                                            fill={next ? "#5F5F5F" : "#C9C9C9"}
                                        />
                                    </svg>
                                </p>
                            </div>
                        }
                        <CustomButton
                            text={duration === 'past' ? 'Showings' : 'Archived'}
                            type="secondary"
                            iconLeft={duration === 'past' ? <Icons.Manage /> : <Icons.Archived />}
                            action={() => onArchived(duration)}
                        />
                    </div>
                </div>
            </DashboardFiltersContainer>
            <div className="contents">
                <div className="table-container">
                    {
                        isLoading &&
                        <div className="loader">
                            <Icons.Loading background={'none'} height={100} width={100} />
                        </div>
                    }
                    <table cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th width="227px"><div >Listing</div></th>
                                <th width="110px" style={{ padding: '16px 0' }}><div style={{ whiteSpace: 'nowrap', textAlign: 'center' }} >Connected Users</div></th>
                                {/* <th width="210px"><div >Homeowner(s)</div></th>
                                <th width="210px"><div >Buyer</div></th> */}

                                <th width="64px"><div >Requested</div></th>
                                {/* <th width="146px"><div >Date</div></th> */}
                                <th width="240px" style={{ padding: '16px 0' }}><div >Schedule</div></th>
                                <th width="118px"><div style={{ whiteSpace: 'nowrap' }}>Showing Status</div></th>
                                {
                                    duration === 'current' &&
                                    <>
                                        <th width="87px" ><div style={{ whiteSpace: 'nowrap' }}>Approval Status</div></th>
                                    </>
                                }
                                <th width="307px"><div style={{ textAlign: 'center' }}>Action</div></th>
                            </tr>
                        </thead>
                        <tbody id="showings-table">
                            {
                                showings.length > 0
                                    ? showings.map((showing, index) => {
                                        return (
                                            <tr className='body' key={index}>
                                                <td data-label="Listing">
                                                    <div style={{ display: 'flex', paddingLeft: 16, paddingTop: 8 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap: 10 }}>
                                                            {
                                                                showing.buyers_data[0]?.buyer_tier === 'Q' &&
                                                                <Icons.QueenBeeTier height={16} width={16} />
                                                            }
                                                            {
                                                                showing.listing_data?.agent_members.includes(user?.id) && <Icons.TeamMemberIndicator height={16} width={16} />
                                                            }
                                                            {
                                                                showing.buyer_agent === user?.id && <Icons.BuyerAgentIndicator height={16} width={16} />
                                                            }
                                                            {
                                                                showing.listing_agent === user?.agent_profile?.id && <Icons.ListingAgentIndicator height={16} width={16} />
                                                            }
                                                        </div>
                                                        <ListingBlock data={showing} />
                                                    </div>
                                                </td>
                                                <td align="center" data-label="Connected Users">
                                                    <div className="users-wrapper">
                                                        <UsersBlock data={showing} setModal={setModal} setSelectedData={setSelectedData} />
                                                    </div>
                                                </td>
                                                <td data-label="Requested" align="center">
                                                    <div className="date">
                                                        <p style={{ color: '#5F5F5F', fontSize: 12 }}>{formatDate(showing.created_at)}</p>
                                                    </div>
                                                </td>
                                                <td data-label="Schedule" align="left">
                                                    <div className="time" style={{ display: 'flex', columnGap: 8, alignItems: 'center', fontSize: 12 }}>
                                                        <p style={{ fontWeight: 700 }}>{showing.date}</p>
                                                        <p>{showing.start_time} - {showing.finish_time}</p>
                                                        <p style={{ fontSize: 8 }}>({showing.listing_data.timezone_display.split(" ")[0]})</p>
                                                    </div>
                                                </td>
                                                <td data-label="Showing Status" align="center">
                                                    <div className="status-wrapper">
                                                        <ShowingStatus showing={showing} />
                                                    </div>
                                                </td>
                                                {
                                                    duration === 'current' &&
                                                    <>
                                                        <td data-label="Approval Status" align="center">
                                                            <StatusBlock data={showing} />
                                                        </td>
                                                    </>
                                                }
                                                <td data-label="Action">
                                                    <ActionBlock
                                                        data={showing}
                                                        refresh={onSearch}
                                                        selectedPage={selectedPage}
                                                        isLoading={isLoading}
                                                        setIsLoading={setIsLoading}
                                                        duration={duration}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : <tr>
                                        <td colSpan={`${duration === 'current' ? '8' : '6'}`}>
                                            <div className="empty-table" style={{ fontSize: 14, color: '#949494' }}>
                                                {
                                                    !isLoading &&
                                                    'No Available Showings'
                                                }
                                            </div>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}


export default ManageShowings;