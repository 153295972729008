import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";

// Assets
import { ProSidebar, SidebarContent, Menu, MenuItem } from 'react-pro-sidebar';

import './dashboardContainer.scss';
import Icons from 'components/Icons';
import PageWrapper from 'components/PageWrapper';


const DashboardContainer = () => {
    const { user } = useSelector((state) => state.auth)
    const [showMenu, setShowMenu] = useState(false)
    const [group, setGroup] = useState('overview')

    const navigate = useNavigate()
    const { pathname, search } = useLocation();

    useEffect(() => {
        if(pathname.includes('inquiries') || pathname.includes('connections')) {
            setGroup('connections')
        }
    }, [pathname])

    const checkTitle = () => {
        if (pathname.includes('manage-listings')) {
            return 'Manage Listings'
        }
        if (pathname.includes('manage-showings')) {
            if (search.includes('past')) {
                return 'Archived Showings'
            }
            return 'Manage Showings'
        }
        if (pathname.includes('inquiries')) {
            return 'Inquiries'
        }
        if (pathname.includes('connections')) {
            return 'Connections'
        }
        if (pathname === '/') {
            return 'Overview'
        }
    }

    return (
        <PageWrapper>
            <div className='dashboard-container'>
                <div className={`collapse-menu ${showMenu && 'show'}`} onClick={() => setShowMenu(prev => !prev)}>
                    <Icons.BoxArrowRight />
                </div>
                <div className={`sidebar-contents-container ${showMenu && 'show'}`}>
                    <div className='dashboard-header'>
                        <p>Dashboard</p>
                        <span>{checkTitle()}</span>
                    </div>
                    <div auto="true" className="dashboard-sidebar">
                        <div className='outer'>
                            <ul>
                                <li onClick={() => {setGroup('overview'); navigate('/')}} className={`${(pathname === '/' || pathname.includes('manage-')) && 'active'}`}><Icons.Dashboard color={`${(pathname === '/' || pathname.includes('manage-')) ? 'white' : '#949494'}`} /></li>
                                <li onClick={() => {setGroup('connections'); navigate(user.user_type === 'agent' ? '/inquiries' : '/connections')}} className={`${(pathname.includes('inquiries') || pathname.includes('connection')) && 'active'}`}><Icons.Inquiries color={`${(pathname.includes('inquiries') || pathname.includes('connection')) ? 'white' : '#949494'}`} /></li>
                            </ul>
                        </div>
                        <div className={`sidebar-container ${showMenu && 'show'}`}>
                            <ProSidebar>
                                <SidebarContent>
                                    <Menu
                                    >
                                        {
                                            group === 'overview' &&
                                            <MenuItem
                                                icon={<Icons.DashboardOverview width={20} height={20} color={pathname === '/' ? 'white' : '#949494'} />}
                                                active={pathname === '/'}
                                                onClick={() => navigate('/')}
                                            >
                                                <div className="left" />Overview
                                            </MenuItem>
                                        }

                                        {
                                            user.user_type === 'agent' &&
                                            <>
                                                {
                                                    group === 'overview' &&
                                                    <>
                                                        <MenuItem
                                                            icon={<Icons.Listing width={20} height={20} color={pathname === '/manage-listings' ? 'white' : '#949494'} />}
                                                            active={pathname === '/manage-listings'}
                                                            onClick={() => navigate('/manage-listings')}
                                                        >
                                                            <div className="left" />Listings
                                                        </MenuItem>
                                                        <MenuItem
                                                            icon={<Icons.Showing color={pathname === '/manage-showings' ? 'white' : '#949494'} />}
                                                            onClick={() => navigate('/manage-showings')}
                                                            active={pathname === '/manage-showings'}
                                                        >
                                                            <div className="left" />Showings
                                                        </MenuItem>
                                                    </>
                                                }
                                                {
                                                    group === 'connections' &&
                                                    <>
                                                        <MenuItem
                                                            icon={<Icons.Inquiries color={pathname === '/inquiries' ? 'white' : '#949494'} />}
                                                            onClick={() => navigate('/inquiries')}
                                                            active={pathname === '/inquiries'}
                                                        >
                                                            <div className="left" />Inquiries
                                                        </MenuItem>
                                                        <MenuItem
                                                            icon={
                                                                <Icons.Connections  color={pathname === '/connections' ? 'white' : '#949494'} />
                                                            }
                                                            onClick={() => navigate('/connections')}
                                                            active={pathname === '/connections'}
                                                        >
                                                            <div className="left" />Connections
                                                        </MenuItem>
                                                    </>
                                                }
                                            </>
                                        }
                                        {
                                            (user.user_type === 'mortgage_broker' && group === 'connections')  &&
                                            <MenuItem
                                                icon={<Icons.Connections color={pathname === '/connections' ? 'white' : '#949494'} />}
                                                onClick={() => navigate('/connections')}
                                                active={pathname === '/connections'}
                                            >
                                                <div className="left" />Connections
                                            </MenuItem>
                                        }
                                    </Menu>
                                </SidebarContent>
                            </ProSidebar>
                        </div>
                    </div>
                </div>
                <div className='outlet-container'>
                    <Outlet />
                </div>
            </div>
        </PageWrapper>
    )
};


export default DashboardContainer;