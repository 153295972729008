import * as React from "react";

const Archived = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={13}
    viewBox="0 0 14 13"
    {...props}
  >
    <g transform="translate(-3.375 -4.5)">
      <path
        fill={props.color || '#2a2a2a'}
        d="M17.24,6.4H3.51a.136.136,0,0,1-.135-.136V5.585A1.08,1.08,0,0,1,4.452,4.5H16.3a1.08,1.08,0,0,1,1.077,1.085v.678A.136.136,0,0,1,17.24,6.4Z"
        transform="translate(0)"
      />
      <path
        fill={props.color || '#2a2a2a'}
        d="M17.146,10.266H4.769a.271.271,0,0,0-.269.271v8.886a1.084,1.084,0,0,0,1.076,1.085H16.338a1.084,1.084,0,0,0,1.076-1.085V10.537A.271.271,0,0,0,17.146,10.266Zm-4.507,4.816H9.289a.484.484,0,0,1-.481-.417.473.473,0,0,1,.467-.532h3.35a.484.484,0,0,1,.481.417.475.475,0,0,1-.467.532Z"
        transform="translate(-0.582 -3.008)"
      />
    </g>
  </svg>
);


export default Archived;
