import { useCallback, useEffect, useState } from 'react'
// Redux
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
// Packages
// Components
import Section from 'components/Section'
import Seprator from 'components/Separator';
// Assets
import './accountDeletionForm.scss'
import ShowingBeeLogoBlack from 'assets/images/ShowingBeeLogoBlack.png'
import DeleteAccountIllus from 'assets/images/delete-acc.svg'
import Icons from 'components/Icons';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { getErrorMessage, inputChange, validateEmail } from 'utils/modules/Handlers';
import ConfirmRequest from 'components/ConfirmRequest';
import Footer from 'components/Footer';

const AccountDeletionForm  = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async(event) => {
        let isValid = true

        const { first_name, last_name, email } = form;
        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: ''})
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }
        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
        }
        if (isValid) {
            setLoading(true);
            try {
                const data = {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                }
                await dispatch(actions.auth.accountDeletionRequest(data));
                setLoading(false);
                setSubmit(true);
            } catch (error) {
                setLoading(false);
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
 
    }, [form])

    return (
        <Section style={{ display: 'flex', flexDirection: 'column' }}>
            {
                !submit ?
                <>
                    <div className='account-deletion-container'>
                    <div className='account-deletion-form'>
                            <img 
                                src={ShowingBeeLogoBlack} 
                                alt="logo"
                                className='logo'
                                onClick={() => navigate('/')}
                            />
                            <Seprator height={60} />
                            {
                                loading
                                ? <div className='loading'>
                                    <Icons.Loading />
                                </div>
                                : <>
                                    <h1 className='header'>Account Deletion Request</h1>
                                    <Seprator height={20} />
                                    <h2 className='subheader' style={{ lineHeight: '35px'}}>
                                        Please filled out the form with the information associated with your Showing Bee Account.
                                        <Seprator height={20} />
                                        <div className='illus-container'>
                                            <img 
                                                src={DeleteAccountIllus} 
                                                alt=""
                                            />
                                        </div>
                                        <Seprator height={40} />
                                        <div className='form-section'>
                                            <div className='name'>
                                                <div className='form-input'>
                                                    <label>First Name</label>
                                                    <input
                                                        type='text' 
                                                        className='small'
                                                        id="first_name"
                                                        onChange={(event) => onChangeInput({event, type: "first_name"})} 
                                                    />
                                                    <span className='error-message'>{form.first_name.error}</span>
                                                </div>
                                                <Seprator width={50} />
                                                <div className='form-input'>
                                                    <label>Last Name</label>
                                                    <input
                                                        type='text' 
                                                        className='small'
                                                        id="last_name"
                                                        onChange={(event) => onChangeInput({event, type: "last_name"})} 
                                                    />
                                                    <span className='error-message'>{form.last_name.error}</span>
                                                </div>
                                            </div>
                                            <Seprator height={20} />
                                            <div className='form-input'>
                                                <label>Email Address</label>
                                                <input
                                                    type='text' 
                                                    className='small'
                                                    id="email"
                                                    onChange={(event) => onChangeInput({event, type: "email"})} 
                                                />
                                                <span className='error-message'>{form.email.error}</span>
                                            </div>
                                        </div>
                                    </h2>
                                    <Seprator height={50} />
                                    <button className='button primary' onClick={onSubmit}>Submit</button>
                                </>
                            }
                            
                            <Seprator height={50} />
                        </div>
                    </div>
                    <Footer subfooter />
                </>
                : <ConfirmRequest header={'We received your Request.'} subheader={'Thank you for using Showing Bee.'} />
            }
        </Section>
    )
}


export default AccountDeletionForm;