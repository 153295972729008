import Icons from "components/Icons";
import Seprator from "components/Separator";
import './feedbacks.scss';
import { useCallback, useEffect, useState } from "react";
import actions from "store/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

const Feedbacks = ({ listing }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [feedbacks, setFeedbacks] = useState([])
    const [loading, setLoading] = useState(false);

    const fetchFeedbacks = useCallback(async() => {
        try {
            setLoading(true);
            await dispatch(actions.showing.fetchShowingFeedback(listing.id)).then((data) => {
                setFeedbacks(data.results);
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    }, [])
    
    useEffect(() => {
        fetchFeedbacks();
    }, [])


    const getDate = (data) => {
        const date = moment(data).format('MMM DD, YYYY | dddd')
        return date
    }

    return (
        <section className="feedbacks-container">
            <div className='header'>
                <Icons.Feedback height={15} width={15} color={'#2A2A2A'} />
                <p>Listing Showing Feedbacks</p>
            </div>
            <Seprator height={50} />
            {
                !loading || feedbacks.length > 0
                ? <div className='drafts-section'>
                        {
                            feedbacks.length > 0
                            ? <>
                                 {
                                    feedbacks.map((item, index) => {
                                        return (
                                            <div key={index} className="item-container">
                                                <div style={{ flex: 3 }}>
                                                    {/* <input type='checkbox' className="select" /> */}
                                                    <div className="line-separator" />
                                                    <div className="details">
                                                        <p className='name'>
                                                            {item.user.first_name} {item.user.last_name}
                                                            {
                                                                (item.user.user_type === 'buyer' || item.user.user_type === 'seller')
                                                                ? <Icons.BuyerBadge height={12} className="user-badge" />
                                                                : <Icons.BuyerAgentBadge height={12} width={12} className="user-badge" />
                                                            }
                                                        </p>
                                                        <p className='address'>{item.comments}</p>
                                                        {
                                                            item.showing.start_time && item.showing.finish_time
                                                            ? <span className="time">{getDate(item.showing.date)} | {item.showing.start_time} - {item.showing.finish_time}</span>
                                                            : <span className="time">No Time</span>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                            : <p className="empty">No Feedbacks Available</p>
                        }
                        
                </div>
                : <div className='loading'>
                    <Icons.Loading />
                </div>
            }
        </section>
    )
}


export default Feedbacks;