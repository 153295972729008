import Seprator from 'components/Separator';
import './tierList.scss'
import beeTierIlllu from 'assets/images/bee_tier_illustration.svg'
import proBadge from 'assets/images/Pro-Badge.svg'
import forager from 'assets/images/Forager.png'
import house from 'assets/images/House.png'
import queen from 'assets/images/Queen.png'
import Icons from 'components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import actions from 'store/actions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import { getErrorMessage } from 'utils/modules/Handlers';

const TierList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ref = useRef(null);
    const { user } = useSelector((state) => state.auth)
    const { mortgageConnections: { data } } = useSelector((state) => state.buyer)
    const [connections, setConnections] = useState([]);

    const checkButtonStatus = () => {
        const elementsList = document.querySelectorAll(".tier-button");
        let underReview = false

        elementsList.forEach(element => {
            if (element.textContent === 'Pre-Approval Letter Uploaded') {
                underReview = true
                element.disabled = true;
            }
            if (element.textContent === 'Active') {
                element.classList.add('disabled')
                element.disabled = true;
            }
        });

        if (underReview) {
            elementsList.forEach(element => {
                element.disabled = true;
            });
        }
    }

    const scrollToHash = () => {
        const yOffset = -100;
        const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    useEffect(() => {
        const init = async() => {
            if (user.id) {
                try {
                    await dispatch(actions.buyer.fetchBuyerMortgageConnection());
                    checkButtonStatus();
                } catch (error) {
                    console.log(error)
                }
            }
        }

        init()
    }, [user])

    useEffect(() => {
        const init = async() => {
            const connect = data.filter(item => !item.date_rejected)
            setConnections(connect)
        }

        init()
    }, [data])

    const onTierSelect = (tier) => {
        if (tier === 'H') {
            navigate('/bee-tier/upgrade/house')
        } else {
            navigate('/bee-tier/upgrade/queen')
        }
    }

    const onForgerBee = async() => {
        try {
            let data = {
                buyer_tier: 'F'
            }
            await dispatch(actions.buyer.foragerBuyerDowngrade(data));
            await dispatch(actions.auth.retrieveUser());
            toast.success('You are now a Forager Bee!', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    return (
        <div className="tier-list-container">
            {/* <div className="header-container">
                    <div className="details">
                        <p className="title">Hello there!</p>
                        <p className="jumbotron">Let's choose which type<br className='desktop-only'/>of buyer
                            you are, and it's totally <marker>Free</marker>!
                        </p>
                        <Seprator height={20} />
                        <p className="subtitle">
                            Enjoy the perks and features accessible on each tier. <br className='desktop-only'/>
                            Buyers can present to Listing Agents by qualifying themselves as one of three levels
                        </p>

                        <Seprator height={40} />
                        <button className="button primary no-glow" style={{ width: 250 }} onClick={scrollToHash}>Take a Look</button>
                    </div>
                    <Seprator width={80} />
                    <div className="illustration">
                        <img src={beeTierIlllu}  alt="bee" />
                    </div>
            </div> */}
            <div className="body-container" ref={ref}>
                {/* <div className="welcome-message">
                    <p>
                        Hi {user.first_name}, You are currently a 
                        <span>
                            {
                                user.buyer_tier === 'F'
                                ? ' Forager Bee Buyer!'
                                : user.buyer_tier === 'H'
                                    ? ' House Bee Buyer!'
                                    : ' Queen Bee Buyer!'
                            }
                        </span>
                    </p>
                    <div>
                        <p className="header">
                            We recommend choosing the <span>Queen Bee Buyer Tier</span>
                        </p>
                        <img className='desktop-only' src={proBadge} alt="" />
                    </div>
                </div> */}
                <div className="details">
                    <Seprator height={50} />
                    <p className="title">Hello there!</p>
                    <Seprator height={20} />
                    <p className="jumbotron">Let's choose which type<br className='desktop-only'/>of buyer
                        you are, and it's totally <marker>Free</marker>!
                    </p>
                    <Seprator height={30} />
                    <p className="subtitle">
                        Enjoy the perks and features accessible on each tier. <br className='desktop-only'/>
                        Buyers can present to Listing Agents by qualifying themselves as one of three levels
                    </p>
                    <Seprator height={50} />
                </div>
                <div className="catalog-container">
                    <div className="catalog">
                        <div className='details'>
                            <div className='icon'>
                                <img src={forager} className="bee-type" alt="bee" />
                            </div>
                            <Seprator height={20} />
                            <p className="header">Forager Bee</p>
                            <Seprator height={10} />
                            <p className="subheader">Passive</p>
                            <Seprator height={30} />
                            <p className="title">Documentation</p>
                            <Seprator height={20} />
                            <p className="title">
                                Sellers & Agents may see you as a window shopper
                            </p>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='#808080' />
                                <p>Save searches and add to favorites</p>
                            </div>
                        </div>
                        <button className="button primary medium tier-button" 
                            onClick={onForgerBee}
                            disabled={
                                ((connections.length === 0 && user.buyer_tier === 'F') || (connections.length > 0 && !connections[0].date_approved))
                            }
                        >
                            {((connections.length === 0 && user.buyer_tier === 'F') || (user.buyer_tier === 'F' && (connections.length > 0 && connections[0].date_approved))) ? 'Current Tier' : 'Become a Forager Bee!'}
                        </button>
                    </div>
                    <Seprator width={40} />
                    <div className="catalog queen">
                        <div className='details'>
                            <div className='icon queen'>
                                <img src={queen} className="bee-type" alt="bee" />
                            </div>
                            <Seprator height={20} />
                            <p className="header">Queen Bee</p>
                            <Seprator height={10} />
                            <h5 className="subheader">Hive Master</h5>
                            <Seprator height={30} />
                            <p className="title">Pre-Approved</p>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='white' />
                                <p>Cash buyers are auto-approved</p>
                            </div>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='white' />
                                <p>Pre-Approval letter Uploaded</p>
                            </div>
                            <Seprator height={30} />
                            <p className='or-spacer'>OR</p>
                            <Seprator height={30} />
                            <div className="item">
                                <Icons.CircleCheck color='white' />
                                <p>Get pre-approved through a verified ShowingBee lender</p>
                            </div>
                            <Seprator height={30} />
                            <p className="title">
                                Sellers & Agents may see you as a ready buyer
                            </p>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='white' />
                                <p>Save searches and add to favorites</p>
                            </div>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='white' />
                                <p>Request Showings via Live Calendar</p>
                            </div>
                        </div>
                        <button
                            className="button medium tier-button"
                            onClick={() => onTierSelect('Q')}
                            disabled={
                                user.buyer_tier === 'Q' || 
                                (connections.length > 0 && (!connections[0].date_approved))
                            }
                        >
                            {
                                (user.buyer_tier === 'Q' && (connections.length > 0 && connections[0].date_approved))
                                ? 'Current Tier' 
                                : connections.length > 0 && (connections[0].buyer_tier === 'Q' && (!connections[0].date_approved))
                                    ? 'Pre-Approval Letter Uploaded'
                                    : 'Become a Queen Bee!'
                            }
                        </button>
                    </div>
                    

                    <Seprator width={40} />
                    <div className="catalog">
                        <div className='details'>
                            <div className='icon'>
                                <img src={house} className="bee-type" alt="bee" />
                            </div>
                            <Seprator height={20} />
                            <p className="header">House Bee</p>
                            <Seprator height={10} />
                            <p className="subheader">Basic</p>
                            <Seprator height={30} />
                            <p className="title"> Pre-Qualified</p>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='#808080' />
                                <p>Upload Pre-Qualification letter</p>
                            </div>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='#808080' />
                                <p>Fill out buyer questionnaire</p>
                            </div>
                            <Seprator height={30} />
                            <p className="title">Sellers & Agents may see you as a serious buyer</p>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='#808080' />
                                <p>Save searches and add to favorites</p>
                            </div>
                            <Seprator height={20} />
                            <div className="item">
                                <Icons.CircleCheck color='#808080' />
                                <p className="proxima font--bold text-color--light-grey">Request Showings via Live Calendar</p>
                            </div>
                        </div>
                        <button 
                            className="button primary medium tier-button"
                            onClick={() => onTierSelect('H')}
                            disabled={
                                user.buyer_tier === 'H' || 
                                (connections.length > 0 && (!connections[0].date_approved))
                            }
                        >
                            {
                                (user.buyer_tier === 'H' && (connections.length > 0 && connections[0].date_approved))
                                ? 'Current Tier' 
                                : connections.length > 0 && (connections[0].buyer_tier === 'H' && (!connections[0].date_approved))
                                    ? 'Pre-Approval Letter Uploaded'
                                    : 'Become a House Bee!'
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TierList;