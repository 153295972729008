import * as React from "react"

const Inquiries = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.385}
    height={22.385}
    {...props}
  >
    <path
      data-name="Icon material-inbox"
      d="M19.12 1H3.254a2.238 2.238 0 0 0-2.243 2.265L1 19.12a2.255 2.255 0 0 0 2.254 2.265H19.12a2.272 2.272 0 0 0 2.265-2.265V3.265A2.264 2.264 0 0 0 19.12 1Zm0 13.59h-4.53a3.4 3.4 0 1 1-6.795 0H3.254V3.265H19.12Z"
      fill={props.color || "#bebebe"}
      stroke={props.color || "#bebebe"}
      strokeWidth={2}
    />
  </svg>
)


export default Inquiries
