import * as React from "react"

const CircleCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      id="Icon_awesome-check-circle"
      data-name="Icon awesome-check-circle"
      d="M25.563,13.063a12.5,12.5,0,1,1-12.5-12.5A12.5,12.5,0,0,1,25.563,13.063ZM11.617,19.681l9.274-9.274a.806.806,0,0,0,0-1.14l-1.14-1.14a.806.806,0,0,0-1.141,0l-7.563,7.563L7.515,12.158a.807.807,0,0,0-1.141,0L5.234,13.3a.806.806,0,0,0,0,1.14l5.242,5.242a.806.806,0,0,0,1.141,0Z"
      transform="translate(-0.563 -0.563)"
      fill={props.color || "#808080"}
    />
  </svg>
)


export default CircleCheck
