import Section from 'components/Section'
import Separator from 'components/Separator'
import './dashboard.scss'
import { useEffect, useState } from 'react'
import { CountUp } from 'countup.js'
import actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import house1 from 'assets/images/house1.jpg'
import { checkImageUrl, formatCurrency } from 'utils/modules/Handlers'
import Icons from 'components/Icons'
import { Link, useNavigate } from 'react-router-dom'
import DashboardFiltersContainer from 'components/DashboardFiltersContainer'
import ListingStatus from 'components/ListingStatus'
// Packages
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import ShowingTime from 'components/ShowingTime'
import NoUser from 'assets/images/no_user.jpg'
import CustomSelect from 'components/CustomSelect'
import ShowingUsersBlock from 'components/ShowingUsersBlock'
import ShowingStatus from 'components/ShowingStatus'
import UserPlaceholder from 'components/UserPlaceholder'

const AgentDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth)
    const [showingToday, setShowingToday] = useState([]);
    const [enabledListings, setEnabledListings] = useState([]);
    const [activeShowings, setActiveShowings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statusFilter, setStatusFilter] = useState('');
    const [userFilter, setUserFilter] = useState('');

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await dispatch(actions.agent.listingDashboardData()).then((data) => {
                const totalEl = document.getElementById('total')
                const enabledEl = document.getElementById('enabled')
                const activeEl = document.getElementById('active')
                const pendingEl = document.getElementById('pending')
                const leasedEl = document.getElementById('leased')
                const soldEl = document.getElementById('sold')

                const total = new CountUp(totalEl, data.total);
                const enabled = new CountUp(enabledEl, data.enabled_showing);
                const active = new CountUp(activeEl, data.active);
                const pending = new CountUp(pendingEl, data.pending);
                const leased = new CountUp(leasedEl, data.leased);
                const sold = new CountUp(soldEl, data.sold);

                total.start();
                enabled.start();
                active.start();
                pending.start();
                leased.start();
                sold.start();
            })
            await dispatch(actions.agent.showingDashboardData()).then((data) => {
                const totalEl = document.getElementById('showings-total')
                const activeEl = document.getElementById('showings-active')
                const pendingEl = document.getElementById('showings-pending')
                const approvedEl = document.getElementById('showings-approved')

                const total = new CountUp(totalEl, data.total);
                const active = new CountUp(activeEl, data.active);
                const pending = new CountUp(pendingEl, data.pending);
                const approved = new CountUp(approvedEl, data.approved);

                total.start();
                active.start();
                pending.start();
                approved.start();
            })

            await dispatch(actions.agent.inquiryDashboardData()).then((data) => {
                const totalEl = document.getElementById('inquiries-total')
                const usersEl = document.getElementById('inquiries-users')
                const pendingEl = document.getElementById('inquiries-pending')
                const notifiedEl = document.getElementById('notified-pending')

                const total = new CountUp(totalEl, data.total);
                const users = new CountUp(usersEl, data.sb_user);
                const pending = new CountUp(pendingEl, data.pending);
                const notified = new CountUp(notifiedEl, data.notified);

                total.start();
                users.start();
                pending.start();
                notified.start();
            })

            await dispatch(actions.agent.showingsToday()).then((data) => {
                setShowingToday(data);
            });

            await dispatch(actions.agent.enabledListings()).then((data) => {
                setEnabledListings(data.results);
            });

            await dispatch(actions.agent.approvedShowings({ filters: `agent_type=all` })).then((data) => {
                setActiveShowings(data.results);
            });
            await setLoading(false);
        }

        init();
    }, [])

    useEffect(() => {
        handleShowingFilter();
    }, [statusFilter, userFilter])

    const handleShowingFilter = async () => {
        let filters = [];
        if (statusFilter) {
            filters.push(`status=${statusFilter}`)
        }
        if (userFilter) {
            filters.push(`agent_type=${userFilter}`)
        }
        await dispatch(actions.agent.approvedShowings({ filters: filters.join('&') })).then((data) => {
            setActiveShowings(data.results);
        });
    }

    const handleGoToForm = (item, event) => {
        event.preventDefault();
        navigate(`/manage-listings/${item.id}/calendar`)
    }
    const handleGoToCalendar = (item, event) => {
        event.preventDefault();
        navigate(`/manage-listings/${item.id}/showing-form`)
    }


    const formatDate = (data) => {
        const startDate = moment(new Date(data)).format('MM/DD/YY');
        return startDate
    }

    return (
        <div className='agent-dashboard-container'>
            <DashboardFiltersContainer>

            </DashboardFiltersContainer>
            <div className='contents'>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className='block border-rb no-max-mobile' style={{ maxWidth: 381 }}>
                        <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className='header'>My Showings</p>
                            <p className='view-all' onClick={() => navigate('/manage-showings')}>View All</p>
                        </div>
                        <p className='subheader'> <span id='showings-total'>0</span> Total showings</p>
                        <Separator height={16} />
                        <div className='data' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <p className='title'>Ongoing<br /> Showings</p>
                                <Separator height={16} />
                                <span id='showings-active'>0</span>
                            </div>
                            <div>
                                <p className='title'>Approved<br /> Showings</p>
                                <Separator height={16} />
                                <span id='showings-approved'>0</span>
                            </div>
                            <div>
                                <p className='title'>Pending<br /> Showings</p>
                                <Separator height={16} />
                                <span id='showings-pending'>0</span>
                            </div>
                        </div>
                    </div>
                    <div className='block border-b ' style={{ maxWidth: 760 }}>
                        <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className='header'>My Listings</p>
                            <p className='view-all' onClick={() => navigate('/manage-listings')}>View All</p>
                        </div>
                        <Separator height={27} />
                        <div className='data' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <p className='title'>Active<br /> Listings</p>
                                <Separator height={16} />
                                <span id='active'>0</span>
                            </div>
                            <div>
                                <p className='title'>Pending<br /> Listings</p>
                                <Separator height={16} />
                                <span id='pending'>0</span>
                            </div>
                            <div>
                                <p className='title'>Leased<br /> Listings</p>
                                <Separator height={16} />
                                <span id='leased'>0</span>
                            </div>
                            <div>
                                <p className='title'>Sold<br /> Listings</p>
                                <Separator height={16} />
                                <span id='sold'>0</span>
                            </div>
                            <div>
                                <p className='title'>Total<br /> Listings</p>
                                <Separator height={16} />
                                <span id='total'>0</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className='showings-today-container block border-r' style={{ maxWidth: 381 }}>
                        <Splide
                            hasTrack={false}
                            options={{
                                type: 'slide',
                                rewind: true,
                                pagination: true,
                                arrows: true,
                                focus: 'left',
                                gap: 20,
                                perPage: 1,
                            }}
                        >
                            <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p className='header'>Showing Today</p>
                                <div className="splide__arrows" style={{ display: 'flex' }}>
                                    <div className="splide__arrow splide__arrow--prev">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M10.0404 3.95955C10.431 4.35007 10.431 4.98324 10.0404 5.37376L7.41421 7.99999L10.0404 10.6262C10.431 11.0167 10.431 11.6499 10.0404 12.0404C9.64992 12.431 9.01675 12.431 8.62623 12.0404L5.29289 8.7071C4.90237 8.31657 4.90237 7.68341 5.29289 7.29288L8.62623 3.95955C9.01675 3.56903 9.64992 3.56903 10.0404 3.95955Z"
                                                fill="#C9C9C9"
                                            />
                                        </svg>
                                    </div>
                                    <div className="splide__arrow splide__arrow--next">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M5.9594 3.95955C6.34992 3.56903 6.98309 3.56903 7.37361 3.95955L10.7069 7.29288C11.0975 7.68341 11.0975 8.31657 10.7069 8.7071L7.37361 12.0404C6.98309 12.431 6.34992 12.431 5.9594 12.0404C5.56887 11.6499 5.56887 11.0167 5.9594 10.6262L8.58562 7.99999L5.9594 5.37376C5.56887 4.98324 5.56887 4.35007 5.9594 3.95955Z"
                                                fill="#C9C9C9"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <Separator height={24} />
                            <SplideTrack>
                                {
                                    showingToday.map((item, index) => {
                                        return (
                                            <SplideSlide key={index}>
                                                <div className='showings-today'>
                                                    <img src={checkImageUrl(item.listing_first_image_url, house1)} alt="" />
                                                    <Separator height={16} />
                                                    <ListingStatus color={item.listing_data.listing_status_color} text={item.listing_data.listing_status} />
                                                    <Separator height={16} />
                                                    <p style={{ fontSize: 10, color: '#949494' }}>Listing #{item.listing_data.mls_number || item.listing_data.id}</p>
                                                    <Separator height={8} />
                                                    <p style={{ fontSize: 16, fontWeight: 700, color: '#2a2a2a' }}>{item.listing_data.property_type} in {item.listing_data.city}</p>
                                                    <Separator height={8} />
                                                    <p style={{ fontSize: 10, fontWeight: 500, color: '#949494' }}>
                                                        {`${item.listing_data.address}, ${item.listing_data.city} ${item.listing_data.state} ${item.listing_data.zip_code}`}
                                                    </p>
                                                    <Separator height={16} />
                                                    <p style={{ fontSize: 16, fontWeight: 500, color: '#2a2a2a' }}>{formatCurrency(item.listing_data.price)}</p>
                                                    <Separator height={16} />
                                                    <ShowingTime style={{ width: '100%' }} date={item.date} start={item.start_time} end={item.finish_time} status={item.status} />
                                                    {
                                                        item.listing_data?.agent &&
                                                        <div className="user la">
                                                            <img className="profile" src={checkImageUrl(item.listing_data?.agent_data.user_dataprofile_photo, NoUser)} alt="" />
                                                            <Separator width={10} />
                                                            <div className="detail">
                                                                <span>
                                                                    {`${item.listing_data?.agent_data.user_data.first_name} ${item.listing_data?.agent_data.user_data.last_name} `}
                                                                    <Icons.ListingAgentBadge width={8} height={8} />
                                                                </span>
                                                                <span>{item.listing_data?.agent_data.user_data.email}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        item.buyer_agent_data?.id &&
                                                        <div className="user ba">
                                                            <img className="profile" src={checkImageUrl(item.buyer_agent_data?.profile_photo, NoUser)} alt="" />
                                                            <Separator width={10} />
                                                            <div className="detail">
                                                                <span>
                                                                    {item.buyer_agent_data?.first_name} {item.buyer_agent_data?.last_name}
                                                                    <Icons.BuyerAgentBadge width={8} height={8} />
                                                                </span>
                                                                <span>{item.buyer_agent_data?.email}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        item.buyers.length > 0 &&
                                                        <div className={`user buyer ${item.buyers[0].buyer_tier}`}>
                                                            <img className="profile" src={checkImageUrl(item.buyers[0].profile_photo, NoUser)} alt="" />
                                                            <Separator width={10} />
                                                            <div className="detail">
                                                                <span>
                                                                    {item.buyers[0].first_name} {item.buyers[0].last_name}
                                                                    {
                                                                        item.buyers[0].buyer_tier === 'F'
                                                                            ? <Icons.ForagerBeeTier width={8} height={8} />
                                                                            : item.buyers[0].buyer_tier === 'H'
                                                                                ? <Icons.HouseBeeTier width={8} height={8} />
                                                                                : <Icons.QueenBeeTier width={8} height={8} />
                                                                    }
                                                                </span>
                                                                <span>{item.buyers[0].email}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </SplideSlide>
                                        )
                                    })
                                }
                            </SplideTrack>
                        </Splide>
                        {
                            showingToday.length === 0 &&
                            <p style={{ fontSize: 14, fontWeight: 700, color: '#949494', paddingTop: 6 }}>You have no showings for today.</p>
                        }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div className='block border-b' style={{ maxWidth: 760, flex: 0 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <p className='header' style={{ marginBottom: 6 }}>Active Showings</p>
                                    <p className='subheader'> <span id='showings-total'>{activeShowings.length}</span> Showings active</p>
                                </div>
                                <div style={{ display: 'flex', columnGap: 10, alignItems: 'center' }}>
                                    <CustomSelect
                                        inputProps={{
                                            placeholder: 'Connection',
                                            id: 'status',
                                        }}
                                        style={{ width: 100 }}
                                        onChange={(value) => { setUserFilter(value) }}
                                        options={[
                                            { text: 'All', value: 'all' },
                                            { text: 'Listing Agent', value: 'listing' },
                                            { text: 'Buyer\'s Agent', value: 'buyer' },
                                            { text: 'Team Member', value: 'member' },
                                        ]}
                                    />
                                    <CustomSelect
                                        inputProps={{
                                            placeholder: 'Status',
                                            id: 'status',
                                        }}
                                        style={{ width: 80 }}
                                        onChange={(value) => { setStatusFilter(value) }}
                                        options={[
                                            { text: 'All', value: '' },
                                            { text: 'Pending', value: 'pending' },
                                            { text: 'Approved', value: 'upcoming' },
                                            { text: 'In Progress', value: 'ongoing' },
                                        ]}
                                    />
                                </div>
                            </div>
                            <Separator height={16} />
                            {
                                !loading
                                    ? <ul className='list' style={{ display: 'flex', flexDirection: 'column', rowGap: '16', height: '100%', minHeight: 150 }}>
                                        {
                                            activeShowings.map((item, index) => {
                                                return (
                                                    <li key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', height: 64, alignItems: 'center', width: 240 }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 15, justifyContent: 'center' }}>
                                                                {
                                                                    item.buyers_data[0]?.buyer_tier === 'Q' &&
                                                                    <Icons.QueenBeeTier height={16} width={16} />
                                                                }
                                                                {
                                                                    item.listing_data?.agent_members.includes(user?.id) && <Icons.TeamMemberIndicator height={16} width={16} />
                                                                }
                                                                {
                                                                    item.buyer_agent === user?.id && <Icons.BuyerAgentIndicator height={16} width={16} />
                                                                }
                                                                {
                                                                    item.listing_agent === user?.agent_profile?.id && <Icons.ListingAgentIndicator height={16} width={16} />
                                                                }
                                                            </div>
                                                            <Separator width={19} />
                                                            <div className={`divider ${item.listing_data?.agent_members.includes(user?.id) && 'team-member'} ${item.buyer_agent === user?.id && 'buyer-agent'} ${item.listing_agent === user?.agent_profile?.id && 'listing-agent'}`} style={{ height: 47, width: 5, borderRadius: 8 }}></div>
                                                            <Separator width={16} />
                                                            <div style={{ width: 180 }}>
                                                                <p style={{ fontSize: 12, fontWeight: 700, color: '#2a2a2a', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.listing_data.property_type} in {item.listing_data.city}</p>
                                                                <Separator height={8} />
                                                                <p style={{ fontSize: 10, fontWeight: 500, color: '#949494', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {`${item.listing_data.address}, ${item.listing_data.city} ${item.listing_data.state} ${item.listing_data.zip_code}`}
                                                                </p>
                                                                <Separator height={8} />
                                                                <p style={{ fontSize: 10, color: '#5F5F5F' }}>{item.listing_data.mls_number || item.listing_data.id}</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: 100 }}>
                                                            <ShowingUsersBlock data={item} />
                                                        </div>
                                                        <p style={{ color: '#5F5F5F', fontSize: 10 }}>{formatDate(item.created_at)}</p>
                                                        <div style={{ display: 'flex', columnGap: 8, alignItems: 'center', fontSize: 10 }}>
                                                            <p style={{ fontWeight: 700 }}>{item.date}</p>
                                                            <p>{item.start_time} - {item.finish_time}</p>
                                                            <p>({item.listing_data.timezone_display.split(" ")[0]})</p>
                                                        </div>
                                                        <ShowingStatus showing={item} />
                                                    </li>
                                                )
                                            })
                                        }
                                        {
                                            (activeShowings.length === 0 && !loading) &&
                                            <li style={{ fontSize: 14, fontWeight: 700, color: '#949494', paddingTop: 20 }}>No active showings available</li>
                                        }
                                    </ul>
                                    : <Icons.Loading width={50} height={50} background={'transparent'} />
                            }
                        </div>
                        <div className='block' style={{ maxWidth: 760, flex: 0 }}>
                            <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p className='header'>Enabled Listings</p>
                            </div>
                            <p className='subheader'> <span id='showings-total'>{enabledListings.length}</span> Enabled Listings</p>
                            <Separator height={16} />
                            {
                                !loading

                                    ? <ul className='list' style={{ display: 'flex', flexDirection: 'column', rowGap: '16', height: '100%', minHeight: 150 }}>
                                        {
                                            enabledListings.map((item, index) => {
                                                return (
                                                    <li key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', height: 64, alignItems: 'center', width: 240 }}>
                                                            <img style={{ height: 40, width: 40, objectFit: 'cover', borderRadius: 8 }} src={checkImageUrl(item.primary_photo_url, house1)} alt="" />
                                                            <Separator width={16} />
                                                            <div style={{ width: 180 }}>
                                                                <p style={{ fontSize: 14, fontWeight: 700, color: '#2a2a2a', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.property_type_display} in {item.city}</p>
                                                                <Separator height={8} />
                                                                <p style={{ fontSize: 10, fontWeight: 700, color: '#5F5F5F', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {`${item.address}, ${item.city} ${item.state} ${item.zip_code}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: 218, justifyContent: 'center', alignContent: 'center' }}>
                                                            <p style={{ fontSize: 12, color: '#5F5F5F', width: 118 }}>{item.id}</p>
                                                            <p style={{ fontSize: 12, color: '#5F5F5F', width: 100 }}>{item.mls_number}</p>
                                                        </div>
                                                        <div style={{ width: 72 }}>
                                                            {
                                                                item?.sellers.length > 0
                                                                    ? <UserPlaceholder user={item?.sellers[0]} type={'seller'} customStyle={{ border: '1px solid white', height: 24, width: 24 }} />
                                                                    : <p style={{ fontSize: 10, color: '#5F5F5F' }}>No Seller</p>
                                                            }
                                                        </div>
                                                        <ListingStatus color={item.listing_status_color} text={item.listing_status} />
                                                        <ul style={{ display: 'flex', columnGap: 10 }}>
                                                            <li className='link' onClick={(event) => handleGoToCalendar(item, event)}><Icons.ListingCalendarIcon /></li>
                                                            <li className='link' onClick={(event) => handleGoToForm(item, event)}><Icons.ListingFormIcon /></li>
                                                        </ul>
                                                    </li>
                                                )
                                            })
                                        }
                                        {
                                            enabledListings.length === 0 &&
                                            <li style={{ fontSize: 14, fontWeight: 700, color: '#949494', paddingTop: 20 }}>No enabled listings available</li>
                                        }
                                    </ul>
                                    : <Icons.Loading width={50} height={50} background={'transparent'} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default AgentDashboard;