import Icons from 'components/Icons';
import Seprator from 'components/Separator';

const Testimonial = ({ data }) => {
    const StarRating = ({ count }) => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= count)  stars.push(<Icons.Star key={i} />)
            else stars.push(<Icons.Star color={'#BEBEBE'} key={i} />)
        }
        return stars
    }
    return (
        <div className='testimonial' style={{ backgroundImage: `url(${data.image})` }}>
            <div className='content'>
                <Seprator height={30} />
                <h1 className='title'>{data.title}</h1>
                <Seprator height={12} />
                <p className='body'>{data.body}</p>
                <Seprator height={30} />
                <div style={{ display: 'flex'}}>
                    <div className='ratings' style={{ flex: 3.5 }}>
                        <span>{data.reviewer}</span>
                        <div className='stars'>
                            <StarRating count={data.ratings} />
                        </div>
                    </div>
                </div>
                <Seprator height={30} />
            </div>
        </div>
    )
}


export default Testimonial;