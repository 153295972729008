import ToastMessage from "components/ToastMessage"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import actions from "store/actions"
import { getErrorMessage } from "utils/modules/Handlers"
import './actionBlock.scss'

const ActionBlock = ({ data, setSelectedData, setModal, setQuestionnaireModal, setPreviewModal, setDeleteModal }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onDeleteEntry = (item) => {
        setSelectedData(item)
        setDeleteModal(prev => !prev)
    }

    const onSelectForm = (item) => {
        setSelectedData(item)
        setQuestionnaireModal(prev => !prev)
    }

    const onSelectPreview = (item) => {
        setSelectedData(item)
        setPreviewModal(prev => !prev)
    }

    const approveRejectRequest = async(id, type, tier) => {
        const requestData = { buyer_tier: tier}
        try {
            await dispatch(actions.broker.approveRejectRequest({id, type, data: requestData}));
            await dispatch(actions.buyer.fetchBuyerMortgageConnection());
            toast.success(`Buyer tier upgrade has been ${type === 'reject' ? 'rejected' : 'approved' }`, {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    return (
        <div className="connection-action-block-container">
            {
                (data.buyer_tier === 'Q' && !data.questionnaire_filled_out && data.approval_document) &&
                <p
                    className='action-button approve'
                    onClick={() => onSelectForm(data)}
                >
                    Fill out Questionnaire
                </p>
            }
            {
                data.date_approved || data.date_rejected
                ? <>
                    {
                        (data.buyer_tier === 'Q' &&  data.date_approved && data.questionnaire_filled_out) &&
                        <p className="action-button reject" onClick={() => onSelectPreview(data)}>Questionnaire Preview</p>
                    }
                </>
                : <>
                    {/* {
                        !data.questionnaire_filled_out &&
                        <p className="action-button draft" onClick={() => onSelectEntry(data)}>
                            {
                                data.approval_document ? 'Re-Upload Document' : 'Upload Document'
                            }
                        </p>
                    } */}
                    {
                        (data.approval_document && (data.buyer_tier !== 'Q' || (data.buyer_tier === 'Q' && data.questionnaire_filled_out))) &&
                        <p
                            className='action-button approve'
                            onClick={() => approveRejectRequest(data.id, 'approve', data.buyer_tier)}
                        >
                            Approve
                        </p>
                    }
                    {
                        data.questionnaire_filled_out &&
                        <p className="action-button">
                            Questionnaire Submitted
                        </p>
                    }
                    {
                        (data.buyer_tier !== 'Q' || (data.buyer_tier === 'Q' && !data.questionnaire_filled_out)) &&
                        <p
                            className='action-button reject'
                            onClick={() => approveRejectRequest(data.id, 'reject', data.buyer_tier)}
                        >
                            Reject
                        </p>
                    }
                </>
            }
            <p
                    className='action-button delete'
                    onClick={() => onDeleteEntry(data)}
                >
                    Delete
            </p>
        </div>
    )
}


export default ActionBlock;