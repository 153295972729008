import * as React from "react";

const QueenBeeTier = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <defs>
      <clipPath id="queen-bee-badge-clip-path">
        <path
          data-name="Path 984"
          d="M890.687.52a1.714,1.714,0,0,1-1.7.381,1.9,1.9,0,0,0-2.365,1.116,1.683,1.683,0,0,1-1.367,1.068,1.859,1.859,0,0,0-1.637,2.01,1.643,1.643,0,0,1-.758,1.543,1.828,1.828,0,0,0-.584,2.507h0a1.625,1.625,0,0,1,0,1.712,1.828,1.828,0,0,0,.584,2.507,1.643,1.643,0,0,1,.758,1.543,1.86,1.86,0,0,0,1.637,2.01,1.682,1.682,0,0,1,1.367,1.068,1.9,1.9,0,0,0,2.365,1.116,1.715,1.715,0,0,1,1.7.381,1.9,1.9,0,0,0,1.306.52h.013a1.9,1.9,0,0,0,1.306-.52,1.715,1.715,0,0,1,1.7-.381,1.9,1.9,0,0,0,2.365-1.116,1.682,1.682,0,0,1,1.366-1.068,1.86,1.86,0,0,0,1.637-2.01,1.643,1.643,0,0,1,.758-1.543h0A1.84,1.84,0,0,0,902,11.816v-.008a1.815,1.815,0,0,0-.273-.952,1.626,1.626,0,0,1,0-1.712h0A1.815,1.815,0,0,0,902,8.192V8.184a1.84,1.84,0,0,0-.857-1.547h0a1.643,1.643,0,0,1-.758-1.543,1.86,1.86,0,0,0-1.637-2.01,1.683,1.683,0,0,1-1.366-1.068A1.9,1.9,0,0,0,895.016.9a1.715,1.715,0,0,1-1.7-.381,1.917,1.917,0,0,0-2.625,0"
          transform="translate(-882)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1={-2.258}
        y1={1}
        x2={-2.255}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fac91f" />
        <stop offset={1} stopColor="#f8a728" />
      </linearGradient>
    </defs>
    <g className="queen-bee-badge" transform="translate(-8554 -1163)">
      <g
        data-name="Group 2447"
        transform="translate(8554 1163)"
      >
        <g
          data-name="Group 2446"
          transform="translate(0)"
          clipPath="url(#queen-bee-badge-clip-path)"
        >
          <rect
            data-name="Rectangle 1845"
            width={20.254}
            height={20}
            transform="translate(-0.254)"
            fill="url(#linear-gradient)"
          />
        </g>
      </g>
      <g id="Group_2450" data-name="Group 2450">
        <path
          data-name="Path 985"
          d="M995.151,256.814a5.33,5.33,0,0,1-1.152.852l-.043.023a5.378,5.378,0,0,1-1.312.508.558.558,0,0,0,.6.629,1.973,1.973,0,0,0,1.256-.615c.054-.052.105-.106.153-.16a1.647,1.647,0,0,0,.495-1.238"
          transform="translate(7566.997 919.303)"
          fill="#fff"
        />
        <path
          data-name="Path 986"
          d="M949.321,202.291l.077-.041a4.773,4.773,0,0,0,1.125-.836c.063-.065.124-.13.18-.2s.111-.134.16-.2a2.385,2.385,0,0,0,.363-.7,3.373,3.373,0,0,1-1.933-1.455c-.037.006-.074.012-.111.02h0a4.767,4.767,0,0,0-1.343.494c-1.412.767-2.208,2.031-1.777,2.824a1.438,1.438,0,0,0,1.341.609,3.286,3.286,0,0,0,.489-.038q.143-.021.29-.056t.315-.085a4.921,4.921,0,0,0,.825-.342"
          transform="translate(7611.315 974.294)"
          fill="#fff"
        />
        <path
          data-name="Path 987"
          d="M1106.852,257.689l-.044-.023a5.341,5.341,0,0,1-1.152-.852,1.647,1.647,0,0,0,.495,1.238c.048.055.1.108.153.16a1.972,1.972,0,0,0,1.256.615.558.558,0,0,0,.6-.629,5.372,5.372,0,0,1-1.312-.508"
          transform="translate(7459.755 919.304)"
          fill="#fff"
        />
        <path
          data-name="Path 988"
          d="M1101.625,199.378a4.772,4.772,0,0,0-1.343-.494h0l-.111-.02a3.373,3.373,0,0,1-1.933,1.455,2.388,2.388,0,0,0,.363.7c.05.068.1.135.16.2s.116.131.18.2a4.771,4.771,0,0,0,1.125.836l.076.041a4.914,4.914,0,0,0,.825.342c.106.033.211.061.315.085s.195.041.29.056a3.283,3.283,0,0,0,.489.038,1.438,1.438,0,0,0,1.341-.609c.431-.793-.365-2.058-1.777-2.824"
          transform="translate(7466.783 974.293)"
          fill="#fff"
        />
        <path
          data-name="Path 989"
          d="M1009.263,65.248a.266.266,0,0,0,.023.039.506.506,0,0,0,.174.2,4.1,4.1,0,0,1,4.927,0,.506.506,0,0,0,.174-.2.276.276,0,0,0,.023-.039l.649-1.386c.084-.181-.089-.366-.321-.366a.484.484,0,0,0-.06,0l-1.347.182-1.283-1.754a.394.394,0,0,0-.6,0l-1.283,1.754L1009,63.5a.483.483,0,0,0-.06,0c-.232,0-.405.185-.321.366Z"
          transform="translate(7551.856 1104.363)"
          fill="#fff"
        />
        <path
          data-name="Path 990"
          d="M1019.9,128.711a2.974,2.974,0,0,0-.384-.253,3.688,3.688,0,0,0-3.472,0,2.957,2.957,0,0,0-.384.253,2.719,2.719,0,0,0-.249.219,2.072,2.072,0,0,0-.647,1.468,1.9,1.9,0,0,0,.115.649l.017.045a2.014,2.014,0,0,0,.117.249,2.229,2.229,0,0,0,.163.253,2.917,2.917,0,0,0,1.416.986c.085.029.173.055.261.078s.169.04.256.055a3.819,3.819,0,0,0,1.34,0q.13-.023.256-.055c.089-.023.176-.049.261-.078a2.917,2.917,0,0,0,1.416-.986,2.214,2.214,0,0,0,.163-.253,2.013,2.013,0,0,0,.117-.249l.017-.045a1.9,1.9,0,0,0,.115-.649,2.072,2.072,0,0,0-.647-1.468,2.718,2.718,0,0,0-.249-.219"
          transform="translate(7545.998 1041.515)"
          fill="#fff"
        />
        <path
          data-name="Path 997"
          d="M1049.337,230.648l0-.006,0-.008q.1-.133.19-.271a2.726,2.726,0,0,0,.314-.68,4.307,4.307,0,0,0,1.5,0,2.722,2.722,0,0,0,.314.68q.087.137.189.271l0,.008,0,.006a1.1,1.1,0,0,0-.106.285,3.734,3.734,0,0,1-2.3,0,1.083,1.083,0,0,0-.106-.289"
          transform="translate(7513.193 945.047)"
          fill="#fff"
        />
        <path
          data-name="Path 998"
          d="M1046.187,265.148a2.115,2.115,0,0,0,.193-.356h0c.012-.03.023-.059.031-.086a1.786,1.786,0,0,0,.049-.211,4.273,4.273,0,0,0,2.281.006,1.865,1.865,0,0,0,.048.205c.008.026.017.054.027.082h0c.008.022.017.045.027.069,0,0,.013.032.04.089l.013.026c0,.007.007.014.011.022.022.043.051.1.086.157a3.727,3.727,0,0,1-2.808,0"
          transform="translate(7516.183 912.015)"
          fill="#fff"
        />
        <path
          data-name="Path 999"
          d="M1033.6,285.883a2.918,2.918,0,0,1-1.469.688l-.792,1.215-.814-1.247a2.883,2.883,0,0,1-1.447-.781s.207-.168.295-.245a2.323,2.323,0,0,0,.254-.228,4.244,4.244,0,0,0,3.426.026c.066.085.141.174.225.264a4.134,4.134,0,0,0,.321.309"
          transform="translate(7532.419 892.287)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);


export default QueenBeeTier;
