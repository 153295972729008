import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoUser from 'assets/images/no_user.jpg'
import Icons from "components/Icons";
import { checkImageUrl } from "utils/modules/Handlers";
import "./headerUserBlock.scss"
import actions from "store/actions";
import Separator from "components/Separator";

const HeaderUserBlock = ({ modal, setModal, simple }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const goToProfile = () => {
        navigate('/me')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToBeeTier = () => {
        navigate('/bee-tier')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToContacts = () => {
        navigate('/contacts')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToTeams = () => {
        navigate('/teams/')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToFeatured = () => {
        navigate('/featured/mortgage-broker')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToBuyerDocuments = () => {
        navigate('/me/documents')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToAccountSettings = () => {
        navigate('/me/settings')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const onProfileClick = (e) => {
        e.stopPropagation();
        setModal(prev => !prev);
    }

    const getTierType = (type) => {
        if (type) {
            if (type === 'F') return 'Forager Bee'
            else if (type === 'H') return 'House Bee'
            else return 'Queen Bee'
        }
    }

    const checkMortgageStatus = () => {
        if (user.user_type === 'mortgage_broker') {
            return user.featured_mortgage_broker ? 'Featured Mortgage Broker' : 'Mortgage Broker';
        }
    }

    const logout = async () => {
        await dispatch(actions.auth.logout())
        navigate("/");
    }

    return (
        <div className={`header-user-block-conatiner ${simple && 'simple'}`} onClick={(e) => e.stopPropagation()}>

            {
                !simple
                    ? <>
                        <div className={`menu ${modal && 'active'}`} onClick={() => setModal(prev => !prev)}>
                            <Icons.Menu height={20} width={20} color={modal ? '#fff' : '#949494'} />
                        </div>
                        <img className="photo" src={checkImageUrl(user.profile_photo, NoUser)} alt="" />
                        <div className={`indicator ${user?.user_type} ${user?.buyer_tier}`}>
                            {
                                user?.user_type === 'agent' &&
                                <Icons.ListingAgentBadge height={20} width={20} />
                            }
                            {
                                user?.user_type === 'mortgage_broker' &&
                                <Icons.BrokerBadge height={20} width={20} />
                            }
                            {
                                (user?.user_type === 'buyer' || user?.user_type === 'seller') &&
                                <>
                                    {
                                        user?.buyer_tier === 'F'
                                            ? <Icons.ForagerBeeTier height={20} width={20} />
                                            : user?.buyer_tier === 'H'
                                                ? <Icons.HouseBeeTier height={20} width={20} />
                                                : <Icons.QueenBeeTier height={20} width={20} />
                                    }
                                </>
                            }
                        </div>
                    </>
                    : <>
                        <div className={`pill-menu ${modal && 'active'}`}  onClick={() => setModal(prev => !prev)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.94937 7.44946C5.43752 6.9613 6.22898 6.9613 6.71714 7.44946L9.99992 10.7322L13.2827 7.44946C13.7709 6.9613 14.5623 6.9613 15.0505 7.44946C15.5386 7.93762 15.5386 8.72907 15.0505 9.21723L10.8838 13.3839C10.3956 13.872 9.60419 13.872 9.11603 13.3839L4.94937 9.21723C4.46121 8.72907 4.46121 7.93762 4.94937 7.44946Z"
                                    fill="white"
                                />
                            </svg>
                            <img className="photo" src={checkImageUrl(user.profile_photo, NoUser)} alt="" />
                        </div>
                    </>
            }



            <div className={`profile-menu ${modal ? 'show' : 'remove'}`} onClick={(e) => e.stopPropagation()}>
                <div className="profile-card">
                    <img src={checkImageUrl(user.profile_photo, NoUser)} alt="" />
                    <div>
                        <p>{user?.first_name} {user?.last_name}</p>
                        <span>
                            {
                                (user.user_type === 'buyer' || user.user_type === 'seller')
                                    ? <> {`${getTierType(user?.buyer_tier)} Buyer`}</>
                                    : user.user_type === 'mortgage_broker'
                                        ? checkMortgageStatus()
                                        : 'Verified Agent'
                            }
                        </span>
                    </div>
                </div>
                <Separator height={14} />
                <ul className="menu-items">
                    <li onClick={goToProfile}>My Profile</li>
                    {
                        user.user_type === 'mortgage_broker' &&
                        <li onClick={goToFeatured}>
                            {
                                !user.featured_mortgage_broker
                                    ? 'Upgrade to Featured'
                                    : 'Featured Status'
                            }
                        </li>
                    }
                    {
                        (user.user_type === 'buyer' || user.user_type === 'seller') &&
                        <>
                            <li>
                                {
                                    user?.buyer_tier === 'F'
                                        ? 'Upgrade Now!'
                                        : 'View Tier'
                                }
                            </li>
                            <li onClick={goToBuyerDocuments}>My Documents</li>
                            <li onClick={goToContacts}>My Connections</li>
                        </>
                    }
                    {
                        user.user_type === 'agent' &&
                        <li onClick={goToTeams}>My Teams</li>
                    }
                    <li onClick={goToAccountSettings}>Account Settings</li>
                    <li onClick={logout} className="logout">Sign Out</li>
                </ul>

            </div>
        </div>
    )
}


export default HeaderUserBlock;