import Section from 'components/Section'
import Seprator from 'components/Separator'
import './dashboard.scss'
import { useEffect } from 'react'
import { CountUp } from 'countup.js'
import actions from 'store/actions'
import { useDispatch } from 'react-redux'


const MortgageDashboard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const init = async() => {
            await dispatch(actions.broker.brokerDashboardData()).then((data) => {
                const totalEl = document.getElementById('total')
                const pendingEl = document.getElementById('pending')
                const approvedEl = document.getElementById('approved')
                const amountEl = document.getElementById('amount')
                const queenEl = document.getElementById('queen')
                const missingDocumentEl = document.getElementById('missing-document')
                const expiredDocumentEl = document.getElementById('expired-document')

                const total = new CountUp(totalEl, data.total);
                const pending = new CountUp(pendingEl, data.pending);
                const approved = new CountUp(approvedEl, data.approved);
                const amount = new CountUp(amountEl, data.missingAmount);
                const queen = new CountUp(queenEl, data.queenBees);
                const missingDocument = new CountUp(missingDocumentEl, data.missingAmount);
                const expiredDocument = new CountUp(expiredDocumentEl, data.expiringDocs);

                pending.start();
                total.start();
                approved.start();
                amount.start();
                queen.start();
                missingDocument.start();
                expiredDocument.start();
            })

        }

        init();
    })
    return (
        <Section className='mortgage-dashboard-container'>
            <div className='info-container'>
                <div className='left'>
                    <div className='block-header'>
                        <p>My Connections</p>
                    </div>
                    <Seprator height={30} />
                    <div className='block-info'>
                        <div className='number-blocks'>
                            <p>Total Connections</p>
                            <span id='total'>0</span>
                        </div>
                        <Seprator width={100} />
                        <div className='number-blocks'>
                            <p>Pending Request</p>
                            <span id='pending'>0</span>
                        </div>
                        <Seprator width={100} />
                        <div className='number-blocks'>
                            <p>Approved</p>
                            <span id='approved'>0</span>
                        </div>
                        <Seprator width={100} />
                        <div className='number-blocks'>
                            <p>Missing Amount</p>
                            <span id='amount'>0</span>
                        </div>
                        <Seprator width={100} />
                        <div className='number-blocks'>
                            <p>Total Bee Tier</p>
                            <span id='queen'>0</span>
                        </div>   
                    </div>
                </div>
                <div className='right'>
                    <div className='block-header'>
                        <p>Pre-Approval Documents</p>
                    </div>
                    <Seprator height={30} />
                    <div className='block-info'>
                        <div className='number-blocks'>
                            <p>Missing Documents</p>
                            <span id='missing-document'>0</span>
                        </div>
                        <Seprator width={100} />
                        <div className='number-blocks'>
                            <p>Expiring Documents</p>
                            <span id='expired-document'>0</span>
                        </div>   
                    </div>
                </div>
            </div>
        </Section>
    )
}


export default MortgageDashboard;