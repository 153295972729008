import * as React from "react"


const Listing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28.841}
    height={27.055}
    viewBox="0 0 28 28"
    {...props}
  >
    <g data-name="Group 414" fill={ props.color || "#bebebe"}>
      <path
        data-name="Polygon 2"
        d="M7.112 13.394a2 2 0 0 1 2.014.008l3.484 2.049a2 2 0 0 1 .986 1.707l.032 4.043a2 2 0 0 1-1 1.748l-3.395 1.96a2 2 0 0 1-2.014-.008L3.734 22.85a2 2 0 0 1-.986-1.708l-.032-4.04a2 2 0 0 1 1-1.748Z"
      />
      <path
        data-name="Polygon 3"
        d="M13.356 2.145a2 2 0 0 1 2.014.008l3.484 2.049a2 2 0 0 1 .986 1.707l.032 4.043a2 2 0 0 1-1 1.748l-3.395 1.96a2 2 0 0 1-2.014-.008l-3.485-2.051a2 2 0 0 1-.986-1.708l-.032-4.04a2 2 0 0 1 1-1.747Z"
      />
      <path
        data-name="Polygon 4"
        d="M19.608 12.974a2 2 0 0 1 2.014.008l3.484 2.049a2 2 0 0 1 .986 1.707l.032 4.043a2 2 0 0 1-1 1.748l-3.395 1.96a2 2 0 0 1-2.014-.008L16.23 22.43a2 2 0 0 1-.986-1.708l-.032-4.04a2 2 0 0 1 1-1.748Z"
      />
    </g>
  </svg>
)

export default Listing
