import API from 'API'
import axios from 'axios';
import showingSlice from '../reducers/showing'

const { fetchShowingsSuccess, retrieveShowingSuccess, showingRequestStepSuccess, fetchActiveShowingsSuccess } = showingSlice.actions

const fetchShowingsAll = () => async dispatch => {
    const response = await API.showing.fetchShowingsAll({ offset: 0 });
    const payload = response.data;
    dispatch(fetchShowingsSuccess(payload));
    return payload
}

const fetchDraftShowings = () => async dispatch => {
    const response = await API.showing.fetchDraftShowings({ offset: 0 });
    const payload = response.data;
    dispatch(fetchShowingsSuccess(payload));
    return payload
}

const searchShowings = ({ filters, offset, duration='current' }) => async dispatch => {
    const response = await API.showing.searchShowings({ offset, filters, duration })
    const payload = response.data;
    dispatch(fetchShowingsSuccess(payload));
    return payload
}

const fetchActiveShowings = (duration) => async dispatch => {
    const response = await API.showing.fetchShowings(duration);
    const payload = response.data;
    dispatch(fetchActiveShowingsSuccess(payload));
    return payload
}

const fetchShowingsPagination = (page) => async dispatch => {
    const instance = axios.create({
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    });
    const token = localStorage.getItem('token')
    instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    const response = await instance.get(page);
    const payload = response.data;
    dispatch(fetchShowingsSuccess(payload));
  }
  
const retrieveShowing = (id) => async dispatch => {
    const response = await API.showing.retrieveShowing(id);
    const payload = response.data;
    dispatch(retrieveShowingSuccess(payload));
    return payload
}

const deleteShowing = (id) => async dispatch => {
    const response = await API.showing.deleteShowing(id);
    const payload = response.data;
    return payload
}

const showingRequestStep1 = (data) => async dispatch => {
    const response = await API.showing.showingRequestStep1(data);
    const payload = response.data;
    localStorage.setItem('showingId', payload.id)
    dispatch(showingRequestStepSuccess(payload));
}

const showingRequestStep2Agent = (data) => async dispatch => {
    const response = await API.showing.showingRequestStep2Agent(data);
    const payload = response.data;
    dispatch(showingRequestStepSuccess(payload));
}

const showingRequestStep2RemoveAgent = (data) => async dispatch => {
    const response = await API.showing.showingRequestStep2RemoveAgent(data);
    const payload = response.data;
    dispatch(showingRequestStepSuccess(payload));
}

const showingRequestStep2Buyer = (data) => async dispatch => {
    const response = await API.showing.showingRequestStep2Buyer(data);
    const payload = response.data;
    dispatch(showingRequestStepSuccess(payload));
}

const showingRequestStep3 = (data) => async dispatch => {
    const response = await API.showing.showingRequestStep3(data);
    const payload = response.data;
    dispatch(showingRequestStepSuccess(payload));
}

const showingRequestStep4 = (data) => async dispatch => {
    const response = await API.showing.showingRequestStep4(data);
    const payload = response.data;
    dispatch(showingRequestStepSuccess(payload));
}

const approveShowingBA = (id) => async dispatch => {
    const response = await API.showing.approveShowingBA(id);
    const payload = response.data;
    return payload
}

const approveShowingLA = (id) => async dispatch => {
    const response = await API.showing.approveShowingLA(id);
    const payload = response.data;
    return payload
}

const approveShowingSeller = (id) => async dispatch => {
    const response = await API.showing.approveShowingSeller(id);
    const payload = response.data;
    return payload
}

const approveShowingForSeller = (id) => async dispatch => {
    const response = await API.showing.approveShowingForSeller(id);
    const payload = response.data;
    return payload
}

const rejectShowingLA = ({id, data}) => async dispatch => {
    const response = await API.showing.rejectShowingLA({id, data});
    const payload = response.data;
    return payload
}

const rejectShowingSeller = ({id, data}) => async dispatch => {
    const response = await API.showing.rejectShowingSeller({id, data});
    const payload = response.data;
    return payload
}

const startShowing = (id) => async dispatch => {
    const response = await API.showing.startShowing(id);
    const payload = response.data;
    return payload
}

const finishShowing = (id) => async dispatch => {
    const response = await API.showing.finishShowing(id);
    const payload = response.data;
    return payload
}

const cancelShowing = ({id, data}) => async dispatch => {
    const response = await API.showing.cancelShowing({id, data});
    const payload = response.data;
    return payload
}

const approveShowingEmail = ({id, data}) => async dispatch => {
    const response = await API.showing.approveShowingEmail({id, data});
    const payload = response.data;
    return payload
}

const rejectShowingEmail = ({id, data}) => async dispatch => {
    const response = await API.showing.rejectShowingEmail({id, data});
    const payload = response.data;
    return payload
}

const showingFormRequest = (data) => async dispatch => {
    const response = await API.showing.showingFormRequest(data);
    const payload = response.data;
    return payload
}

const draftShowingsTotal = () => async dispatch => {
    const response = await API.showing.draftShowingsTotal();
    const payload = response.data;
    return payload
}

const requestFeedbackShowing = (id) => async dispatch => {
    const response = await API.showing.requestFeedbackShowing(id);
    const payload = response.data;
    return payload
}

const sendShowingFeedback = (data) => async dispatch => {
    const response = await API.showing.sendShowingFeedback(data);
    const payload = response.data;
    return payload
}

const fetchShowingFeedback = (listing) => async dispatch => {
    const response = await API.showing.fetchShowingFeedback(listing);
    const payload = response.data;
    return payload
}


const showingsActions = {
    fetchShowingsAll,
    fetchDraftShowings,
    searchShowings,
    fetchActiveShowings,
    fetchShowingsPagination,
    retrieveShowing,
    deleteShowing,
    showingRequestStep1,
    showingRequestStep2Agent,
    showingRequestStep2RemoveAgent,
    showingRequestStep2Buyer,
    showingRequestStep3,
    showingRequestStep4,
    approveShowingBA,
    approveShowingLA,
    approveShowingSeller,
    approveShowingForSeller,
    rejectShowingLA,
    rejectShowingSeller,
    startShowing,
    finishShowing,
    cancelShowing,
    approveShowingEmail,
    rejectShowingEmail,
    showingFormRequest,
    draftShowingsTotal,
    requestFeedbackShowing,
    sendShowingFeedback,
    fetchShowingFeedback
}


export default showingsActions;