// Utils
import { inputChange } from 'utils/modules/Handlers';
// Components
import Icons from 'components/Icons';
import Separator from 'components/Separator';
// Assets
import './selectUserType.scss'

const SelectUserType = ({ form, setForm, setActive }) => {
    
    const onTypeSelect = (data) => {
        inputChange({ setForm: setForm, key: 'user_type', value: 'value', data: data })
    }

    return (
        <div className="select-user-type-container">
            <span className='join'>Joining as</span>
            <Separator height={20} />
            {/* <div className={`choices ${form.user_type.value === 'Buyer' && 'active'}`} onClick={() => onTypeSelect('Buyer')}>
                <Icons.BuyerBadge height={22} color={'#bebebe'} />
                <Separator width={10} />
                <span>Buyer</span>
            </div> */}
            <div className={`choices ${form.user_type.value === 'Buyer / Seller' && 'active'}`} onClick={() => onTypeSelect('Buyer / Seller')}>
                {/* <Icons.SellerBadge height={20} width={16} color={'#bebebe'} subcolor={'#F5F5F5'} /> */}
                <Icons.BuyerBadge height={22} color={'#bebebe'} />
                <Separator width={10} />
                <span>Buyer / Seller</span>
            </div>
            {/* <div className={`choices ${form.user_type.value === 'Buyer Agent' && 'active'}`} onClick={() => onTypeSelect('Buyer Agent')}>
                <Icons.BuyerAgentBadge height={20} width={16} color={'#bebebe'} subcolor={'#F5F5F5'} />
                <Separator width={10} />
                <span>Buyer Agent</span>
            </div> */}
            <div className={`choices ${form.user_type.value === 'Agent' && 'active'}`} onClick={() => onTypeSelect('Agent')}>
                <Icons.ListingAgentBadge height={20} width={16} color={'#bebebe'} subcolor={'#F5F5F5'} />
                <Separator width={10} />
                <span>Agent</span>
            </div>
            <div className={`choices ${form.user_type.value === 'Mortgage Broker' && 'active'}`} onClick={() => onTypeSelect('Mortgage Broker')}>
                <Icons.BrokerBadge height={20} width={16} color={'#bebebe'} />
                <Separator width={10} />
                <span>Mortgage Broker</span>
            </div>
            <Separator height={10} />
            <button className='button primary' onClick={() => setActive('information')}>
                Next
            </button>
        </div>
    )
}


export default SelectUserType;