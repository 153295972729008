import Separator from 'components/Separator'
import './dashboard.scss'
import { useEffect } from 'react'
import { CountUp } from 'countup.js'
import actions from 'store/actions'
import { useDispatch } from 'react-redux'
import DashboardFiltersContainer from 'components/DashboardFiltersContainer'
import { useNavigate } from 'react-router-dom'


const MortgageDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const init = async() => {
            await dispatch(actions.broker.brokerDashboardData()).then((data) => {
                const totalEl = document.getElementById('total')
                const pendingEl = document.getElementById('pending')
                const approvedEl = document.getElementById('approved')
                const amountEl = document.getElementById('amount')
                const queenEl = document.getElementById('queen')
                const missingDocumentEl = document.getElementById('missing-document')
                const expiredDocumentEl = document.getElementById('expired-document')

                const total = new CountUp(totalEl, data.total);
                const pending = new CountUp(pendingEl, data.pending);
                const approved = new CountUp(approvedEl, data.approved);
                const amount = new CountUp(amountEl, data.missingAmount);
                const queen = new CountUp(queenEl, data.queenBees);
                const missingDocument = new CountUp(missingDocumentEl, data.missingAmount);
                const expiredDocument = new CountUp(expiredDocumentEl, data.expiringDocs);

                pending.start();
                total.start();
                approved.start();
                amount.start();
                queen.start();
                missingDocument.start();
                expiredDocument.start();
            })

        }

        init();
    })
    return (
        <div className='mortgage-dashboard-container'>
            <DashboardFiltersContainer>

            </DashboardFiltersContainer>
            <div className='contents'>
            <div style={{ display: 'flex', width: '100%' }}>
                    <div className='block border-rb' style={{ maxWidth: 656 }}>
                        <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className='header'>My Connections</p>
                            <p className='view-all' onClick={() => navigate('/connections')}>View All</p>
                        </div>
                        <Separator height={16} />
                        <div className='data' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <p className='title'>Total<br /> Connections</p>
                                <Separator height={16} />
                                <span id='total'>0</span>
                            </div>
                            <div>
                                <p className='title'>Pending<br /> Request</p>
                                <Separator height={16} />
                                <span id='pending'>0</span>
                            </div>
                            <div>
                                <p className='title'>Approved</p>
                                <Separator height={16} />
                                <span id='approved'>0</span>
                            </div>
                            <div>
                                <p className='title'>Missing<br /> Amount</p>
                                <Separator height={16} />
                                <span id='amount'>0</span>
                            </div>
                            <div>
                                <p className='title'>Queen<br /> Bees</p>
                                <Separator height={16} />
                                <span id='queen'>0</span>
                            </div>
                        </div>
                    </div>
                    <div className='block border-b ' style={{ maxWidth: 485 }}>
                        <div style={{ marginBottom: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className='header'>Pre-Approval Documents</p>
                            <p className='view-all' onClick={() => navigate('/connections')}>View All</p>
                        </div>
                        <Separator height={27} />
                        <div className='data' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <p className='title'>Missing<br /> Documents</p>
                                <Separator height={16} />
                                <span id='missing-document'>0</span>
                            </div>
                            <div>
                                <p className='title'>Expiring<br /> Documents</p>
                                <Separator height={16} />
                                <span id='expired-document'>0</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MortgageDashboard;