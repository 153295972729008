import './listingStatus.scss'

const ListingStatus = ({ color, text, fit=false }) => {
    return (
        <div className={`listing-status-component ${fit && 'fit-content'}`}>
            <div className={`status-label ${color}`}>{text}</div>
        </div>
    )
}


export default ListingStatus;