import * as React from "react"

const Showing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    {...props}
  >
    <g transform="translate(-73 -283)">
      <rect
        fill={props.color || "#bebebe"}
        width={25}
        height={16}
        rx={5}
        transform="translate(73 283)"
      />
      <rect
        fill={props.color || "#bebebe"}
        width={5}
        height={11}
        rx={2}
        transform="translate(83 297)"
      />
      <rect
        fill="#f5f5f5"
        width={2}
        height={17}
        rx={1}
        transform="translate(94 287) rotate(90)"
      />
      <rect
        fill="#f5f5f5"
        width={2}
        height={9}
        rx={1}
        transform="translate(86 292) rotate(90)"
      />
    </g>
  </svg>
)


export default Showing
