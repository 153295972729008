import API from 'API'
import axios from 'axios';
import agentSlice from '../reducers/agent'

const { 
  fetchBuyerAgentsSuccess,
  fetchFeaturedAgentsSuccess,
  fetchallAgentsSuccess,
  fetchBuyerAgentConnectionSuccess,
  fetchBuyersSuccess,
  fetchInquiriesSuccess
} = agentSlice.actions

const fetchBuyerAgents = () => async dispatch => {
  const response = await API.agent.fetchBuyerAgents();
  const payload = response.data;
  dispatch(fetchBuyerAgentsSuccess(payload));
}

const fetchFeaturedAgents = ({ filters }) => async dispatch => {
  const response = await API.agent.fetchFeaturedAgents({ filters });
  const payload = response.data;
  dispatch(fetchFeaturedAgentsSuccess(payload));
}

const fetchAllAgents = () => async dispatch => {
  const response = await API.agent.fetchAllAgents();
  const payload = response.data;
  dispatch(fetchallAgentsSuccess(payload));
  return payload
}

const fetchBuyerAgentConnection = () => async dispatch => {
  const response = await API.agent.fetchBuyerAgentConnection({ offset: 0 });
  const payload = response.data;
  dispatch(fetchBuyerAgentConnectionSuccess(payload));
}

const fetchBuyerAgentConnectionPagination = (page) => async dispatch => {
  const instance = axios.create({
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const token = localStorage.getItem('token')
  instance.defaults.headers.common["Authorization"] = `Token ${token}`;
  const response = await instance.get(page);
  const payload = response.data;
  dispatch(fetchBuyerAgentConnectionSuccess(payload));
}

const fetchBuyers = () => async dispatch => {
  const response = await API.agent.fetchBuyers();
  const payload = response.data;
  dispatch(fetchBuyersSuccess(payload));
}

const fetchInquiries = () => async dispatch => {
  const response = await API.agent.fetchInquiries();
  const payload = response.data;
  dispatch(fetchInquiriesSuccess(payload));
}

const inviteBuyer = (data) => async dispatch => {
  const response = await API.agent.inviteBuyer(data);
  const payload = response.data;
  return payload
}

const listingDashboardData = () => async dispatch => {
  const response = await API.agent.listingDashboardData();
  const payload = response.data;
  return payload
}

const showingDashboardData = () => async dispatch => {
  const response = await API.agent.showingDashboardData();
  const payload = response.data;
  return payload
}

const showingsToday = () => async dispatch => {
  const response = await API.agent.showingsToday();
  const payload = response.data;
  return payload
}

const approvedShowings = ({ filters }) => async dispatch => {
  const response = await API.agent.approvedShowings({filters});
  const payload = response.data;
  return payload
}


const enabledListings = () => async dispatch => {
  const response = await API.agent.enabledListings();
  const payload = response.data;
  return payload
}

const inquiryDashboardData = (type = '') => async dispatch => {
  const response = await API.agent.inquiryDashboardData(type);
  const payload = response.data;
  return payload
}

const replyToBuyerInquiry = (data) => async dispatch => {
  const response = await API.agent.replyToBuyerInquiry(data);
  const payload = response.data;
  return payload
}

const resolvedBuyerInquiry = (data) => async dispatch => {
  const response = await API.agent.resolvedBuyerInquiry(data);
  const payload = response.data;
  return payload
}


const agentActions = {
    fetchBuyerAgents,
    fetchFeaturedAgents,
    fetchAllAgents,
    fetchBuyerAgentConnection,
    fetchBuyerAgentConnectionPagination,
    fetchBuyers,
    fetchInquiries,
    inviteBuyer,
    listingDashboardData,
    showingDashboardData,
    showingsToday,
    approvedShowings,
    enabledListings,
    inquiryDashboardData,
    replyToBuyerInquiry,
    resolvedBuyerInquiry
}


export default agentActions;