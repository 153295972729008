import { useNavigate } from 'react-router-dom';
// Components
import Separator from 'components/Separator';
// Assets
import './verifyEmail.scss';

const VerifyEmail = ({ form }) => {
    const navigate = useNavigate()

    const goToLogin = () => {
        navigate('/login')
    }

    return (
        <div className="verify-email-container">
            <div className='content'>
                <h1 className='header'>Verify Email Address</h1>
                <Separator height={13} />
                <p className='subheader'>We have sent a verification email to your email address.</p>
                <Separator height={30} />
                <p className='verify-email'>Email verification sent to <span>{form.email.value}</span></p>
            </div>
            <Separator height={50} />
            <button className='button primary' onClick={goToLogin}>Go To Sign In</button>
        </div>
    )
}


export default VerifyEmail;