// Components
import Icons from 'components/Icons';
import Separator from 'components/Separator';
import ToastMessage from 'components/ToastMessage';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import actions from 'store/actions';
// Utils
import { getErrorMessageSignup, inputChange } from 'utils/modules/Handlers';

const InformationForm = ({ onChangeInput, form, setForm, setActive }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const validatePhone = (phone_number) => {
        let phone = `+1${phone_number}`
        return phone.match(/(^\+\d{11}(\d{2})?$)/);
    };

    const onNext = useCallback(async(event) => {
        const { first_name, last_name, phone_number } = form

        event.preventDefault();
        let isValid = true

        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: ''})
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }

        if (first_name.value && last_name.value) {
            isValid = true
        }
        if (phone_number.value) {
            if (!validatePhone(phone_number.value)) {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: ''})
                isValid = true
            }
        }

        if (isValid) {
            setIsLoading(true);
            try {
                if (phone_number.value) {
                    const data = {
                        phone_number: phone_number.value
                    }
                    await dispatch(actions.auth.validatePhone(data))
                    inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: ''})
                }
                setIsLoading(false);
                if(form.user_type.value === 'Buyer / Seller') {
                    setActive('invite_agent')
                } else {
                    setActive('account')
                }
            } catch (error) {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: getErrorMessageSignup(error.response.data) })
                setIsLoading(false);
                // toast.error(<ToastMessage messages={getErrorMessageSignup(error.response.data)} />, {
                //     position: toast.POSITION.TOP_CENTER
                // });
            }
        }
    }, [form])

    return (
        <form className="information-form-container" onSubmit={(event) => onNext(event)}>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <input 
                        type="text" 
                        placeholder='First Name'
                        value={form.first_name.value}
                        onChange={(event) => onChangeInput({event, type: "first_name"})}
                    />
                    <span className='error-message'>{form.first_name.error}</span>
                </div>
                <Separator width={20} height={20} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <input 
                        type="text" 
                        placeholder='Last Name'
                        value={form.last_name.value}
                        onChange={(event) => onChangeInput({event, type: "last_name"})} 
                    />
                    <span className='error-message'>{form.last_name.error}</span>
                </div>
            </div>
            <Separator height={20} />
            <div className='phone-icon-container'>
                <input 
                    type="tel"
                    maxLength={10}
                    placeholder='Phone Number (Optional)'
                    value={form.phone_number.value}
                    onChange={(event) => onChangeInput({event, type: "phone_number"})} 
                />
            </div>
            <span className='error-message'>{form.phone_number.error}</span>
            <Separator height={30} />
            <button className='button primary' type="submit" >
            {
                isLoading 
                ? <Icons.Loading height={40} width={40} background={'none'} />
                : 'Next'
            }
            </button>
        </form>
    )
}


export default InformationForm;