import './userPlaceholder.scss'

const UserPlaceholder = ({ user=null, type, customStyle, placeholder='' }) => {
  console.log(user)
  return (
    <div className={`user-placeholder ${type}`} style={{...customStyle}}>
      {
        user 
        ? <p>
          {`${user?.first_name.charAt(0)}${user?.last_name.charAt(0)}`}
        </p>
        : <p>{`${placeholder}`}</p>
      }
    </div>
  )
}


export default UserPlaceholder;