import API from 'API'
import axios from 'axios';
import buyerSlice from '../reducers/buyer'

const { fetchBuyerInquiriesSuccess, fetchBuyerMortgageConnectionSuccess } = buyerSlice.actions

const postBuyerInquiry = (data) => async dispatch => {
  const response = await API.buyer.postBuyerInquiry(data);
  const payload = response.data;
  return payload
}

const fetchBuyerInquiries = (agent) => async dispatch => {
  const response = await API.buyer.fetchBuyerInquiries(agent);
  const payload = response.data;
  dispatch(fetchBuyerInquiriesSuccess(payload));
}

const fetchBuyerInquiriesPagination = (page) => async dispatch => {
  const instance = axios.create({
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const response = await instance.get(page);
  const payload = response.data;
  dispatch(fetchBuyerInquiriesSuccess(payload));
}

const inviteAgent = (data) => async dispatch => {
  const response = await API.buyer.inviteAgent(data);
  const payload = response.data;
  return payload
}

const fetchBuyerMortgageConnection = () => async dispatch => {
  const response = await API.buyer.fetchBuyerMortgageConnection({ offset: 0 });
  const payload = response.data;
  dispatch(fetchBuyerMortgageConnectionSuccess(payload));
  return payload;
}

const deleteBuyerInquiry = (id) => async dispatch => {
  const response = await API.buyer.deleteBuyerInquiry(id);
  const payload = response.data;
  return payload
}

const searchBuyerMortgageConnection = ({ filters }) => async dispatch => {
  const response = await API.buyer.searchBuyerMortgageConnection({ offset: 0, filters })
  const payload = response.data;
  dispatch(fetchBuyerMortgageConnectionSuccess(payload));
  return payload
}

const patchBuyerMortgageConnection = ({ id, data }) => async dispatch => {
  const response = await API.buyer.patchBuyerMortgageConnection({ id, data });
  const payload = response.data;
  return payload
}

const deleteBuyerMortgageConnection = (id) => async dispatch => {
  const response = await API.buyer.deleteBuyerMortgageConnection(id);
  const payload = response.data;
  return payload
}

const fetchBuyerMortgageConnectionPagination = (page) => async dispatch => {
  const instance = axios.create({
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const token = localStorage.getItem('token')
  instance.defaults.headers.common["Authorization"] = `Token ${token}`;
  const response = await instance.get(page);
  const payload = response.data;
  dispatch(fetchBuyerMortgageConnectionSuccess(payload));
}


const inviteMortgageBroker = (data) => async dispatch => {
  const response = await API.buyer.inviteMortgageBroker(data);
  const payload = response.data;
  return payload
}

const cashBuyerUpgrade = (data) => async dispatch => {
  const response = await API.buyer.cashBuyerUpgrade(data);
  const payload = response.data;
  return payload
}

const foragerBuyerDowngrade = (data) => async dispatch => {
  const response = await API.buyer.foragerBuyerDowngrade(data);
  const payload = response.data;
  return payload
}

const fetchBuyerProfile = () => async dispatch => {
  const response = await API.buyer.fetchBuyerProfile();
  const payload = response.data;
  return payload
}

const setDefaultAgent = (data) => async dispatch => {
  const response = await API.buyer.setDefaultAgent(data);
  const payload = response.data;
  return payload
}

const setDefaultBroker = (data) => async dispatch => {
  const response = await API.buyer.setDefaultBroker(data);
  const payload = response.data;
  return payload
}


const buyerActions = {
    postBuyerInquiry,
    fetchBuyerInquiries,
    fetchBuyerInquiriesPagination,
    deleteBuyerInquiry,
    inviteAgent,
    fetchBuyerMortgageConnection,
    searchBuyerMortgageConnection,
    fetchBuyerMortgageConnectionPagination,
    inviteMortgageBroker,
    cashBuyerUpgrade,
    foragerBuyerDowngrade,
    patchBuyerMortgageConnection,
    deleteBuyerMortgageConnection,
    fetchBuyerProfile,
    setDefaultAgent,
    setDefaultBroker,
}


export default buyerActions;