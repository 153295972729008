import Separator from 'components/Separator'
import './investorPage.scss'
import ProtectedPageIllus from 'assets/images/ProtectedPageIllus.svg'
import SBLogo from 'assets/images/SBLogo.png'
import Icons from 'components/Icons'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import InvestorDetailPage from './modules/InvestorDetailPage'
import GetAccessModal from './modules/GetAccessModal'

const InvestorPage = () => {
    const [loading, setLoading] = useState(true);
    const [alreadyLogin, setAlreadyLogin] = useState(false);
    const [password, setPassword] = useState(null);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        const init = () => {
            const key = localStorage.getItem('investorKey')
            if (key) {
                setAlreadyLogin(true);
            }
            setLoading(false);
        }

        init();
    }, [])

    useEffect(() => {
        return () => {
            localStorage.removeItem('investorKey');
        };
    }, []);

    const onSubmit = useCallback(() => {
        if (password === 'sb@investor2024') {
            localStorage.setItem('investorKey', true)
            setAlreadyLogin(true)
        } else {
            toast.error('Access Denied', {
                position: toast.POSITION.TOP_CENTER
            });
        }

    }, [password])

    return (
        <div className="investor-page-container">
            <GetAccessModal modal={modal} setModal={setModal} />
            {
                !loading &&
                <>
                    {
                        alreadyLogin
                        ? <>
                        <InvestorDetailPage />
                        </>
                        : <div className='investor-form'>
                            <div className="form">
                                <div className='details'>
                                    <img className='cursor' src={SBLogo} alt='logo' />
                                    <Separator height={30} />
                                    <p className='header'>Exclusive Investment <br />Opportunity</p>
                                    <Separator height={20} />
                                    <p className='subheader'>Welcome to a unique investment opportunity.</p>
                                    <Separator height={50} />
                                    <div className='get-started'>
                                        <p>Get Started</p>
                                        <Separator height={20} />
                                        <span>Enter your PIN or access code to unlock the potential of this investment opportunity. Don't have access code? <mark onClick={() => setModal(prev => !prev)}>Click here</mark>.</span>
                                    </div>
                                    <Separator height={30} />
                                    <input type="password" placeholder='Password' className='small' onChange={(event) => setPassword(event.target.value)}/>
                                    <Separator height={30} />
                                    <div className={`submit-button ${!password && 'disabled'}`} onClick={onSubmit}>
                                        <Icons.RightArrow />
                                    </div>
                                    <Separator height={100} />
                                    <div className='security'>
                                        <p>Security and Confidentiality</p>
                                        <Separator height={20} />
                                        <span>Your information will be kept secure and confidential.</span>
                                    </div>
                                </div>
                            </div>
                            <Separator width={70} />
                            <div className='illustration'>
                                <div className='illus' style={{ backgroundImage: `url(${ProtectedPageIllus})` }} />
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}


export default InvestorPage;