import { Instance, InstanceMultipart } from "utils";

const fetchListings = ({ offset }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`listing/?limit=20&offset=${offset}`)
}

const searchListings = ({ offset, filters }) => {
    return Instance.get(`listing/search/?limit=20&offset=${offset}&${filters}`)
}

const fetchSavedListings = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`saved-listing/`)
}

const postSavedListing = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`saved-listing/`, data)
}

const deleteSavedListing = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.delete(`saved-listing/${id}/`)
}

const retrieveForAGentsListing = (id) => {
    return Instance.get(`listing/${id}/for_agents/`)
}

const retrieveListing = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`listing/${id}/`)
}

const updateListing = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.patch(`listing/${id}/`, data)
}

const fetchListingEvents = (listing) => {
    const token = localStorage.getItem('token')
    if (token) {
        Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    }
    return Instance.get(`/listing/${listing}/events/`)
}

const fetchListingEventsStartEnd = ({ listing, start, end }) => {
    const token = localStorage.getItem('token')
    if (token) {
        Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    }
    return Instance.get(`/listing/${listing}/events/?start=${start}&end=${end}`)
}

const fetchListingCalendarEvents = (listing) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/listing/${listing}/listingevents/`)
}

const addListingCalendarEvent = ({listing, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/listing/${listing}/listingevents/`, data)
}

const deleteListingCalendarEvent = ({ listing, id }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.delete(`/listing/${listing}/listingevents/${id}/`)
}

const updateListingCalendarEvent = ({ listing, id, data }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.put(`/listing/${listing}/listingevents/${id}/`, data)
}

const fetchPublicListings = ({ offset }) => {
    return Instance.get(`public-listing/?limit=20&offset=${offset}`)
}

const searchPublicListings = ({ offset, filters, sort }) => {
    return Instance.get(`public-listing/search/?limit=20&offset=${offset}${filters ? `&${filters}` : ''}${sort ? `&sort=${sort}` : ''} `)
}

const retrievePublicListing = ({ mls, id }) => {
    return Instance.get(`public-listing/${mls}/${id}/`)
}

const fetchSavedSearches = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/saved-search/?ordering=-date_saved`)
}

const postSaveSearch = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/saved-search/`, data)
}

const deleteSaveSearch = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.delete(`/saved-search/${id}`)
}

const addListingSellers = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/listing/${id}/sellers-add/`, data)
}

const editListingSeller = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.put(`/listing/${id}/sellers-edit/`, data)
}

const deleteListingSeller = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/listing/${id}/sellers-delete/`, data)
}

const enableShowingService = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.put(`/listing/${id}/enable-status/`,)
}

const disableShowingService = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.put(`/listing/${id}/disable-status/`,)
}

const postListing = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`listing/`, data)
}

const fetchListingImages = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`listing-image/?ordering=-created_at&listing_id=${id}&limit=50`)
}

const setListingDefaultImage = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`listing-image/${id}/set-default-photo/`)
}

const deleteListingImage = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.delete(`listing-image/${id}/`)
}

const addListingImage = (data) => {
    const token = localStorage.getItem('token')
    InstanceMultipart.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`listing-image/`, data)
}

const fetchListingDocuments = ({id, is_public}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`listing-document/?ordering=-created_at&listing_id=${id}&limit=50&${is_public && 'public'}`)
}

const deleteListingDocument = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.delete(`listing-document/${id}/`)
}

const addListingDocument = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`listing-document/`, data)
}

const setDocumentPublic = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`listing-document/${id}/set-public-document/`)
}

const fetchListingPrice = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`listing-price/?ordering=-created_at&listing_id=${id}&limit=50`)
}

const addListingPrice = (data) => {
    const token = localStorage.getItem('token')
    InstanceMultipart.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`listing-price/`, data)
}

const setListingDefaultPrice = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`listing-price/${id}/set-default-price/`)
}

const removeListingDefaultPrice = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`listing-price/remove-default-price/?listing_id=${id}`)
}

const addListingAgentMember = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/listing/${id}/agent-member-add/`, data)
}

const deleteListingAgentMember = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/listing/${id}/agent-member-delete/`, data)
}

const addDefaultListingSeller = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/listing/${id}/set-default-seller/`, data)
}

const uploadConcessionDocument = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/listing/${id}/upload-concession-document/`, data)
}

const uploadCompensationDocument = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/listing/${id}/upload-compensation-document/`, data)
}

const createCompensationConcessionHistory = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.patch(`/update-listing-compensation-and-concession/${id}/`, data)
}

const fetchCompensationConcessionHistory = ({type, listing}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/list-listing-compensation-and-concession/?request_type=${type}&listing=${listing}`)
}


const auth = {
    fetchListings,
    fetchSavedListings,
    postSavedListing,
    deleteSavedListing,
    retrieveForAGentsListing,
    retrieveListing,
    updateListing,
    fetchListingEvents,
    fetchListingEventsStartEnd,
    fetchListingCalendarEvents,
    addListingCalendarEvent,
    deleteListingCalendarEvent,
    updateListingCalendarEvent,
    fetchPublicListings,
    searchPublicListings,
    retrievePublicListing,
    fetchSavedSearches,
    postSaveSearch,
    deleteSaveSearch,
    addListingSellers,
    editListingSeller,
    deleteListingSeller,
    enableShowingService,
    disableShowingService,
    postListing,
    searchListings,
    fetchListingImages,
    setListingDefaultImage,
    deleteListingImage,
    addListingImage,
    fetchListingDocuments,
    deleteListingDocument,
    addListingDocument,
    setDocumentPublic,
    fetchListingPrice,
    addListingPrice,
    setListingDefaultPrice,
    removeListingDefaultPrice,
    addListingAgentMember,
    deleteListingAgentMember,
    addDefaultListingSeller,
    uploadConcessionDocument,
    uploadCompensationDocument,
    createCompensationConcessionHistory,
    fetchCompensationConcessionHistory,
}


export default auth;
