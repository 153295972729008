import { useCallback, useEffect, useMemo, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
// Packages
import moment from 'moment';
import debouce from 'lodash.debounce'
//  Modules
import ListingBlock from "./modules/ListingBlock";
import SellerBlock from "./modules/SellerBlock";
// Components
import Section from "components/Section";
import Seprator from "components/Separator";
// Assets
import './manageListings.scss';
import { Config } from "utils";
import Icons from "components/Icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMessage from "components/ToastMessage";
import { getErrorMessage } from "utils/modules/Handlers";
import HomeownersModal from "./modules/HomeownersModal";
import ListingStatus from "components/ListingStatus";
import TableHeader from "components/TableHeader";

const ManageListing = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {data: listings, previous, next, total } = useSelector((state) => state.listing);
    const [statusFilter, setStatusFilter] = useState('all');
    const [serviceFilter, setServiceFilter] = useState('all');
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedPage, setSelectedPage] = useState(null);
    const [search, setSearch] = useState(null);
    const [serviceMenu, setServiceMenu] = useState(false);
    const [statusMenu, setStatusMenu] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const [filters, setFilters] = useState({
        status: '',
        service: '',
    })

    // useEffect(() => {
    //     const init = async() => {
    //         await dispatch(actions.listing.fetchListings())
    //     }
    //     init();
    // }, [])
    
    useEffect(() => {
        const init = async() => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total/20)||1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    name: i,
                    value: search ? `${Config.API_URL}/listing/search/?limit=20&offset=${offset}&${search}` : `${Config.API_URL}/listing/?limit=20&offset=${offset}`
                })
                offset = i * 20
            }
            setPages(pageLinks)
        }
        init();
    }, [total, search])

    const loadPageData = async({ page, pageNumber }) => {
        await dispatch(actions.listing.fetchListingsPagination(page));
        setSelectedPage(page);
        setPage(pageNumber);
    }

    // const onSearch = useCallback(async() => {
    //     try {
    //         await dispatch(actions.listing.searchListings({ offset: 0, filters: search }))
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }, [search])

    const formatDate = (data) => {
        const startDate = moment(new Date(data)).format('MM/DD/YY');
        return startDate
    }
    

    const onSearch = useCallback(async() => {
        setIsLoading(true);
        try {
            let data = [];
            if (search) {
                data.push(search)
            }
            if (filters.status) {
                data.push(filters.status)
            }
            if (filters.service) {
                data.push(filters.service)
            }
            await dispatch(actions.listing.searchListings({ offset: 0, filters: data.join('&') })).then(() => {
                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
            console.log(error)
        }
    }, [filters, search])

    useEffect(() => {
        const handleSearch = () => {
            onSearch();
        }
        handleSearch()
    }, [search, filters])

    const onChangeInput = (event) => {
        setSearch(`search=${event.target.value}`)
    }

    const handleInput = useMemo(() => {
        return debouce(onChangeInput, 300)
    }, [])

    const ShowingServiceMessage = ({ title, status, errors }) => {
        return (
            <div>
                <p style={{ fontWeight: 700, color: '#2a2a2a' }}>{title}</p>
                <span style={{ fontWeight: 600 }}>{status}</span>
                {
                    errors &&
                    <ul style={{ fontSize: 14, paddingTop: 5, marginLeft: 15 }}>
                        {
                            errors.map((error, index) => {
                                return (
                                    <li key={index} style={{ paddingTop: 10 }}>-{error}</li>
                                )
                            })
                        }
                    </ul>
                }
            </div>
        )
    } 

    const onChangeService = async(data) => {
        if (data.showing_notes && data.buyer_only_showing_notes && (data.sellers.length > 0 || data.seller_not_required)) {
            if (data.showing_service_status === 'enabled') {
                try {
                    await dispatch(actions.listing.disableShowingService(data.id));
                    toast.info(
                        <ShowingServiceMessage 
                            title={data.mls_number ? data.full_address : `${data.address}, ${data.city} ${data.state} ${data.zip_code}`} 
                            status={'Showing service disabled'}
                        />, {
                        position: toast.POSITION.TOP_CENTER
                    });
                } catch (error) {
                    toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } else {
                try {
                    await dispatch(actions.listing.enableShowingService(data.id));
                    toast.info(
                        <ShowingServiceMessage 
                            title={data.mls_number ? data.full_address : `${data.address}, ${data.city} ${data.state} ${data.zip_code}`} 
                            status={'Showing service enabled'}
                        />, {
                        position: toast.POSITION.TOP_CENTER
                    });
                } catch (error) {
                    toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            if (search || filters.status ||filters.service) {
                onSearch();
            } else {
                if (selectedPage) {
                    await dispatch(actions.listing.fetchListingsPagination(selectedPage));
                } else {
                    await dispatch(actions.listing.fetchListings());
                }
            }
            
        } else {
            const errors = [];
            if (!data.showing_notes) {
                errors.push('Add showing instructions for the Buyer\'s Agent')
            }
            if (!data.buyer_only_showing_notes) {
                errors.push('Add showing instructions for the Solo Buyer')
            }
            if (data.sellers.length === 0 && !data.seller_not_required) {
                errors.push('Add Owner/s')
            }
            if (!data.default_seller && !data.seller_not_required) {
                errors.push('Add a Default Owner')
            }
            toast.warning(
                <ShowingServiceMessage 
                    title={`${data.address}, ${data.city} ${data.state} ${data.zip_code}`}
                    status={'To change the showing service:'}
                    errors={errors}
                />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const onStatusSelect = useCallback((status) => {
        setStatusFilter(status);
        if (status === 'all') {
            setFilters({...filters, status: ''});
        } else {
            setFilters({...filters, status: `status=${status}`});
        }
        setStatusMenu(false)
    }, [statusMenu])

    const onServiceSelect = useCallback((service) => {
        setServiceFilter(service);
        if (service === 'all') {
            setFilters({...filters, service: ''});
        } else {
            setFilters({...filters, service: `service=${service}`});
        }
        setServiceMenu(false)
    }, [serviceMenu])

    return (
        <Section className="manage-listing-container" style={{ minHeight: '91.5vh' }}>
            <HomeownersModal modal={modal} setModal={setModal} data={selectedData} />
            <TableHeader title={'Manage Listings'}>
                <div className="header-button" onClick={() => navigate(`/manage-listings/create`)}>
                    <div><Icons.CirclePlus width={13} height={13} /> <p>Add Listing</p></div>
                </div>
            </TableHeader>
            <div className="table-filters">
                <div>
                    <input className="search" type="text" placeholder="Search Listing" onChange={handleInput} />
                    <Seprator width={30} />
                    <div className={`status service ${serviceMenu ? 'active' : 'inactive'}`} onClick={() => setServiceMenu((prev) => !prev)}>
                        <p className={`${serviceFilter}`}>
                            {
                                serviceFilter === 'all' 
                                ? 'All Service'
                                : serviceFilter === 'enabled'
                                    ? 'Enabled Service'
                                    : 'Disabled Service'
                            }
                        </p>
                        <div className={`status-menu ${serviceMenu ? 'active' : 'inactive'}`} onClick={(e) => e.stopPropagation()}>
                            <p className={`${serviceFilter === 'all' && 'active'}`} onClick={() => onServiceSelect('all')}><span className="all"></span> All Service</p>
                            <p className={`${serviceFilter === 'enabled' && 'active'}`} onClick={() => onServiceSelect('enabled')}><span className="enabled"></span> Enabled Service</p>
                            <p className={`${serviceFilter === 'disabled' && 'active'}`} onClick={() => onServiceSelect('disabled')}><span className="disabled"></span> Disabled Service</p>
                        </div>
                    </div>
                    <Seprator width={30} />
                    <div className={`status ${statusMenu ? 'active' : 'inactive'}`} onClick={() => setStatusMenu((prev) => !prev)}>
                        <p className={`${statusFilter}`}>
                            {statusFilter}
                        </p>
                        <div className={`status-menu ${statusMenu ? 'active' : 'inactive'}`} onClick={(e) => e.stopPropagation()}>
                            <p className={`${statusFilter === 'all' && 'active'}`} onClick={() => onStatusSelect('all')}><span className="all"></span> All</p>
                            <p className={`${statusFilter === 'active' && 'active'}`} onClick={() => onStatusSelect('active')}><span className="active"></span> Active</p>
                            <p className={`${statusFilter === 'pending' && 'active'}`} onClick={() => onStatusSelect('pending')}><span className="pending"></span> Pending</p>
                            <p className={`${statusFilter === 'leased' && 'active'}`} onClick={() => onStatusSelect('leased')}><span className="leased"></span> Leased</p>
                            <p className={`${statusFilter === 'sold' && 'active'}`} onClick={() => onStatusSelect('sold')}><span className="sold"></span> Sold</p>
                            <p className={`${statusFilter === 'soon' && 'active'}`} onClick={() => onStatusSelect('soon')}><span className="soon"></span> Coming Soon</p>
                        </div>
                    </div>
                </div>
            </div>
            <Seprator height={20} />
            <div className="table-container">
                {
                    isLoading &&
                    <div className="loader">
                        <Icons.Loading background={'none'} height={100} width={100} />
                    </div>
                }
                <table cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th width="65px"></th>
                            <th width="385px"><div >Listing</div></th>
                            <th width="210px"><div >Showing Bee ID</div></th>
                            <th width="146px"><div >MLS Number</div></th>
                            <th width="210px"><div >Homeowner(s)</div></th>
                            <th width="230px"><div >Showing Service</div></th>
                            <th width="100px"><div >Status</div></th>
                            <th width="230px"><div >Updated At</div></th>
                            <th width="220px"><div >Action</div></th>
                        </tr>
                    </thead>
                    <tbody id="showings-table">
                        {
                            listings.length > 0 ?
                            listings.map((listing, index) => {
                                return (
                                    <tr className="body" key={index}>
                                        <td data-label="Select" style={{ textAlign: 'center' }}></td>
                                        <td data-label="Listing">
                                            <ListingBlock data={listing} />
                                        </td>
                                        <td data-label="Showing Bee ID" align="center">
                                            <div className="id">
                                                <p>{listing.id}</p>
                                            </div>
                                        </td>
                                        <td data-label="MLS Number" align="center">
                                            <div className="id">
                                                <p>{listing.mls_number}</p>
                                            </div>
                                        </td>
                                        <td data-label="Client" align="center">
                                            <SellerBlock data={listing} setModal={setModal} setSelectedData={setSelectedData} />
                                        </td>
                                        <td data-label="Showing Service" align="center">
                                            <div className="switch-container">
                                                <label className='switch'>
                                                    <input 
                                                        type='checkbox'
                                                        className={`${listing.showing_approval_type === 'auto' && 'auto'}`}
                                                        checked={listing.showing_service_status === 'enabled' ?  true : false}
                                                        disabled={listing.listing_status === 'Pending' & true} 
                                                        onChange={() => onChangeService(listing)}
                                                    />
                                                </label>
                                            </div>
                                        </td>
                                        <td data-label="Status" align="center">
                                           {/* <StatusBlock data={listing} /> */}
                                           <ListingStatus color={listing.listing_status_color} text={listing.listing_status}/>
                                        </td>
                                        <td data-label="Updated At" align="center">
                                            <div className="date">
                                                <p>{ formatDate(listing.updated_at) }</p>
                                            </div>
                                        </td>
                                        
                                        <td data-label="Action">
                                           <div className="action-icons">
                                            
                                            <div data-name="Edit Listing" className={`${(!listing.showing_notes || !listing.buyer_only_showing_notes || (listing.sellers.length === 0 && !listing.seller_not_required) || (!listing.default_seller && !listing.seller_not_required)) && 'notice'}`}>
                                                <Icons.Edit height={15} width={15} onClick={() => navigate(`${listing.id}/update`)} />
                                            </div>
                                            <div data-name="Listing Images" className={`${!listing.primary_photo_url && 'notice'}`}>
                                                <Icons.Image height={12} width={12} onClick={() => navigate(`${listing.id}/images`)} />
                                            </div>
                                            <div data-name="Documents">
                                                <Icons.Document height={12} width={12} onClick={() => navigate(`${listing.id}/documents`)} />
                                            </div>
                                            <div data-name="View Feedbacks">
                                                <Icons.Feedback height={15} width={15} color={'#2a2a2a'} onClick={() => navigate(`${listing.id}/feedbacks`)} />
                                            </div>
                                            <div data-name="View Listing">
                                                <Icons.Eye  height={15} width={15} color={'#2a2a2a'} onClick={() => navigate(`/listings/${listing.mls_source}/${listing.mls_number || listing.id}`)} />
                                            </div>
                                            <div data-name="View Calendar">
                                                <Icons.Calendar height={15} width={15} color={'#2a2a2a'} onClick={() => navigate(`${listing.id}/calendar`)} />
                                            </div>
                                            {
                                                listing.showing_service_status === 'enabled' &&
                                                <div data-name="Showing Form">
                                                    <Icons.ShowingForm height={15} width={15} color={'#2a2a2a'} onClick={() => navigate(`${listing.id}/showing-form`)} />
                                                </div>
                                            }

                                           </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan="9">
                                    <div className="empty-table">
                                        {
                                            !isLoading &&
                                            'No Available Listings'
                                        }
                                    </div>
                                </td>
                            </tr> 
                        }
                    </tbody>
                </table>
            </div>
            {
                listings.length > 0 &&
                <div className="pagination">
                    <p className="previous" onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}> { previous && 'Previous' } </p>
                    <ul> 
                        {
                            pages.map((item, index) => {
                                return(
                                    <li 
                                        key={index}
                                        className={`${item.name === page && 'active'}`}
                                        onClick={() => loadPageData({ page: item.value, pageNumber: item.name })}
                                    >
                                        {item.name}
                                    </li>)
                            })
                        }
                    </ul>
                    <p className="next" onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}> { next && 'Next' } </p>
                </div>
            }
        </Section>
    )
}


export default ManageListing;