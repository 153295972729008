import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";

// Assets
import { ProSidebar, SidebarContent, Menu, MenuItem } from 'react-pro-sidebar';

import './sidebar.scss';
import Section from 'components/Section';
import Icons from 'components/Icons';


export default function SideBar() {
  const { user } = useSelector((state) => state.auth)
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showMenu, setShowMenu] = useState(false)
  
  const navigate = useNavigate()
  const { pathname } = useLocation();
    
  return (
    <Section auto className="dashboard-sidebar">
      <div className='mobile-only menu-icon open'>
          <Icons.More width={25} height={25} onClick={() => setShowMenu(prev => !prev)} />
      </div>
      <div className={`sidebar-container ${showMenu && 'show'}`}>
        <ProSidebar collapsed={isCollapsed} collapsedWidth={200}>
          <div className='mobile-only menu-icon close'>
              <Icons.Close height={15} width={15} onClick={() => setShowMenu(prev => !prev)} />
          </div>
          <SidebarContent>
            {/**
             *  You can add the content of the sidebar ex: menu, profile details, ...
             */}
            <Menu
            >
              <MenuItem 
                icon={<Icons.Home color={pathname === '/' ? '#2A2A2A' : '#bebebe'} />} 
                active={pathname === '/'}
                onClick={() => navigate('/')}
                >
                <div className="left"/>Dashboard
              </MenuItem>
              {
                user.user_type === 'agent' &&
                <>
                  <MenuItem 
                    icon={<Icons.Listing color={pathname === '/manage-listings' ? '#2A2A2A' : '#bebebe'} />} 
                    active={pathname === '/manage-listings'}
                    onClick={() => navigate('/manage-listings')}
                  >
                    <div className="left"/>Listings
                  </MenuItem>
                  <MenuItem 
                    icon={<Icons.Showing color={pathname === '/manage-showings' ? '#2A2A2A' : '#bebebe'} />} 
                    onClick={() => navigate('/manage-showings')}
                    active={pathname === '/manage-showings'}
                  >
                    <div className="left"/>Showings
                  </MenuItem>
                  <MenuItem 
                    icon={<Icons.Inquiries color={pathname === '/inquiries' ? '#2A2A2A' : '#bebebe'} />}  
                    // suffix={<div className="badge">0</div>}
                    onClick={() => navigate('/inquiries')}
                    active={pathname === '/inquiries'}
                  >
                    <div className="left"/>Inquiries
                  </MenuItem>
                </>
              }
              {
                user.user_type === 'mortgage_broker' &&
                <MenuItem 
                    icon={<Icons.MortgageConnection color={pathname === '/connections' ? '#2A2A2A' : '#bebebe'} />}  
                    // suffix={<div className="badge">0</div>}
                    onClick={() => navigate('/connections')}
                    active={pathname === '/connections'}
                  >
                    <div className="left"/>Connections
                </MenuItem>
              }
              {
                user.user_type !== 'mortgage_broker' &&
                <MenuItem 
                  icon={
                    <Icons.Contact 
                      color={pathname === '/contacts' ? '#2A2A2A' : '#bebebe' } 
                      width={25}
                      height={25}
                    />
                  }  
                  onClick={() => navigate('/contacts')}
                  active={pathname === '/contacts'}
                >
                  <div className="left"/>Connections
                </MenuItem>
              }
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
      <div className='outlet-container'>
        <Outlet />
      </div>
    </Section>
  )
};