import { useCallback, useEffect, useState } from 'react'
// Redux
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
// Packages
// Components
import Section from 'components/Section'
import Seprator from 'components/Separator';
// Assets
import './confirmTemporaryPassword.scss'
import ShowingBeeLogoBlack from 'assets/images/ShowingBeeLogoBlack.png'
import ForgotPasswordIllus from 'assets/images/forgot-pass-page-illus.svg'
import Icons from 'components/Icons';
import { getErrorMessage, inputChange } from 'utils/modules/Handlers';
import ToastMessage from 'components/ToastMessage';
import { toast } from 'react-toastify';

const ConfirmTemporaryPassword  = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [viewPassword2, setViewPassword2] = useState(false);
    const [form, setForm] = useState({
        password: {
            value: '',
            error: '',
        },
        confirm_password: {
            value: '',
            error: '',
        },
    });


    useEffect(() => {
        const init = async() => {
            if (urlParams.get('uidb64') && urlParams.get('tmp') && urlParams.get('token')) {
                setLoading(prev => !prev);
            } else {
                navigate('/confirm-temporary-password/invalid/');

            }
        }
        init();
    }, [])

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async(event) => {
        event.preventDefault();

        const { password, confirm_password } = form
        let isValid = true

        if (password.value === '') {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: 'Password cannot be blank'})
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'password', value: 'error', data: ''})
        }

        if (confirm_password.value !== password.value) {
            inputChange({ setForm: setForm, key: 'confirm_password', value: 'error', data: 'Password does not match'})
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'confirm_password', value: 'error', data: ''})
        }

        if ((password.value && confirm_password.value) && confirm_password.value === password.value) {
            isValid = true
        }

        if (isValid) {
            setLoading(true);
            try {
                const data = {
                    uidb64: urlParams.get('uidb64'),
                    token: urlParams.get('token'),
                    temporary_code: urlParams.get('tmp'),
                    password: password.value,
                }
                await dispatch(actions.auth.confirmResetPassword(data)).then(() => {
                    setSubmitted(true);
                    setLoading(false);
                });
            } catch (error) {
                setLoading(false);
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
 
    }, [dispatch, form, navigate])

    return (
        <Section style={{ display: 'flex' }}>
            <div className='conform-temp-password-container'>
               <div className='conform-temp-password-form'>
                    <img 
                        src={ShowingBeeLogoBlack} 
                        alt="logo"
                        className='logo'
                        onClick={() => navigate('/')}
                    />
                    <Seprator height={60} />
                    {
                        loading
                        ? <div className='loading'>
                            <Icons.Loading />
                        </div>
                        : <>
                            {
                                !submitted
                                ? <>
                                    <p className="header">Reset Password Setup</p>
                                    <Seprator height={10} />
                                    <p className="subheader">Please setup your password to complete the reset.</p>
                                    <Seprator height={20} />
                                    <img className='illus' 
                                        src={ForgotPasswordIllus} 
                                        alt=""
                                    />
                                    <Seprator height={40} />
                                    <div className="form-container">
                                        <div className="password small">
                                            <input 
                                                type={viewPassword ? 'text' : 'password'} 
                                                placeholder='Password'
                                                autoComplete="new-password"
                                                className='small' 
                                                onChange={(event) => onChangeInput({event, type: "password"})}
                                            />
                                            <span className='error-message'>{form.password.error}</span>
                                            <Icons.Eye color={viewPassword ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword(prev => !prev)} />
                                        </div>
                                        <Seprator height={30} />
                                        <div className="password small">
                                            <input 
                                                type={viewPassword2 ? 'text' : 'password'} 
                                                className='small'
                                                autoComplete="new-password"
                                                placeholder='Confirm Password' 
                                                onChange={(event) => onChangeInput({event, type: "confirm_password"})}
                                            />
                                            <span className='error-message'>{form.confirm_password.error}</span>
                                            <Icons.Eye color={viewPassword2 ? '#FAC91F' : '#808080'} width={15} onClick={() => setViewPassword2(prev => !prev)} />
                                        </div>
                                        <span className='error-message'>{form.confirm_password.error}</span>
                                        <Seprator height={50} />
                                        <button className="button primary small no-glow browse" disabled={loading} onClick={onSubmit}>
                                            {
                                                loading 
                                                ? <Icons.Loading height={40} width={40} background={'none'} />
                                                : 'Submit'
                                            }
                                        </button>
                                    </div>
                                </>
                                : <>
                                    <h1 className='header'>Password has been reset!</h1>
                                    <Seprator height={10} />
                                    <h2 className='subheader' style={{ lineHeight: '35px'}}>
                                        Please login into your account.
                                    </h2>
                                    <Seprator height={50} />
                                    <button className='button primary' onClick={() => navigate('/login')}>Back to Login</button>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </Section>
    )
}


export default ConfirmTemporaryPassword;