// Assets
import house1 from 'assets/images/house1.jpg'
import Seprator from 'components/Separator';
import { checkImageUrl } from 'utils/modules/Handlers';
import './listingBlock.scss'


const ListingBlock = ({ data }) => {
    return (
        <div className="manage-listing listing-block-container">
            <img className="item-image" src={checkImageUrl(data.primary_photo_url, house1)} alt="" />
            <Seprator width={25} />
            <div className='details'>
                <p className='name'>{data.property_type_display} in {data.city}</p>
                <Seprator height={10} />
                <p className='address'>
                    {
                        data.mls_number 
                        ? <>{data.full_address}</>
                        : <>{`${data.address}, ${data.city} ${data.state} ${data.zip_code}`}</>
                    }
                </p>
            </div>
        </div>       
    )
}


export default ListingBlock;