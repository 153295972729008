
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// Redux
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
// Components
import Icons from "components/Icons";
import Seprator from "components/Separator";
// Assets
import './headerNavigation.scss'
import Logo from 'assets/images/dashboard_logo.svg'
import SBLogo from 'assets/images/SBLogo.png'
import NoUser from 'assets/images/no_user.jpg'
import QueenBee from 'assets/images/Queen.png'
import HouseBee from 'assets/images/House.png'
import ForagerBee from 'assets/images/Forager.png'
import { checkImageUrl } from "utils/modules/Handlers";
import Talk from "talkjs";
import { Config } from "utils";
import ForagerBeeTier from "components/Icons/modules/ForagerBeeTier";
import HouseBeeTier from "components/Icons/modules/HouseBeeTier";
import QueenBeeTier from "components/Icons/modules/QueenBeeTier";

const HeaderNavigation = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [modal, setModal] = useState(false)
    const [activeItem, setActiveItem] = useState('/')
    const [unread, setUnread] = useState(0)
    const [session, setSession] = useState(null)
    const { pathname } = useLocation();
    const { user } = useSelector((state) => state.auth)
    const [talkLoaded, markTalkLoaded] = useState(false);


    useEffect(() => {
        const init = () => {
            Talk.ready.then(() => markTalkLoaded(true));
            if (user.id && talkLoaded) {
                const currentUser = new Talk.User({
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                    photoUrl: checkImageUrl(user.profile_photo, NoUser),
                    role: user.user_type,
                });
                
                const sessionData = new Talk.Session({
                    appId: Config.TalkJS_APP_ID,
                    me: currentUser,
                });
                setSession(sessionData)
            }
        }

        init();
    }, [user, talkLoaded])

    useEffect(() => {
        if (session) {
            session.unreads.onChange((unreadConversations) => {
                const amountOfUnreads = unreadConversations.length;
                setUnread(amountOfUnreads)
            });
        }
    }, [session])

    useEffect(() => {
        if (pathname === "/") {
            setActiveItem("/")
        } else if (pathname.includes('/waggle')) {
            setActiveItem("/waggle")
        } else if (pathname.includes('/listings') || pathname.includes('/request')) {
            setActiveItem("/listings")
        } else if (pathname.includes('/main-calendar')) {
            setActiveItem("/main-calendar")
        } else if (pathname.includes('/messages')) {
            setActiveItem("/messages")
        } else if (pathname.includes('/showings')) {
            setActiveItem("/showings")
        } else if (pathname.includes('/me') || pathname.includes('/contacts') || pathname.includes('/bee-tier')) {
            setActiveItem("")
        }

    }, [pathname])
    
    const logout = async() => {
        await dispatch(actions.auth.logout())
        navigate("/");
    }

    const getTierType = (type) => {
        if (type) {
            if (type === 'F') return 'Forager Bee'
            else if (type === 'H') return 'House Bee'
            else return 'Queen Bee'
        }
    }

    const goToProfile = () => {
        navigate('/me')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToBeeTier = () => {
        navigate('/bee-tier')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToContacts = () => {
        navigate('/contacts')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToTeams = () => {
        navigate('/teams/')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToFeatured = () => {
        navigate('/featured/mortgage-broker')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToBuyerDocuments = () => {
        navigate('/me/documents')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToAccountSettings = () => {
        navigate('/me/settings')
        setModal(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const navigateTo = (item) => {
        navigate(`${item}`)
        setActiveItem(item)
    }

    const checkMortgageStatus = () => {
        if (user.user_type === 'mortgage_broker') {
            return user.featured_mortgage_broker ? 'Featured Mortgage Broker' : 'Mortgage Broker';
        }
    }

    const onProfileClick = (e) => {
        e.stopPropagation();
        setModal(prev => !prev);
    }

    return (
        <>
            <header className="waggle-navigation" onClick={() => setModal(false)}>
                <div className="logo" onClick={() => navigateTo('/home')}>
                    <img src={SBLogo} alt="" />
                </div>
                <div className="nav-items">
                    <ul>
                        {
                            (user.user_type === 'agent' || user.user_type === 'mortgage_broker') &&
                            <li data-name="Dashboard" className={`${activeItem === '/' && 'active'}`} onClick={() => navigateTo('/')}>
                                <Icons.Dashboard color={`${activeItem === '/' ? '#FAC91F' : '#6C767C'}`} />
                            </li>
                        }
                        {
                            (user.user_type === 'buyer' || user.user_type === 'seller') 
                            ? <li data-name="Feed" className={`${activeItem === '/' && 'active'}`} onClick={() => navigateTo('/')}>
                                <Icons.Waggle 
                                    color={`${activeItem === '/' ? '#FAC91F' : '#6C767C'}`} 
                                    
                                />
                            </li>
                            : <li data-name="Feed" className={`${activeItem === '/waggle' && 'active'}`} onClick={() => navigateTo('/waggle')}>
                                <Icons.Waggle 
                                    color={`${activeItem === '/waggle' ? '#FAC91F' : '#6C767C'}`} 
                                    
                                />
                            </li>
                        }
                        {
                            (user.user_type === 'agent' || user.user_type === 'buyer' || user.user_type === 'seller') &&
                            <li data-name="Search" className={`${activeItem === '/listings' && 'active'}`} onClick={() => navigateTo('/listings')}>
                                <Icons.Search color={`${activeItem === '/listings' ? '#FAC91F' : '#6C767C'}`} />
                            </li>
                        }
                        <li data-name="Messages" className={`${activeItem === '/messages' && 'active'} messages`} onClick={() => navigateTo('/messages')}>
                            { unread > 0 && <p className="notif">{unread}</p> }
                            <Icons.Message color={`${activeItem === '/messages' ? '#FAC91F' : '#6C767C'}`} />
                        </li>

                        {
                            user.user_type !== 'mortgage_broker' &&
                            <li data-name="Calendar" className={`${activeItem === '/main-calendar' && 'active'}`} onClick={() => navigateTo('/main-calendar')}>
                                <Icons.Calendar color={`${activeItem === '/main-calendar' ? '#FAC91F' : '#6C767C'}`} />
                            </li>
                        }

                        {
                            (user.user_type === 'buyer' || user.user_type === 'seller') &&
                            <li data-name="Showings" className={`${activeItem === '/showings' && 'active'}`} onClick={() => navigateTo('/showings')}>
                                <Icons.Showing color={`${activeItem === '/showings' ? '#FAC91F' : '#6C767C'}`} />
                            </li>
                        }
                        {/* <li data-name="Saved"><Icons.Save color="#6C767C" /></li> */}
                    </ul>
                </div>
                <div className="user-profile">
                    <div id="user-profile-block" onClick={(e) => onProfileClick(e)}>
                        <img className="user-profile-picture" src={checkImageUrl(user.profile_photo, NoUser)} alt="" />
                        {/* <div className="user-profile-info">
                            <p>{user?.first_name} {user?.last_name}</p>
                            <span>
                                {`${getTierType(user?.buyer_type)} Buyer`}
                            </span>
                        </div> */}
                    </div>
                    <div id="dropdown-nav" className={`${modal ? 'show' : 'remove'}`} onClick={(e) =>  e.stopPropagation()}>
                        <div className="buyer-agent-info">
                            <div className="buyer-agent">
                                <div>
                                    <img src={checkImageUrl(user.profile_photo, NoUser)} alt="" />
                                    <div>
                                        <p>{user?.first_name} {user?.last_name}</p>
                                        <span>
                                            {
                                                (user.user_type === 'buyer' || user.user_type === 'seller') 
                                                ? <> {`${getTierType(user?.buyer_tier)} Buyer`}</>
                                                : user.user_type === 'mortgage_broker' 
                                                    ? checkMortgageStatus()
                                                    : 'Verified Agent'
                                            }
                                        </span>
                                    </div>
                                </div>
                                <Icons.Swap className="swap-icon" />
                            </div>
                            {
                                (user.user_type === 'buyer' || user.user_type === 'seller') &&
                                <>
                                    <Seprator width={15} />
                                    <div className={`buyer-tier ${user?.buyer_tier === 'Q' && 'queen'}`}>
                                        <div className="tier">
                                            {
                                                user?.buyer_tier === 'F'
                                                ? <ForagerBeeTier height={40} width={40} />
                                                : user?.buyer_tier === 'H'
                                                    ? <HouseBeeTier height={40} width={40} />
                                                    : <QueenBeeTier height={40} width={40} />
                                            } 
 
                                        </div>
                                        <div className="tier-info">
                                            <p>
                                                {getTierType(user?.buyer_tier)}
                                            </p>
                                            
                                            <span onClick={goToBeeTier}>
                                                {
                                                    user?.buyer_tier === 'F' 
                                                    ? 'Upgrade Now!'
                                                    : 'View Tier'
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <Seprator height={20} />
                        <div className="dropdown-nav--items">
                            {
                                user.user_type === 'mortgage_broker' &&
                                <>
                                    <div>
                                        <Icons.Contact />
                                        <p onClick={goToFeatured}>
                                            {
                                                !user.featured_mortgage_broker 
                                                ? 'Upgrade to Featured'
                                                : 'Featured Status'
                                            }
                                        </p>
                                    </div>
                                    <Seprator height={30} />
                                </>
                            }
                            <div>
                                <Icons.Profile />
                                <p onClick={goToProfile}>My Profile</p>
                            </div>
                            <Seprator height={30} />
                            <div>
                                <Icons.Settings width={15} height={15} color={'#2a2a2a'} />
                                <p onClick={goToAccountSettings}>Account Settings</p>
                            </div>
                            <Seprator height={30} />
                            {
                                (user.user_type === 'buyer' || user.user_type === 'seller') &&
                                <>
                                    <div>
                                        <Icons.Document width={15} height={15} />
                                        <p onClick={goToBuyerDocuments}>My Documents</p>
                                    </div>
                                    <Seprator height={30} />
                                    <div>
                                        <Icons.Contact />
                                        <p onClick={goToContacts}>My Connections</p>
                                    </div>
                                    <Seprator height={30} />
                                </>
                            }
                            {
                                user.user_type === 'agent' &&
                                <>
                                    <div>
                                        <Icons.Teams width={15} height={15} />
                                        <p onClick={goToTeams}>My Teams</p>
                                    </div>
                                    <Seprator height={30} />
                                </>
                            }
                            <div>
                                <Icons.Logout />
                                <p onClick={logout}>Log Out</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Seprator height={80} />
            <div onClick={() => setModal(false)}>
                <Outlet />
            </div>
        </>
    )
}


export default HeaderNavigation;