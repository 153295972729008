import React, { useEffect, Suspense, lazy } from 'react';
import { Routes, Route } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from 'react-redux'
import actions from 'store/actions';
// Packages
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Routes
import { ConfirmTemporaryPassword, ForgotPassword, Login } from "routes/Authentication"
import { ManageShowings, ManageListings, Dashboard, Inquiries, Contacts } from "routes/AgentDashboard"
import { MortgageDashboard, MortgageContacts, MortgageConnections } from "routes/MortgageDashboard"
import { LoginToRequest, ConfirmProperty, SelectAgent, ChooseAgent, InviteAgent, ShowingBeeAgent, RequestReview, Calendar, SelectBuyer, InviteBuyer, RequestSubmitted } from 'routes/ShowingRequest';
// import { ListingDetail, ListingForm, AddListing, ListingImages } from "routes/Listing"
import { BeeBrokers, PublicCatalog, TierList, UpgradeForm } from 'routes/BeeTier';
import { AgentContacts, AllContacts } from 'routes/BuyerContacts';
import { ApproveShowing, Confirm, SetPassword, Verify, SetAccount, ReActivateAccount, AccountDeleted, RequestSubmitted as RequestSubmittedResponse } from 'routes/Response';
// import { ManageListingCalendar } from 'routes/Calendar';
import HeaderNavigation from 'components/HeaderNavigation';
import SideBar from 'components/SideBar';
import HomeContainer from 'components/HomeContainer';
import SuspenseLoader from 'components/SuspenseLoader';
import TeamDetails from 'routes/TeamDetails';

// import SavedListings from 'routes/SavedListings';
// import ListingDocuments from 'routes/Listing/modules/ListingDocuments';
// import ListingImagesForm from 'routes/Listing/modules/ListingImagesForm';
// import NotFound from "routes/NotFound";
// import Homepage from "routes/Homepage";
// import WaggleFeed from 'routes/WaggleFeed';
// import SearchListing from 'routes/SearchListing';
// import Profile from 'routes/Profile';
// import FeaturedBroker from 'routes/FeaturedBroker';
// import BrokerContacts from 'routes/BuyerContacts/modules/BrokerContacts';
// import ForAgent from 'routes/ForAgent';
// import ShowingFormCalendar from 'routes/Calendar/modules/ShowingFormCalendar';
// import PublicShowingFormCalendar from 'routes/Calendar/modules/PublicShowingFormCalendar';
// import MainCalendar from 'routes/Calendar/modules/MainCalendar';
// import AccountSettings from 'routes/AccountSettings';


// DISCLOSURE AND PRIVACY MODULES
import { CookiePolicy, PrivacyCenter, TermsOfUse } from 'routes/DisclosurePages';
import PageLoading from 'routes/Response/modules/PageLoading';
import Messages from 'routes/Messages';
import ShowingRequestSubmitted from 'routes/Response/modules/ShowingRequestSubmitted';
import InvestorPage from 'routes/InvestorPage';
import MLSFooterPage from 'routes/MLSFooterPage';
import ShowingFeedbackForm from 'routes/Response/modules/ShowingFeedbackForm';
import ConfirmFeedback from 'routes/Response/modules/ConfirmFeedback';
import ShowingFeeback from 'routes/ShowingFeeback';
import AccountDeletionForm from 'routes/Response/modules/AccountDeletionForm';
import ReShowingFormCalendar from 'routes/Calendar/modules/ReShowingFormCalendar';
// import PublicShowingInquiryCalendar from 'routes/Calendar/modules/PublicShowingInquiryCalendar';
// import BuyerDocuments from 'routes/BuyerDocuments';


const NotFound = lazy(() => import('routes/NotFound'))
const Homepage = lazy(() => import('routes/Homepage'))
const WaggleFeed = lazy(() => import('routes/WaggleFeed'))
const SearchListing = lazy(() => import('routes/SearchListing'))
const Profile = lazy(() => import('routes/Profile'))
const FeaturedBroker = lazy(() => import('routes/FeaturedBroker'))
const BrokerContacts = lazy(() => import('routes/BuyerContacts/modules/BrokerContacts'))
const ForAgent = lazy(() => import('routes/ForAgent'))
const BuyerShowings = lazy(() => import('routes/BuyerShowings'))
const BuyerSellerListings = lazy(() => import('routes/BuyerSellerListings'))
const Signup = lazy(() => import('routes/Authentication/modules/Signup'))

// CALENDARS MODULES
const ShowingFormCalendar = lazy(() => import('routes/Calendar/modules/ShowingFormCalendar'))
const PublicShowingFormCalendar = lazy(() => import('routes/Calendar/modules/PublicShowingFormCalendar'))
const PublicShowingInquiryCalendar = lazy(() => import('routes/Calendar/modules/PublicShowingInquiryCalendar'))
const MainCalendar = lazy(() => import('routes/Calendar/modules/MainCalendar'))
const ManageListingCalendar = lazy(() => import('routes/Calendar/modules/ManageListingCalendar'))
const PluginShowingFormCalendar = lazy(() => import('routes/Calendar/modules/PluginShowingFormCalendar'))

const AccountSettings = lazy(() => import('routes/AccountSettings'))

// LISTING MODULES
const ListingDetail = lazy(() => import('routes/Listing/modules/ListingDetail'))
const ListingForm = lazy(() => import('routes/Listing/modules/ListingForm'))
const AddListing = lazy(() => import('routes/Listing/modules/AddListing'))
const ListingImages = lazy(() => import('routes/Listing/modules/ListingImages'))
const ListingImagesForm = lazy(() => import('routes/Listing/modules/ListingImagesForm'))
const ListingDocuments = lazy(() => import('routes/Listing/modules/ListingDocuments'))
const BuyerDocuments = lazy(() => import('routes/BuyerDocuments'))
const Teams = lazy(() => import('routes/Teams'))

const App = () => {
	const { token, user } = useSelector((state) => state.auth)
	const dispatch = useDispatch()

	useEffect(() => {
		const key = token || localStorage.getItem('token')
		if (key) {
			dispatch(actions.auth.retrieveUser())
		}
	}, [dispatch, token])

	return (
		<>
			<Suspense fallback={<SuspenseLoader />}>
				<Routes>
					<Route path="*" element={<NotFound />} />
					<Route path="/verify/" element={<Verify />} />
					<Route path="/set-password/" element={<SetPassword />} />
					<Route path="/set-account/" element={<SetAccount />} />
					<Route path="/confirm/" element={<Confirm />} />
					<Route path="/approve-showing/" element={<ApproveShowing />} />
					<Route path="/for-agents/:mls/:id" element={<ForAgent />} />
					<Route path="/request-plugin/submitted" element={<RequestSubmittedResponse />} />
					<Route path="/for-agents/request/submitted" element={<ShowingRequestSubmitted />} />
					<Route path="/listing/:mls/:id/request-calendar" element={<PluginShowingFormCalendar />} />
					<Route path="/terms-of-use/" element={<TermsOfUse />} />
					<Route path="/privacy-center/" element={<PrivacyCenter />} />
					<Route path="/cookie-policy/" element={<CookiePolicy />} />
					<Route path="/account-deleted/" element={<AccountDeleted />} />
					<Route path="/showing-request/:mls/:id/calendar" element={<PublicShowingInquiryCalendar />} />
					<Route path="/showing-feedback/" element={<ShowingFeedbackForm />} />
					<Route path="/showing-feedback/sent/" element={<ConfirmFeedback />} />
					<Route path="/request-account-deletion/" element={<AccountDeletionForm />} />
					{/* <Route path="/investors/" element={<InvestorPage />} /> */}
					{
						token || localStorage.getItem('token')
							?
							<>
								{
									user.id ?
										<>
											{
												user.is_deactivated
													? <Route path="/" element={<ReActivateAccount />} />
													:
													<>
														<Route path="/" element={<HeaderNavigation />}>
															<Route path="/investors/" element={<InvestorPage />} />
															<Route path="/mls/" element={<MLSFooterPage />} />
															<Route path="/listings/" element={<SearchListing />} />
															<Route path="/listings/:mls/:id" element={<ListingDetail />} />
															<Route path="/listings/:mls/:id/gallery" element={<ListingImages />} />
															<Route path="/me" element={<Profile />} />
															<Route path="/me/settings" element={<AccountSettings />} />
															<Route path="/bee-tier" element={<TierList />} />
															<Route path="/bee-tier/upgrade/:tier" element={<UpgradeForm />} />
															<Route path="/bee-tier/brokers/:tier" element={<BeeBrokers />} />
															<Route path="/request/:mls/:id" element={<ConfirmProperty />} />
															<Route path="/request/:mls/:id/select-agent" element={<SelectAgent />} />
															<Route path="/request/:mls/:id/select-buyer" element={<SelectBuyer />} />
															<Route path="/request/:mls/:id/choose-agent" element={<ChooseAgent />} />
															<Route path="/request/:mls/:id/invite-agent" element={<InviteAgent />} />
															<Route path="/request/:mls/:id/invite-buyer" element={<InviteBuyer />} />
															<Route path="/request/:mls/:id/showingbee-agent" element={<ShowingBeeAgent />} />
															<Route path="/request/:mls/:id/calendar" element={<Calendar />} />
															<Route path="/request/:mls/:id/review" element={<RequestReview />} />
															<Route path="/request/submitted" element={<RequestSubmitted />} />
															<Route path="/for-agents/:mls/:id/calendar" element={<PublicShowingFormCalendar />} />
															<Route path="/main-calendar/" element={<MainCalendar />} />
															<Route path="/me/property-listing" element={<BuyerSellerListings />} />
															<Route path="/me/documents" element={<BuyerDocuments />} />
															<Route path="/messages" element={<Messages />} />
															{
																(user.user_type === 'buyer' || user.user_type === 'seller') &&
																<Route path="/" element={<WaggleFeed />} />
															}
															{
																user.user_type === 'agent' &&
																<>
																	<Route path="/" element={<SideBar />}>
																		<Route path="/" element={<Dashboard />} />
																		<Route path="/manage-listings" element={<ManageListings />} />
																		<Route path="/manage-showings" element={<ManageShowings />} />
																		<Route path="/inquiries" element={<Inquiries />} />
																		<Route path="/contacts" element={<Contacts />} />
																	</Route>
																	<Route path="/manage-listings/:id/update" element={<ListingForm />} />
																	<Route path="/manage-listings/create" element={<AddListing />} />
																	<Route path="/manage-listings/:id/calendar" element={<ManageListingCalendar />} />
																	<Route path="/manage-listings/:id/showing-form" element={<ShowingFormCalendar />} />
																	<Route path="/manage-listings/:id/images" element={<ListingImagesForm />} />
																	<Route path="/manage-listings/:id/documents" element={<ListingDocuments />} />
																	<Route path="/manage-listings/:id/feedbacks" element={<ShowingFeeback />} />
																	<Route path="/manage-showings/re-showing" element={<ReShowingFormCalendar />} />
																	<Route path="/teams/" element={<Teams />} />
																	<Route path="/teams/:id/" element={<TeamDetails />} />
																	<Route path="/waggle" element={<WaggleFeed />} />
																</>
															}

															{
																user.user_type === 'mortgage_broker' &&
																<>
																	<Route path="/" element={<SideBar />}>
																		<Route path="/" element={<MortgageDashboard />} />
																		{/* <Route path="/dashboard/contacts" element={<MortgageContacts />} /> */}
																		<Route path="/connections" element={<MortgageConnections />} />
																	</Route>
																	<Route path="/featured/mortgage-broker" element={<FeaturedBroker />} />
																	<Route path="/waggle" element={<WaggleFeed />} />
																</>
															}

															{
																(user.user_type !== 'mortgage_broker' || user.user_type !== 'agent') &&
																<>
																	<Route path="/contacts" element={<AllContacts />} />
																	<Route path="/listings/:id/update" element={<ListingForm />} />
																	<Route path="/showings" element={<BuyerShowings />} />
																	<Route path="/contacts/agents" element={<AgentContacts />} />
																	<Route path="/contacts/mortgage-brokers" element={<BrokerContacts />} />
																</>
															}
														</Route>
														<Route path="/home" element={<HomeContainer />}>
															<Route path="/home" element={<Homepage />} />
														</Route>
													</>
											}
										</>
										: <Route path="/" element={<PageLoading />} />
								}
							</>
							:
							<>
								<Route path="/" element={<HomeContainer />}>
									<Route path="/" element={<Homepage />} />
									<Route path="/listings/:mls/:id" element={<ListingDetail />} />
									<Route path="/listings/:mls/:id/gallery" element={<ListingImages />} />
								</Route>
								<Route path="/investors/" element={<InvestorPage />} />
								<Route path="/mls/" element={<MLSFooterPage />} />
								<Route path="/bee-tier" element={<PublicCatalog />} />
								<Route path="/listings/" element={<SearchListing />} />
								<Route path="/login" element={<Login />} />
								<Route path="/signup" element={<Signup />} />
								<Route path="/forgot-password" element={<ForgotPassword />} />
								<Route path="/confirm-temporary-password" element={<ConfirmTemporaryPassword />} />
								<Route path="/request/:mls/:id" element={<LoginToRequest />} />
								<Route path="/for-agents/:mls/:id/calendar" element={<PublicShowingFormCalendar />} />
							</>
					}
				</Routes>
			</Suspense>
			<ToastContainer />
		</>
	);
}


export default App;
