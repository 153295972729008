import Section from "components/Section";
import Seprator from "components/Separator";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
import { checkImageUrl } from "utils/modules/Handlers";
import './inquiries.scss'
import house1 from 'assets/images/house1.jpg'
import { Link } from "react-router-dom";
import { Config } from "utils";
import NoUser from 'assets/images/no_user.jpg'
import DeleteInquiryModal from "./modules/DeleteInquiryModal";
import EmailReplyModal from "./modules/EmailReplyModal";
import MessageReplyModal from "./modules/MessageReplyModal";
import Icons from "components/Icons";

const Inquiries = () => {
    const dispatch = useDispatch();
    const { inquiries: { data, next, previous, total } } = useSelector((state) => state.buyer)
    const { user } = useSelector((state) => state.auth)
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedData, setSelectedData] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [emailReplyModal, setEmailReplyModal] = useState(false);
    const [chatReplyModal, setChatReplyModal] = useState(false);
    const [type, setType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const fetchData = async() => {
        try {
            setIsLoading(true);
            await dispatch(actions.buyer.fetchBuyerInquiries(user.agent_profile.id)).then(() => {
                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        const init = async() => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total/5)||1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    name: i,
                    value: `${Config.API_URL}/buyer-inquiry/?limit=5&ordering=-date_added&listing__agent=${user.agent_profile.id}&offset=${offset}`
                })
                offset += 5
            }
            setPages(pageLinks)
        }
        init();
    }, [total])

    const loadPageData = async({ page, pageNumber }) => {
        await dispatch(actions.buyer.fetchBuyerInquiriesPagination(page));
        setPage(pageNumber);
    }

    const onDelete = (item) => {
        setType('delete')
        setSelectedData(item)
        setDeleteModal(prev => !prev)
    }

    const onEmailReply = (item) => {
        setType('email')
        setSelectedData(item)
        setEmailReplyModal(prev => !prev)
    }

    const onChatReply = (item) => {
        setType('chat')
        setSelectedData(item)
        setChatReplyModal(prev => !prev)
    }


    return (
        <Section className="inquiries-container" style={{ minHeight: '91.5vh' }}>
            <DeleteInquiryModal modal={deleteModal} setModal={setDeleteModal} selectedData={selectedData} fetchData={fetchData} />
            <EmailReplyModal modal={emailReplyModal} setModal={setEmailReplyModal} selectedData={selectedData} fetchData={fetchData} />
            <MessageReplyModal modal={chatReplyModal} setModal={setChatReplyModal} selectedData={selectedData} fetchData={fetchData} type={type} />
            
            <h1 className="header">Inquiries</h1>
            <div className="table-container">
                {
                    isLoading &&
                    <div className="loader">
                        <Icons.Loading background={'none'} height={100} width={100} />
                    </div>
                }
                <table cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th width="65px"></th>
                            <th width="385px"><div>Listing</div></th>
                            <th width="210px"><div>Requester</div></th>
                            <th width="210px"><div>Inquiry Type</div></th>
                            <th width="385px"><div>Message</div></th>
                            <th width="100px"><div>Status</div></th>
                            <th width="146px"><div>Date</div></th>
                            <th width="210px"><div>Action</div></th>
                        </tr>
                    </thead>
                    <tbody id="showings-table">
                        {
                            data.length > 0 ?
                            data.map((inquiry, index) => {
                                return (
                                    <tr className="body" key={index}>
                                        <td data-label="Select" style={{ textAlign: 'center' }}></td>
                                        <td className="listing">
                                            <img className="item-image" src={checkImageUrl(inquiry.listing.primary_photo_url, house1)} alt="" />
                                            <Seprator width={25} />
                                            <p className="address">
                                                <Link to={`/listings/${inquiry.listing.mls_source}/${inquiry.listing.mls_number || inquiry.listing.id}/`} target="_blank">
                                                    {
                                                        inquiry.listing.mls_number 
                                                        ? <>{inquiry.listing.address}</>
                                                        : <>{`${inquiry.listing.address}, ${inquiry.listing.city} ${inquiry.listing.state} ${inquiry.listing.zip_code}`}</>
                                                    }   
                                                </Link>
                                            </p>
                                        </td>
                                        <td align="center">
                                            {
                                                inquiry.inquirer ?
                                                <div className="inquirer-details">
                                                    <img src={checkImageUrl(inquiry.inquirer?.profile_photo, NoUser)} alt="" />
                                                    <p>{`${inquiry.inquirer?.first_name} ${inquiry.inquirer?.last_name}`}</p>
                                                    <span>({inquiry.email})</span>
                                                </div>
                                                : <div className="inquirer-details">
                                                    <p>{`${inquiry.first_name} ${inquiry.last_name}`}</p>
                                                    <span>({inquiry.email})</span>
                                                </div>
                                            }
                                        </td>
                                        <td align="center">
                                            <p className={`type type_${inquiry.inquiry_type}`}>{inquiry.inquiry_type}</p>
                                            {
                                                inquiry.inquiry_type === 'showing' &&
                                                <p className="showing-date">({moment(inquiry.showing_date, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD/YYYY hh:mm A')})</p>
                                            }
                                        </td>
                                        <td align="center">
                                            <p className="message">{inquiry.message}</p>
                                        </td>
                                        <td align="center">
                                            <p className={`status ${inquiry.is_resolved ? 'resolved' : inquiry.date_notified ? 'notified' : 'pending'}`}>
                                                {inquiry.is_resolved ? 'Resolved' : inquiry.date_notified ? 'Notified' : 'Pending'}
                                                {
                                                   (inquiry.date_notified && !inquiry.is_resolved) &&
                                                    <span className="tooltip" data-text="The inquirer has been notified regarding the inquiry because it's been 12 hours since it was made. Please respond to the inquiry immediately." >
                                                        <Icons.Tooltip height={15} width={15} />
                                                    </span>
                                                }
                                            </p>
                                        </td>
                                        <td align="center">
                                            <p>{moment(inquiry.date_added).format('MM/DD/YYYY HH:mm:ss')}</p>
                                        </td>
                                        <td align="center">
                                            {
                                                !inquiry.is_resolved &&
                                                <div className="action-block">
                                                    {
                                                        inquiry.inquirer &&
                                                        <p className="action-button approve" onClick={() => onChatReply(inquiry)}>Reply by Message</p>
                                                    }
                                                    <p className="action-button approve" onClick={() => onEmailReply(inquiry)}>Reply by Email</p>
                                                    <p className="action-button reject" onClick={() => onDelete(inquiry)}>Delete</p>
                                                </div>
                                            }
                                        </td>
                                        
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan="8">
                                    <div className="empty-table">
                                        {
                                            !isLoading &&
                                            'No Available Inquiries'
                                        }
                                    </div>
                                </td>
                            </tr> 
                        }
                    </tbody>
                </table>
            </div>
            {
                data.length > 0 &&
                <div className="pagination">
                    <p className="previous" onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}> { previous && 'Previous' } </p>
                    <ul> 
                        {
                            pages.map((item, index) => {
                                return(
                                    <li 
                                        key={index}
                                        className={`${item.name === page && 'active'}`}
                                        onClick={() => loadPageData({ page: item.value, pageNumber: item.name })}
                                    >
                                        {item.name}
                                    </li>)
                            })
                        }
                    </ul>
                    <p className="next" onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}> { next && 'Next' } </p>
                </div>
            }
        </Section>
    )
}


export default Inquiries;