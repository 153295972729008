// Components
import Separator from 'components/Separator';
// Assets
import './inviteBuyerAgentForm.scss'

const InviteBuyerAgentForm = ({ onChangeInput, form, setActive, setSkip }) => {
    const skipInvite = () => {
        setSkip(true);
        setActive('account');
    }
    return (
        <div className="invite-agent-container">
            <div className='agent-invite-header'>
                <h1 className='header'>Do you already have an agent?</h1>
                <Separator height={13} />
                <p className='subheader'>Enter you agent's email and we will send them an invite.</p>
            </div>
            <Separator height={20} />
            {/* <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <input 
                        type="text" 
                        placeholder='First Name' 
                        onChange={(event) => onChangeInput({event, type: "first_name"})}
                    />
                    <span className='error-message'>{form.first_name.error}</span>
                </div>
                <Separator width={20} height={20} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <input 
                        type="text" 
                        placeholder='Last Name' 
                        onChange={(event) => onChangeInput({event, type: "last_name"})} 
                    />
                    <span className='error-message'>{form.last_name.error}</span>
                </div>
            </div>
            <Separator height={20} /> */}
            <input 
                type="email" 
                placeholder='Email Address' 
                onChange={(event) => onChangeInput({event, type: "email"})} 
            />
            <Separator height={30} />
            <div style={{ display: 'flex' }}>
                <button className='button outline' onClick={skipInvite}>Skip</button>
                <Separator width={20} height={10} />
                <button className='button primary' onClick={() => setActive('account')}>Next</button>
            </div>
        </div>
    )
}


export default InviteBuyerAgentForm;