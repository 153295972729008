import Section from 'components/Section'
import Seprator from 'components/Separator'
import './dashboard.scss'
import { useEffect, useState } from 'react'
import { CountUp } from 'countup.js'
import actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import house1 from 'assets/images/house1.jpg'
import { checkImageUrl } from 'utils/modules/Handlers'
import Icons from 'components/Icons'
import { Link, useNavigate } from 'react-router-dom'

const AgentDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth)
    const [showingToday, setShowingToday] = useState(null);
    const [enabledShowings, setEnabledShowings] = useState(null);
    const [activeShowings, setActiveShowings] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showingFilter, setShowingFilter] = useState('all');

    useEffect(() => {
        const init = async() => {
            setLoading(true);
            await dispatch(actions.agent.listingDashboardData()).then((data) => {
                const totalEl = document.getElementById('total')
                const enabledEl = document.getElementById('enabled')
                const activeEl = document.getElementById('active')
                const pendingEl = document.getElementById('pending')
                const leasedEl = document.getElementById('leased')
                const soldEl = document.getElementById('sold')

                const total = new CountUp(totalEl, data.total);
                const enabled = new CountUp(enabledEl, data.enabled_showing);
                const active = new CountUp(activeEl, data.active);
                const pending = new CountUp(pendingEl, data.pending);
                const leased = new CountUp(leasedEl, data.leased);
                const sold = new CountUp(soldEl, data.sold);

                total.start();
                enabled.start();
                active.start();
                pending.start();
                leased.start();
                sold.start();
            }) 
            await dispatch(actions.agent.showingDashboardData()).then((data) => {
                const totalEl = document.getElementById('showings-total')
                const activeEl = document.getElementById('showings-active')
                const pendingEl = document.getElementById('showings-pending')

                const total = new CountUp(totalEl, data.total);
                const active = new CountUp(activeEl, data.active);
                const pending = new CountUp(pendingEl, data.pending);

                total.start();
                active.start();
                pending.start();
            })

            await dispatch(actions.agent.inquiryDashboardData()).then((data) => {
                const totalEl = document.getElementById('inquiries-total')
                const usersEl = document.getElementById('inquiries-users')
                const pendingEl = document.getElementById('inquiries-pending')
                const notifiedEl = document.getElementById('notified-pending')

                const total = new CountUp(totalEl, data.total);
                const users = new CountUp(usersEl, data.sb_user);
                const pending = new CountUp(pendingEl, data.pending);
                const notified = new CountUp(notifiedEl, data.notified);

                total.start();
                users.start();
                pending.start();
                notified.start();
            })

            await dispatch(actions.agent.showingsToday()).then((data) => {
                setShowingToday(data);
            });

            await dispatch(actions.agent.enabledListings()).then((data) => {
                setEnabledShowings(data.results);
            });

            await dispatch(actions.agent.approvedShowings({ filters: `agent_type=all` })).then((data) => {
                setActiveShowings(data.results);
            });
            await setLoading(false);
        }

        init();
    }, [])

    const formatTime = (data) => {
        const startDate = moment(new Date(data.start)).format('ddd MMMM DD');
        return startDate
    }

    const handleShowingFilter = async(type) => {
        await dispatch(actions.agent.approvedShowings({ filters: `agent_type=${type}` })).then((data) => {
            setActiveShowings(data.results);
            setShowingFilter(type);
        });
    }

    const handleGoToForm = (item, event) => {
        event.preventDefault();
        navigate(`/manage-listings/${item.id}/calendar`)
    }
    const handleGoToCalendar = (item, event) => {
        event.preventDefault();
        navigate(`/manage-listings/${item.id}/showing-form`)
    }

    return (
        <>
            <Section className='agent-dashboard-container'>
                <div id="content"  className='top-info-container'>
                    <div className='top-left'>
                        <div className='block-header'>
                            <p>My Listings</p>
                        </div>
                        <Seprator height={30} />
                        <div className='block-info'>
                            <div className='number-blocks'>
                                <p>Total</p>
                                <span id='total'>0</span>
                            </div>
                            <Seprator width={100} />
                            <div className='number-blocks'>
                                <p>Enabled Showing</p>
                                <span id='enabled'>0</span>
                            </div>
                            <Seprator width={100} />
                            <div className='number-blocks'>
                                <p>Active</p>
                                <span id='active'>0</span>
                            </div>
                            <Seprator width={100} />
                            <div className='number-blocks'>
                                <p>Pending</p>
                                <span id='pending'>0</span>
                            </div>
                            <Seprator width={100} />
                            <div className='number-blocks'>
                                <p>Leased</p>
                                <span id='leased'>0</span>
                            </div>  
                            <Seprator width={100} />
                            <div className='number-blocks'>
                                <p>Sold</p>
                                <span id='sold'>0</span>
                            </div>   
                        </div>
                    </div>
                    <div className='top-right'>
                        <div className='block-header'>
                            <p>Showing Today</p>
                        </div>
                        <Seprator height={30} />
                        {
                            !loading
                            ? <div className='block-info'>
                                {
                                    (showingToday && showingToday.length > 0) 
                                    ? <div className='showing-block'>
                                        <img src={checkImageUrl(showingToday[0].listing_first_image_url, house1)} alt='' />
                                        <div className='details'>
                                            <p className='name'>{showingToday[0].address}</p>
                                            <Seprator height={10} />
                                            <p className='address'>
                                                <p className={`status ${showingToday[0].status_data.color}`}>{ showingToday[0].status }</p>
                                            </p>
                                            <div className="time">
                                                <p>{formatTime(showingToday[0])}</p>
                                                <Seprator width={15} />
                                                <p>{showingToday[0].start_time} - {showingToday[0].finish_time}</p>
                                            </div>
                                        </div>
                                    </div>
                                    : <p className='no-showings'>No showing for today.</p>
                                }
                            </div>
                            : <div className='loading' style={{ width: '100%' }}>
                                <Icons.Loading height={60} />
                            </div>
                        }
                    </div>
                </div>
                <div className="bottom-info-container">
                    <div className="bottom-left-1">
                        <div className='block-header'>
                            <p>My Showings</p>
                        </div>
                        <Seprator height={30} />
                        <div className='block-info'>
                            <div className='number-blocks'>
                                <p>Total</p>
                                <span id='showings-total'>0</span>
                            </div>
                            <Seprator height={20} />
                            <div className='number-blocks'>
                                <p>Approved Showings</p>
                                <span id='showings-active'>0</span>
                            </div>
                            <Seprator height={20} />
                            <div className='number-blocks'>
                                <p>Pending Showings</p>
                                <span id='showings-pending'>0</span>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-left-2">
                        <div className='block-header'>
                            <p>Inquiries</p>
                            <Seprator height={30} />
                        </div>
                        <div className='block-info'>
                            <div className='number-blocks'>
                                <p>Total</p>
                                <span id='inquiries-total'>0</span>
                            </div>
                            <Seprator height={20} />
                            <div className='number-blocks'>
                                <p>SB User Inquiries</p>
                                <span id='inquiries-users'>0</span>
                            </div>
                            <Seprator height={20} />
                            <div className='number-blocks'>
                                <p>Pending Inquiries</p>
                                <span id='inquiries-pending'>0</span>
                            </div>
                            <Seprator height={20} />
                            <div className='number-blocks'>
                                <p>Notified Inquiries</p>
                                <span id='notified-pending'>0</span>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-middle">
                        <div className='block-header'>
                            <p>Enabled Listings</p>
                        </div>
                        <Seprator height={20} />
                        {
                            !loading
                            ? <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                                {
                                    (enabledShowings && enabledShowings.length > 0) 
                                    ? <>
                                        {
                                            enabledShowings.map((item, index) => {
                                                return (
                                                    <Link to={`/listings/${item.mls_source}/${item.mls_number || item.id}`} target="_blank">
                                                        <div key={index} className='block-info listing'>
                                                            <div className='image-name'>
                                                                <img src={checkImageUrl(item.primary_photo_url, house1)} alt='' />
                                                                <div>
                                                                    <p className='name'>{item.property_type_display} in {item.city}</p>
                                                                    <p className='mls'>#{item.mls_number || item.id}</p>
                                                                </div>
                                                            </div>
                                                            <p className='address'>
                                                                {
                                                                    item.mls_number 
                                                                    ? <>{item.full_address}</>
                                                                    : <>{`${item.address}, ${item.city} ${item.state} ${item.zip_code}`}</>
                                                                }
                                                            </p>    
                                                            <div style={{ display: 'flex', columnGap: 10 }}>
                                                                <Icons.Calendar height={15} width={15} color={'#2a2a2a'} onClick={(event) => handleGoToForm(item, event)} />
                                                                <Icons.ShowingForm height={15} width={15} color={'#2a2a2a'} onClick={(event) => handleGoToCalendar(item, event)} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </>
                                    : <p className='no-listings'>No listings available.</p>
                                }
                            </div>
                            : <div className='loading'>
                                <Icons.Loading />
                            </div>
                        }
                    </div>
                    <div className="bottom-right">
                        <div className='block-header'>
                            <p>Approved Showings</p>
                            <div className='showings-filter'>
                                <p className={`all ${showingFilter === 'all' && 'active'}`} onClick={() => handleShowingFilter('all')}>ALL</p>
                                <p className={`listing ${showingFilter === 'listing' && 'active'}`} onClick={() => handleShowingFilter('listing')}>LA</p>
                                <p className={`buyer ${showingFilter === 'buyer' && 'active'}`} onClick={() => handleShowingFilter('buyer')}>BA</p>
                                <p className={`member ${showingFilter === 'member' && 'active'}`} onClick={() => handleShowingFilter('member')}>TM</p>
                            </div>
                        </div>
                        <Seprator height={30} />
                        {
                            !loading 
                            ? <div className='block-info'>
                                {
                                    (activeShowings && activeShowings.length > 0) 
                                    ? 
                                    <>
                                        {
                                            activeShowings.map((item, index) => {
                                                return (
                                                    <div key={index} style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', rowGap: 5 }}>
                                                            {
                                                                item.buyers_data[0]?.buyer_tier === 'Q' &&
                                                                <Icons.QueenBeeTier height={15} width={15} />
                                                            }
                                                            {
                                                                item.listing_data?.agent_members.includes(user?.id) && <Icons.TeamMemberIndicator height={15} width={15} />
                                                            }
                                                            {
                                                                item.buyer_agent === user?.id && <Icons.BuyerAgentIndicator height={15} width={15} />
                                                            }
                                                            {
                                                                item.listing_agent === user?.agent_profile?.id && <Icons.ListingAgentIndicator height={15} width={15} />
                                                            }
                                                        </div>
                                                        <div className='showing-block'>
                                                            <img src={checkImageUrl(item.listing_data.featured_image.image_url, house1)} alt='' />
                                                            <div className='details'>
                                                                <p className='name'>{item.listing_data.property_type} in {item.listing_data.city}</p>
                                                                <Seprator height={10} />
                                                                <p className='address'>
                                                                    {
                                                                        item.listing_data.mls_number 
                                                                        ? <>{item.listing_data.address}</>
                                                                        : <>{`${item.listing_data.address}, ${item.listing_data.city} ${item.listing_data.state} ${item.listing_data.zip_code}`}</>
                                                                    }
                                                                </p>
                                                                <div className="time">
                                                                    <p style={{ margin: 0, paddingTop: 10 }}>{formatTime(item)}</p>
                                                                    <Seprator width={15} />
                                                                    <p style={{ margin: 0, paddingTop: 10 }}>{item.start_time} - {item.finish_time}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Seprator height={30} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    : <p className='no-showings'>No showings available.</p>
                                }
                            </div>
                            :<div className='loading'>
                                <Icons.Loading />
                            </div>
                        }
                    </div>
                </div>
            </Section>
            <div style={{ background: '#F4F5F6', height: 30, width: '100%' }}></div>
        </>
    )
}


export default AgentDashboard;