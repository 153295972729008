import Icons from 'components/Icons';
import Seprator from 'components/Separator';
import './statusBlock.scss'

const StatusBlock = ({ data }) => {
    return (
        <div className="status-block-container">
            {
                (data.status_data.message && data.status_data.message !== 'Waiting for Buyer\'s Agent approval.') &&
                <>
                    <div className={`status-label`}>{data.status_data.message}</div>
                    <Seprator height={15} />
                </>
            }
            {(data.status_display === 'Cancelled' || data.status_display === 'Rejected') && (
                <div>Reason: {data.cancellation || data.buyer_agent_cancellation || data.listing_agent_cancellation || data.seller_cancellation}</div>
            )}
            {(data.status_display !== 'Rejected' && data.status_display !== 'Cancelled') && (
                <>
                    {
                        (data.listing_data.showing_approval_type === 'auto' && data.status === 'upcoming')
                        ? <div className='status-checks'>
                            <div>
                                <span className='approved'>BA</span>
                                <Seprator width={5} />
                                <Icons.Approve />
                            </div>
                            <Seprator width={15} />
                            <div>
                                <span className='approved'>LA</span>
                                <Seprator width={5} />
                                <Icons.Approve />
                            </div>
                            <Seprator width={15} />
                            <div>
                                <span className='approved'>S</span>
                                <Seprator width={5} />
                                <Icons.Approve />
                            </div>
                        </div>
                        :  <div className='status-checks'>
                                <div>
                                    { data.buyer_agent ? (
                                        data.buyer_agent_approved ?
                                            <>
                                                <span className='approved'>BA</span>
                                                <Seprator width={5} />
                                                <Icons.Approve />
                                            </>
                                            : <>
                                                <span className='waiting'>BA</span>
                                                <Seprator width={5} />
                                                <Icons.Ellipsis color={'#707070'} />
                                            </>
                                    ) :
                                        <>
                                            <span className='not-required'>BA</span>
                                            <Seprator width={5} />
                                            <Icons.Pending color={'#BEBEBE'} />
                                        </>

                                    }
                            </div>
                        <Seprator width={15} />
                        <div>
                            {(data.listing_data.showing_approval_type === 'both' || data.listing_data.showing_approval_type === 'la_only') ? (
                                (data.state === 'ShowingState.APPROVED' || data.state === 'ShowingState.SELLER_AGENT_APPROVED') ? (
                                    <>
                                        <span className='approved'>LA</span>
                                        <Seprator width={5} />
                                        <Icons.Approve />
                                    </>
                                ) : <>
                                    <span className='waiting'>LA</span>
                                    <Seprator width={5} />
                                    <Icons.Ellipsis color={'#707070'} />
                                </>
                            ) : <>
                                <span className='not-required'>LA</span>
                                <Seprator width={5} />
                                <Icons.Pending color={'#BEBEBE'} />
                            </>
                            }
                        </div>
                        <Seprator width={15} />
                        <div>
                            {(data.listing_data.showing_approval_type === 'both' || data.listing_data.showing_approval_type === 'owner_only') ? (
                                (data.state === 'ShowingState.APPROVED' || data.state === 'ShowingState.SELLER_CLIENT_APPROVED') ? (
                                    <>
                                        <span className='approved'>S</span>
                                        <Seprator width={5} />
                                        <Icons.Approve />
                                    </>
                                ) : <>
                                    <span className='waiting'>S</span>
                                    <Seprator width={5} />
                                    <Icons.Ellipsis color={'#707070'} />
                                </>
                            ) : <>
                                <span className='not-required'>S</span>
                                <Seprator width={5} />
                                <Icons.Pending color={'#BEBEBE'} />
                            </>
                            }
                        </div>
                    </div>
                    }
                </>
            )}
        </div>
    )
}


export default StatusBlock;