import { createSlice } from '@reduxjs/toolkit'

const agentSlice = createSlice({
  name: 'agent',
  initialState: {
    buyerAgents: [],
    featuredAgents: [],
    allAgents: [],
    connections: {
      data: [],
      total: 0,
      next: null,
      previous: null,
      offset: 0,
    },
    buyers: [],
    inquiries: [],
  },
  reducers: {
    fetchBuyerAgentsSuccess: (state, action) => {
      state.buyerAgents = action.payload;
    },
    fetchFeaturedAgentsSuccess: (state, action) => {
      state.featuredAgents = action.payload;
    },
    fetchallAgentsSuccess: (state, action) => {
      state.allAgents = action.payload;
    },
    fetchBuyerAgentConnectionSuccess: (state, action) => {
      state.connections.data = action.payload.results;
      state.connections.next = action.payload.next;
      state.connections.previous = action.payload.previous;
      state.connections.total = action.payload.count;
      state.connections.offset = 0;
    },
    fetchBuyersSuccess: (state, action) => {
      state.buyers = action.payload;
    },
    fetchInquiriesSuccess: (state, action) => {
      state.inquiries = action.payload.results;
    },
  },
});


export default agentSlice