import * as React from "react";

const Waggle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29.94}
    height={29.94}
    viewBox="0 0 29.94 29.94"
    {...props}
  >
    <g transform="translate(0)">
      <rect
        fill={props.color || '#808080'}
        width={29.94}
        height={29.94}
        rx={14.97}
        transform="translate(29.94) rotate(90)"
      />
      <rect
        fill="#FFFFFF"
        width={10}
        height={10}
        rx={5}
        transform="translate(7.802 14.949) rotate(-45)"
      />
      <path
        fill="#FFFFFF"
        d="M23.1,6.86,21.62,8.34c3.43,3.98,3.86,8.88.04,13.25l1.49,1.48A11.49,11.49,0,0,0,23.1,6.86Z"
      />
      <path
        fill="#FFFFFF"
        d="M6.84,6.86,8.32,8.34c-3.43,3.98-3.86,8.88-.04,13.25L6.79,23.07A11.49,11.49,0,0,1,6.84,6.86Z"
      />
    </g>
  </svg>
);


export default Waggle;
