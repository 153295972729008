import axios from "axios";
import fileDownload from 'js-file-download'
import Section from "components/Section";
import Seprator from "components/Separator";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
import { Config } from "utils";
import './connections.scss'
import ActionBlock from "./modules/ActionBlock";
import InviteConnections from "./modules/InviteConnection";
import UploadDocument from "./modules/UploadDocument";
import QuestionnaireForm from "./modules/QuestionnaireForm";
// import QuestionnairePreview from "./modules/QuestionnairePreview";
import { toast } from "react-toastify";
import ToastMessage from "components/ToastMessage";
import { checkImageUrl, getErrorMessage } from "utils/modules/Handlers";
import DeleteConnection from "./modules/DeleteConnection";
import moment from "moment";
import NoUser from 'assets/images/no_user.jpg'
import BuyerInfoModal from "./modules/BuyerInfoModal";
import debouce from 'lodash.debounce'
import QuestionnairePreview from "components/QuestionnairePreview";
import AmountEdit from "./modules/AmountEdit";
import NotifyBuyerModal from "./modules/NotifyBuyerModal";

const MortgageConnections = () => {
    const dispatch = useDispatch();
    const { mortgageConnections: { data, previous, next, total } } = useSelector((state) => state.buyer)
    const [pages, setPages] = useState([]);
    const [inviteModal, setInviteModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [questionnaireModal, setQuestionnaireModal] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [buyerModal, setBuyerModal] = useState(false);
    const [amountModal, setAmountModal] = useState(false);
    const [notifyModal, setNotifyModal] = useState(false);
    const [page, setPage] = useState(1);
    const [selectedData, setSelectedData] = useState({});
    const [selectedPage, setSelectedPage] = useState(null);
    const [filters, setFilters] = useState('')
    
    
    useEffect(() => {
        const init = async() => {
            await dispatch(actions.buyer.fetchBuyerMortgageConnection())
        }
        init();
    }, [])

    useEffect(() => {
        const init = async() => {
            let pageLinks = []
            let offset = 0
            const count = Math.ceil(total/20)||1
            for (let i = 1; i <= count; i++) {
                pageLinks.push({
                    name: i,
                    value: filters ? `${Config.API_URL}/buyer-broker-connection/search/?limit=20&offset=${offset}&ordering=-date_created&${filters}` : `${Config.API_URL}/buyer-broker-connection/?limit=20&offset=${offset}&ordering=-date_created`
                })
                offset = i * 20
            }
            setPages(pageLinks)
        }
        init();
    }, [total, filters])

    const loadPageData = async({ page, pageNumber }) => {
        await dispatch(actions.agent.fetchBuyerAgentConnectionPagination(page));
        setSelectedPage(page);
        setPage(pageNumber);
    }

    const checkStatus = (item) => {
        if (item.approval_document) {
            if (item.date_approved) {
                return <span style={{ color: '#02B946' }}>Approved</span>
            } else if (item.date_cancelled) {
                return <span style={{ color: '#BEBEBE' }}>Cancelled</span>
            } else if (item.date_rejected) {
                return <span style={{ color: 'rgb(235, 74, 74)' }}>Rejected</span>
            } else { return <span style={{ color: '#06C0CD' }}>Waiting for Approval</span> }
        } else {
            return <span style={{ color: '#06C0CD' }}>Upload Pre-Approval Letter</span>
        }
    }

    const handleDownload = (connection) => {
        axios.get(connection.approval_document, {
            responseType: 'blob',
        })
        .then((res) => {
            fileDownload(res.data, `${connection.buyer.first_name} ${connection.buyer.last_name} - Approval Document.${res.data.type.split('/')[1]}`)
        })
    }

    const updateBuyerTier = async(item, tier) => {
        const data = { id: item.id, buyer_tier: tier}
        try {
            if (tier === 'Q' && !item.questionnaire_filled_out) {
                await setSelectedData(item);
                toast.info('Questionnaire must be filled out before changing to Queen Bee', {
                    position: toast.POSITION.TOP_CENTER
                });
                setQuestionnaireModal((prev) => !prev);
            } else {
                await dispatch(actions.broker.updateBuyerTier(data)).then(async() => {
                    await refetchConnectionData();
                    toast.success('Buyer tier has been updated', {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
            }
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const onSelectEntry = (item) => {
        setSelectedData(item)
        setUploadModal(prev => !prev)
    }

    const onSelectBuyer = (item) => {
        setSelectedData(item)
        setBuyerModal(prev => !prev)
    }

    const onSelectAmount = (item) => {
        setSelectedData(item)
        setAmountModal(prev => !prev)
    }

    const onNotifyBuyer = (item) => {
        setSelectedData(item)
        setNotifyModal(prev => !prev)
    }

    const onSearch = useCallback(async() => {
        try {
            await dispatch(actions.buyer.searchBuyerMortgageConnection({ offset: 0, filters }))
        } catch (error) {
            console.log(error)
        }
    }, [filters])

    useEffect(() => {
        const handleSearch = () => {
            onSearch();
        }
        handleSearch()
    }, [filters])

    const onChangeInput = (event) => {
        setFilters(`search=${event.target.value}`)
    }

    const handleInput = useMemo(() => {
        return debouce(onChangeInput, 300)
    }, [])


    const refetchConnectionData = useCallback(async() => {
        if (filters) {
            onSearch();
        } else {
            if (selectedPage) {
                await dispatch(actions.buyer.searchBuyerMortgageConnection(selectedPage));
            } else {
                await dispatch(actions.buyer.fetchBuyerMortgageConnection())
            }
        }
    }, [filters])
    

    const formatCurency = (value) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        });
        return formatter.format(value);
    }

    return (
        <Section className="mortgage-connections-container" style={{ minHeight: '91.5vh' }}>
            <InviteConnections modal={inviteModal} setModal={setInviteModal} />
            <UploadDocument modal={uploadModal} setModal={setUploadModal} selectedData={selectedData} />
            <QuestionnaireForm modal={questionnaireModal} setModal={setQuestionnaireModal} selectedData={selectedData} />
            <QuestionnairePreview modal={previewModal} setModal={setPreviewModal} selectedData={selectedData} />
            <DeleteConnection modal={deleteModal} setModal={setDeleteModal} selectedData={selectedData} />
            <BuyerInfoModal modal={buyerModal} setModal={setBuyerModal} selectedData={selectedData} />
            <AmountEdit modal={amountModal} setModal={setAmountModal} selectedData={selectedData} />
            <NotifyBuyerModal modal={notifyModal} setModal={setNotifyModal} selectedData={selectedData} />

            <h1 className="header">Mortgage Broker Connections</h1>
            <div className="table-filters">
                <input className="search" type="text" style={{ width: 580 }} placeholder="Search Buyer" onChange={handleInput} />
                <button 
                    className='button secondary' 
                    style={{ width: 240, height: 60 }} 
                    onClick={() => setInviteModal(prev => !prev)}
                >
                    Invite Buyer
                </button>
            </div>
            <Seprator height={20} />
            <div className="table-container">
                <table cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th width="210px"><div>Buyer</div></th>
                            <th width="210px"><div>Document</div></th>
                            <th width="210px"><div>Document Expiration</div></th>
                            <th width="180px"><div>Pre-Approval Amount</div></th>
                            <th width="210px"><div>Status</div></th>
                            <th width="210px"><div>Tier</div></th>
                            <th width="146px"><div>Action</div></th>
                        </tr>
                    </thead>
                    <tbody id="connections-table">
                    {
                            data.length > 0 ?
                            data.map((connection, index) => {
                                return (
                                    <tr className="body" key={index}>
                                        <td>
                                            <div className="buyer-container">
                                                <img className="buyer-image" src={checkImageUrl(connection.buyer.profile_photo, NoUser)} alt="" />
                                                <p className="buyer-info" onClick={() => onSelectBuyer(connection)}>{connection.buyer.first_name} {connection.buyer.last_name}</p>
                                            </div>
                                        </td>
                                        <td align="center" data-label="Document">
                                            {
                                                connection.approval_document ?
                                                <>
                                                    {
                                                        moment.duration(moment(connection.approval_document_expiry).startOf('day').diff(moment().startOf('day'))).asDays() > 0 &&
                                                        <p onClick={() => handleDownload(connection)} className="upgrade upload">
                                                            Download Document
                                                        </p>
                                                    }
                                                    <p className="upgrade change" onClick={() => onSelectEntry(connection)}>Re-upload Document</p>
                                                </>
                                                : <>
                                                    {
                                                        !data.questionnaire_filled_out &&
                                                        <p className="upgrade draft" onClick={() => onSelectEntry(connection)}>
                                                            Upload Document
                                                        </p>
                                                    }
                                                </>
                                            }
                                        </td>
                                        <td align="center" data-label="Document Expiration">
                                            {
                                                connection.approval_document_expiry 
                                                ? <>
                                                    <p>{moment(connection.approval_document_expiry ).format('MMMM DD, YYYY')}</p>
                                                    {
                                                        parseInt(moment.duration(moment(connection.approval_document_expiry).startOf('day').diff(moment().startOf('day'))).asDays()) < 7 &&
                                                        <>
                                                            <Seprator height={5} />
                                                            {
                                                               (Math.sign(parseInt(moment.duration(moment(connection.approval_document_expiry).diff(moment().startOf('day'))).asDays())) === -1 || 
                                                               Math.sign(parseInt(moment.duration(moment(connection.approval_document_expiry).diff(moment().startOf('day'))).asDays())) === 0)
                                                               ? <p style={{ fontSize: 10, color: 'rgb(235, 74, 74)' }}>Document is expired</p>
                                                               : <p style={{ fontSize: 10, color: 'rgb(235, 74, 74)' }}>Document is about to expire!</p>
                                                            }
                                                            {
                                                                connection.date_notified 
                                                                ? <>
                                                                    {
                                                                        (moment.duration(moment(connection.date_notified).startOf('day').diff(moment().add(3, 'days').startOf('day'))).asDays() >= 0) 
                                                                        ? <p className="upgrade" onClick={() => onNotifyBuyer(connection)}>Notify Buyer</p>
                                                                        : <p className="notified">Notified</p>
                                                                    }
                                                                </>
                                                                : <p className="upgrade" onClick={() => onNotifyBuyer(connection)}>Notify Buyer</p>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                : 'No Document'
                                            }
                                        </td>
                                        <td align="center" data-label="Pre-Approval Amount">
                                            {
                                                connection.approval_amount 
                                                ?
                                                <>
                                                    <p>{formatCurency(connection.approval_amount)}</p>
                                                    <p className="upgrade change" onClick={() => onSelectAmount(connection)}>Update Amount</p>
                                                </> 
                                                : <p className="upgrade draft" onClick={() => onSelectAmount(connection)}>Add Amount</p>
                                            }
                                        </td>
                                        <td align="center" data-label="Status"><p>{checkStatus(connection)}</p></td>
                                        <td align="center" data-label="Tier">
                                            <p>{connection.buyer_tier_display}</p>
                                            {
                                                connection.buyer_tier === 'Q' 
                                                ? <p className="upgrade change" onClick={() => updateBuyerTier(connection, 'H')}>Change to House Bee</p>
                                                : <p className="upgrade" onClick={() => updateBuyerTier(connection, 'Q')}>Upgrade to Queen Bee</p>
                                            }
                                        </td>
                                        <td align="center" data-label="Action">
                                            <ActionBlock 
                                                data={connection} 
                                                setSelectedData={setSelectedData} 
                                                setModal={setUploadModal} 
                                                setQuestionnaireModal={setQuestionnaireModal}
                                                setPreviewModal={setPreviewModal}
                                                setDeleteModal={setDeleteModal}
                                            />
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan="7">
                                    <div className="empty-table">No Available Buyer Connections</div>
                                </td>
                            </tr> 
                        }
                    </tbody>
                </table>
            </div>
            {
                data.length > 0 &&
                <div className="pagination">
                    <p className="previous" onClick={() => loadPageData({ page: previous, pageNumber: page - 1 })}> { previous && 'Previous' } </p>
                    <ul> 
                        {
                            pages.map((item, index) => {
                                return(
                                    <li 
                                        key={index}
                                        className={`${item.name === page && 'active'}`}
                                        onClick={() => loadPageData({ page: item.value, pageNumber: item.name })}
                                    >
                                        {item.name}
                                    </li>)
                            })
                        }
                    </ul>
                    <p className="next" onClick={() => loadPageData({ page: next, pageNumber: page + 1 })}> { next && 'Next' } </p>
                </div>
            }
        </Section>
    )
}


export default MortgageConnections;