import './customButton.scss';

/* ACCEPTABLE PROPS VALUE
 * type: primary, secondary, tertiary, text, error, success
 * size: small, normal(default), large
 * iconOnly: button with icon only
 */

const CustomButton = ({ text, size, type = 'primary', iconLeft, iconRight, disabled, iconOnly, style, action = () => { } }) => {
  return (
    <button className={`custom-button ${size} ${type}`} disabled={disabled} onClick={action} style={style}>
      {
        !iconOnly
          ? <>
            <div className='icon-left'>{iconLeft}</div>
            {text}
            <div className='icon-right'>{iconRight}</div>
          </>
          : <div>{iconOnly}</div>
      }
    </button>
  )
}

export default CustomButton;