import { useCallback, useState } from 'react'
// Redux
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
// Packages
import { toast } from 'react-toastify';
//Utils
import { getErrorMessage, inputChange, validateEmail } from 'utils/modules/Handlers';
// Components
import Section from 'components/Section'
import Seprator from 'components/Separator';
import ToastMessage from 'components/ToastMessage';
// Assets
import './forgotPassword.scss'
import ShowingBeeLogoBlack from 'assets/images/ShowingBeeLogoBlack.png'
import ForgotPasswordIllus from 'assets/images/forgot-pass-page-illus.svg'
import Icons from 'components/Icons';

const ForgotPassword  = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        email: {
            value: '',
            error: '',
        },
        password: {
            value: '',
            error: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async(event) => {
        event.preventDefault();

        const { email } = form
        let isValid = true

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid'})
            isValid = false
        } else {
            isValid = true
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: ''})
        }

        if (isValid) {
            setLoading(true)
            try {
                await dispatch(actions.auth.resetPassword({ email: email.value })).then(() => {
                    toast.success('Reset password request sent! Please check your email.', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setLoading(false)
                    navigate('/login')
                })
            } catch (error) {
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
                setLoading(false)
            }
        }
 
    }, [dispatch, form, navigate])

    return (
        <Section style={{ display: 'flex' }}>
            <div className='forgot-password-container'>
                <form className='forgot-password-form' onSubmit={(event) => onSubmit(event)}>
                    <img 
                        src={ShowingBeeLogoBlack} 
                        alt="logo"
                        className='logo'
                        onClick={() => navigate('/')}
                    />
                    <Seprator height={60} />
                    <h1 className='header'>Forgot your password?</h1>
                    <Seprator height={20} />
                    <h2 className='subheader'>Please provide us your email and we will help you retrieve your account.</h2>
                    <Seprator height={50} />
                    <input 
                        type="email"
                        id='email'
                        placeholder='Email Address' 
                        onChange={(event) => onChangeInput({event, type: "email"})} 
                    />
                    <span className='error-message'>{form.email.error}</span>
                    <Seprator height={30} />
                    <button className='button primary' type="submit" disabled={loading}>
                        {
                            loading 
                            ? <Icons.Loading height={40} width={40} background={'none'} />
                            : 'Submit'
                        }
                    </button>
                    <Seprator height={30} />
                    <p className='sign-up'>Don't have an account? <span onClick={() => navigate('/signup')}>Sign up for free</span></p>
                </form>
                <div className='illus-container'>
                    <img 
                        src={ForgotPasswordIllus} 
                        alt=""
                    />
                </div>
            </div>
        </Section>
    )
}


export default ForgotPassword;